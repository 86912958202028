import React, { useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import MyCloseButton from '../../components/button/closeButton';
import { useTranslation } from 'react-i18next';
import DeleteButton from '../../components/button/deleteButton';
import Navigation from '../../components/place/navigation';

import Store from '../../utils/store';
import { API } from '../../utils/api';
import Description from '../../components/place/description';



const UnknownPlace = () => {
  const { t } = useTranslation();

  const showUnknownPlaceModal = Store.useState((s) => s.showUnknownPlaceModal);
  const deletingPlace = Store.useState((s) => s.deletingPlace);
  const profile = Store.useState((s) => s.profile);
  const placeId = Store.useState((s) => s.selectedPlaceId);
  const place = Store.useState((s) => s.selectedPlace);

  useEffect(() => {
    console.log('fetching unknown place', placeId);

    API.fetchPlace(placeId)
      .then((res) => {
        Store.update((s) => {
          s.selectedPlace = res;
        });
      })
      .catch((res) => {
        console.error(res);
      });
  }, []);

  const hide = () => {
    Store.update((s) => {
      s.showUnknownPlaceModal = false;
      s.selectedPlace = null;
    });
  };

  const confirm = () => {
    Store.update((s) => {
      if (profile.username) {
        s.showEditPlaceModal = true;
        s.showUnknownPlaceModal = false;
      } else {
        s.showSignInModal = true;
      }
    });
  };

  return (
    <Modal show={showUnknownPlaceModal} onHide={hide} size="sm" centered>
      <Modal.Header>
        <Modal.Title>{t('confirmPlace.header')}</Modal.Title>
        <MyCloseButton hide={hide} />
      </Modal.Header>
      <Modal.Body>
        {place.description?.length > 0 && <Description />}
        <p>{t('confirmPlace.body')}</p>
        <p>{t('confirmPlace.question')}</p>
        <Navigation />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={hide} className="unknownPlaceBtnNo" disabled={deletingPlace}>
          {t('button.no')}
        </Button>

        {profile.is_admin && <DeleteButton />}
        <Button className="unknownPlaceBtnYes" variant="primary" onClick={confirm} disabled={deletingPlace}>
          {t('button.yes')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UnknownPlace;
