import React, { useState } from 'react';
import { Button, Col, Container, Form, Modal, Row, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import MyCloseButton from '../../components/button/closeButton';
import HelpButton from '../../components/button/helpButton';
import Coordinates from '../../components/coordinates';
import DropArea from '../../components/dnd/dropArea';
import DraggableModalDialog from '../../components/draggable';
import Facilities from '../../components/facilities';
import ObjectType from '../../components/objectType';
import PasteImageArea from '../../components/pasteArea';
import { API } from '../../utils/api';
import { FACILITY_TYPES, OBJECT_TYPES } from '../../utils/constants';
import Store from '../../utils/store';

const moduleName = 'New Place';

const NewPlaceModal = () => {
  const { t } = useTranslation();

  const newObjectTypes = Store.useState((s) => s.newObjectTypes);
  const newFacilities = Store.useState((s) => s.newFacilities);
  const markerCoords = Store.useState((s) => s.markerCoords);
  const imagesToUpload = Store.useState((s) => s.imagesToUpload);
  const savingNewPlace = Store.useState((s) => s.savingNewPlace);

  const showNewPlaceModal = Store.useState((s) => s.showNewPlaceModal);
  const newPlaceLoadingImages = Store.useState((s) => s.newPlaceLoadingImages);

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');

  const [validated, setValidated] = useState(false);

  const addMarker = (marker) => {
    console.log(`${moduleName}: addMarker: -> ${JSON.stringify(marker)}`);
    Store.update((s) => {
      s.places = [...s.places, marker];
    });
  };

  const hide = () => {
    Store.update((s) => {
      s.showNewPlaceModal = false;
      s.showNewMarker = false;
      s.placeIconActive = false;
      s.pointOnMap = false;
    });
  };

  const save = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (imagesToUpload.length === 0) {
      toast.error('Upload at least one image');
      return;
    }

    if (
      !newObjectTypes.hut &&
      !newObjectTypes.ruin &&
      !newObjectTypes.lean_to &&
      !newObjectTypes.bivouac &&
      !newObjectTypes.tower &&
      !newObjectTypes.basic_shelter &&
      !newObjectTypes.weather_shelter
    ) {
      toast.error('Object type is not selected');
      return;
    }
    const form = e.currentTarget;
    const valid = form.checkValidity();
    setValidated(true);

    if (valid === true) {
      Store.update((s) => {
        s.savingNewPlace = true;
      });

      const formData = new FormData();
      formData.append('name', name.trim());
      formData.append('description', description.trim());
      formData.append(
        'location',
        JSON.stringify({
          latitude: markerCoords.lat,
          longitude: markerCoords.lng,
        }),
      );

      for (let i in imagesToUpload) {
        formData.append('images', imagesToUpload[i]);
      }
      OBJECT_TYPES.map((key) => {
        formData.append(key, Boolean(newObjectTypes[key]));
      });
      FACILITY_TYPES.map((key) => {
        formData.append(key, Boolean(newFacilities[key]));
      });
      API.postPlace(formData)
        .then((res) => {
          setName('');
          setDescription('');
          setValidated(false);

          Store.update((s) => {
            s.showNewMarker = false;
            s.savingNewPlace = false;
            s.imagesToUpload = [];
            s.newObjectTypes = {};
            s.newFacilities = {};
            s.placeIconActive = false;
            s.pointOnMap = false;
            s.showNewMarker = false;
          });

          console.log(`${moduleName}: save -> New place posted ${JSON.stringify(res)}`);

          addMarker(res);
          toast.success('Place has been created');
          hide();
        })
        .catch((err) => {
          Store.update((s) => {
            s.savingNewPlace = false;
            s.placeIconActive = false;
            s.pointOnMap = false;
            s.showNewMarker = false;
          });
          setValidated(false);
          if (err.response && err.response.data) {
            if (err.response.data.images) {
              toast.error(err.response.data.images[0]);
            }
            if (err.response.data.detail) {
              toast.error(err.response.data.detail[0]);
            }
            return;
          }

          toast.error('Something went wrong. We are already fixing it');
          console.error(`${moduleName}: save: error -> ${err}`);
        });
    }
  };

  const showObjectTypeHelpModal = () => {
    Store.update((s) => {
      s.showObjectTypeHelpModal = true;
    });
  };

  const Footer = () => {
    return (
      <Modal.Footer>
        <Button
          className="newPlaceButtonCancel"
          variant="outline-secondary"
          onClick={hide}
          disabled={savingNewPlace || newPlaceLoadingImages}
          tabIndex="5">
          {t('button.cancel')}
        </Button>

        <Button
          variant="outline-primary"
          className="newPlaceButtonSave"
          disabled={savingNewPlace || newPlaceLoadingImages}
          tabIndex="6"
          type="submit">
          {savingNewPlace && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />}
          {t('button.save')}
        </Button>
      </Modal.Footer>
    );
  };

  return (
    <Modal
      show={showNewPlaceModal}
      onHide={hide}
      dialogAs={DraggableModalDialog}
      backdrop={savingNewPlace ? 'static' : true}
      dialogClassName="newPlaceModal"
      className="newPlace">
      <Form validated={validated} noValidate onSubmit={save}>
        <Modal.Header className="modalHeader">
          <Modal.Title>{t('newPlace.modalHeader')}</Modal.Title>
          <MyCloseButton hide={hide} />
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <Form.Group as={Col} controlId="name">
                <Form.Label className="label">{t('newPlace.name')}</Form.Label>
                <Form.Control
                  required={false}
                  type="text"
                  disabled={savingNewPlace}
                  value={name}
                  placeholder={t('newPlace.namePlaceholder')}
                  onChange={(e) => setName(e.target.value)}
                  autoCorrect="on"
                  autoFocus={true}
                  autoComplete="username"
                  autoCapitalize="on"
                  tabIndex="1"
                />
                <Form.Control.Feedback type="invalid">Please provide a valid name</Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row className="description">
              <Form.Group as={Col} controlId="description">
                <Form.Label className="label">{t('newPlace.description')}</Form.Label>
                <Form.Control
                  className="newPlaceFormControl"
                  as="textarea"
                  required={false}
                  disabled={savingNewPlace}
                  componentclass="textarea"
                  value={description}
                  placeholder={t('newPlace.descriptionPlaceholder')}
                  onChange={(e) => setDescription(e.target.value)}
                  autoCorrect="on"
                  autoCapitalize="on"
                  tabIndex="2"
                />
                <Form.Control.Feedback type="invalid">Please provide a valid description.</Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Coordinates required={true} tabIndex="3" location={{ lat: markerCoords.lat, lng: markerCoords.lng }} />
            <Form.Label className="label">
              <div className="objectType">
                {t('newPlace.objectType')}
                <HelpButton help={showObjectTypeHelpModal} />
              </div>
            </Form.Label>
            <ObjectType
              objectTypes={newObjectTypes}
              disabled={savingNewPlace}
              setObjectTypes={(types) => {
                Store.update((s) => {
                  s.newObjectTypes = types;
                });
              }}
            />
            <Form.Label className="label">{t('newPlace.facilities')}</Form.Label>
            <Facilities
              facilities={newFacilities}
              disabled={savingNewPlace}
              setFacilities={(facilities) => {
                Store.update((s) => {
                  s.newFacilities = facilities;
                });
              }}
            />
            <PasteImageArea />

            <DropArea className="dropZone" />
          </Container>
        </Modal.Body>
        <Footer />
      </Form>
    </Modal>
  );
};
export default NewPlaceModal;
