import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import MyCloseButton from '../../components/button/closeButton';
import Store from '../../utils/store';
import MeImage from '../../images/about/me.png';
import AndreImage from '../../images/about/andre.png';
import MarkusImage from '../../images/about/markus.png';
import Viktoria from '../../images/about/viktoria.png';

import ProfileIcon from '../../images/icons/profile.png';

function TeamModal() {
  const showTeamModal = Store.useState((s) => s.showTeamModal);

  const hide = () => {
    Store.update((s) => {
      s.showTeamModal = false;
    });
  };
  return (
    <Modal show={showTeamModal} onHide={hide} size="lg" className="aboutModal">
      <Modal.Header>
        <Modal.Title>Our Team</Modal.Title><MyCloseButton hide={hide} />
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <Col sm={3}>
              <Image src={MeImage} roundedCircle fluid />
            </Col>
            <Col sm={9}>
              <h4>Alexey Ulyanov</h4>
              <p>The founder of this project</p>
              <p>
                I&apos;ve been working as an IT consultant for many years and this project is my way to contribute into
                community.
              </p>
              <a href="https://www.linkedin.com/in/alexey-ulyanov-3632a02a/" target="_blank" rel="noreferrer">
                Linkedin
              </a>
            </Col>
          </Row>

          <Row>
            <Col sm={3}>
              <Image src={MarkusImage} roundedCircle fluid />
            </Col>
            <Col sm={9}>
              <h4>Markus Zöllner</h4> <br></br>
            </Col>
          </Row>
          <Row>
            <Col sm={3}>
              <Image src={AndreImage} roundedCircle fluid />
            </Col>
            <Col sm={9}>
              <h4>Andre Mayer</h4> <br></br>
              <p>
                My name is Andre and my Life is playing on two wheels most of the time. The rest of the time I am
                sleeping.
              </p>
            </Col>
          </Row>
          <Row>
            <Col sm={3}>
              <Image src={ProfileIcon} roundedCircle fluid />
            </Col>
            <Col sm={9}>
              <h4>Dennis Priskorn</h4> <br></br>
            </Col>
          </Row>
          <Row>
            <Col sm={3}>
              <Image src={Viktoria} roundedCircle fluid />
            </Col>
            <Col sm={9}>
              <h4>Viktoria Ulyanova</h4> <br></br>
              <p>Frontend Developer</p>
              <p>
                Hello everyone! I think this is a great project where we can help each
                other find cool places to stop while traveling or just have a BBQ on the weekend. Peace and love :)
              </p>
              <a href="https://www.linkedin.com/in/victoria-ulianova-b9992a100/" target="_blank" rel="noreferrer">
                Linkedin
              </a>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
}

export default TeamModal;
