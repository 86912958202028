import React from 'react';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';

import MyCloseButton from '../../components/button/closeButton';
import OneNiteTent from '../../images/other-projects/1nitetent.png';
import BeWelcomeImg from '../../images/other-projects/bewelcome.jpeg';
import FBCampwild from '../../images/other-projects/fb_campwild.jpeg';
import FBKoiekartet from '../../images/other-projects/fb_koiekartet.jpeg';
import FBVin from '../../images/other-projects/fb_vindskyd.jpeg';
import iOverlanderImg from '../../images/other-projects/iOverlander.png';
import NaturStyrelsen from '../../images/other-projects/naturstyrelsen.png';
import NomadWikiImg from '../../images/other-projects/nomad_wiki.png';
import Park4NightImg from '../../images/other-projects/park4night.png';
import Skaneleden from '../../images/other-projects/skaneleden.jpeg';
import SydsverigeImg from '../../images/other-projects/sydsverige.png';
import TrustRootsImg from '../../images/other-projects/trustroots.png';
import WelcomeToMyGardenImg from '../../images/other-projects/welcome_to_my_garden.jpeg';
import Store from '../../utils/store';

function OtherProjectsModal() {
  const showOtherProjectsModal = Store.useState((s) => s.showOtherProjectsModal);

  const hide = () => {
    Store.update((s) => {
      s.showOtherProjectsModal = false;
    });
  };
  return (
    <Modal show={showOtherProjectsModal} onHide={hide} size="lg" className="aboutModal">
      <Modal.Header>
        <Modal.Title>Other Projects</Modal.Title>
        <MyCloseButton hide={hide} />
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <Col>
              <Card className="otherProjectsCard">
                <Card.Img variant="top" src={FBCampwild} />
                <Card.Body>
                  <Card.Title>Camp Wild</Card.Title>
                  <Card.Text>
                    CampWild Facebook group for all those who are looking for new shelters and bivouac sites! Let’s
                    create something beautiful, useful and practical!
                  </Card.Text>
                  <Button
                    variant="primary"
                    onClick={(e) => {
                      window.open('https://www.facebook.com/groups/shelterandbivouac', '_blank');
                      e.preventDefault();
                    }}>
                    Check It Out
                  </Button>
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card className="otherProjectsCard">
                <Card.Img variant="top" src={FBVin} />
                <Card.Body>
                  <Card.Title>Vindskydd i Norden</Card.Title>
                  <Card.Text>
                    Swedish Facebook group for anyone looking for new, exciting shelters to visit! Please add your
                    friends and let&apos;s create something nice, useful and practical!
                  </Card.Text>
                  <Button
                    variant="primary"
                    onClick={(e) => {
                      window.open('https://www.facebook.com/groups/vindskyddinorden', '_blank');
                      e.preventDefault();
                    }}>
                    Check It Out
                  </Button>
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card className="otherProjectsCard">
                <Card.Img variant="top" src={FBKoiekartet} />
                <Card.Body>
                  <Card.Title>Gruppe for Koiekartet</Card.Title>
                  <Card.Text>
                    Norwegian Facebook group for open huts, stone arches, barns, overnight gapahuker, caves...
                  </Card.Text>
                  <Button
                    variant="primary"
                    onClick={(e) => {
                      window.open('https://www.facebook.com/groups/522094295449279/', '_blank');
                      e.preventDefault();
                    }}>
                    Check It Out
                  </Button>
                </Card.Body>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col>
              <Card className="otherProjectsCard">
                <Card.Img variant="top" src={WelcomeToMyGardenImg} />
                <Card.Body>
                  <Card.Title>Welcome to my garden</Card.Title>
                  <Card.Text>
                    A not-for-profit network of citizens offering free camping spots in their gardens to slow
                    travellers.
                  </Card.Text>
                  <Button
                    variant="primary"
                    onClick={(e) => {
                      window.open('https://welcometomygarden.org/explore', '_blank');
                      e.preventDefault();
                    }}>
                    Check It Out
                  </Button>
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card className="otherProjectsCard">
                <Card.Img variant="top" src={Park4NightImg} />
                <Card.Body>
                  <Card.Title>Park4Night</Card.Title>
                  <Card.Text>
                    Share the nice spots where you like to relax with your camper, your equipped van or normal van.
                  </Card.Text>
                  <Button
                    variant="primary"
                    onClick={(e) => {
                      window.open('https://park4night.com/', '_blank');
                      e.preventDefault();
                    }}>
                    Check It Out
                  </Button>
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card className="otherProjectsCard">
                <Card.Img variant="top" src={iOverlanderImg} />
                <Card.Body>
                  <Card.Title>iOverlander</Card.Title>
                  <Card.Text>
                    iOverlander is a free website and app created to help overlanders on the road find their next
                    destination.
                  </Card.Text>
                  <Button
                    variant="primary"
                    onClick={(e) => {
                      window.open('https://park4night.com/', '_blank');
                      e.preventDefault();
                    }}>
                    Check It Out
                  </Button>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card className="otherProjectsCard">
                <Card.Img variant="top" src={NomadWikiImg} />
                <Card.Body>
                  <Card.Title>Nomad Wiki</Card.Title>
                  <Card.Text>
                    Nomadwiki is about anything interesting to nomads, like sharing knowledge on money-free and
                    affordable ways for travelling, wild camping accommodation, hospitality.
                  </Card.Text>
                  <Button
                    variant="primary"
                    onClick={(e) => {
                      window.open('http://nomadwiki.org/en/Wilderness_huts', '_blank');
                      e.preventDefault();
                    }}>
                    Check It Out
                  </Button>
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card className="otherProjectsCard">
                <Card.Img variant="top" src={SydsverigeImg} />
                <Card.Body>
                  <Card.Title>Sydsverigie.dk</Card.Title>
                  <Card.Text>
                    Spend the night outdoors in the Swedish countryside, light a fire and shelter from the wind and
                    rain. The shelters (&quot;vindskydd&quot; in Swedish) are usually free of charge and first-come,
                    first-served. But always remember to welcome others if there is room in the shelter.
                  </Card.Text>
                  <Button
                    variant="primary"
                    onClick={(e) => {
                      window.open('https://www.sydsverige.dk/?pageID=689', '_blank');
                      e.preventDefault();
                    }}>
                    Check It Out
                  </Button>
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card className="otherProjectsCard">
                <Card.Img variant="top" src={Skaneleden} />
                <Card.Body>
                  <Card.Title>Skåneleden</Card.Title>
                  <Card.Text>
                    Do you find it difficult to know where to start your hike? Here is some inspiration and a selection
                    of recommended hikes - short and long, with or without accommodation or based on different themes..
                  </Card.Text>
                  <Button
                    variant="primary"
                    onClick={(e) => {
                      window.open('https://www.skaneleden.se/en', '_blank');
                      e.preventDefault();
                    }}>
                    Check It Out
                  </Button>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card className="otherProjectsCard">
                <Card.Img variant="top" src={OneNiteTent} />
                <Card.Body>
                  <Card.Title>1 Nite Tent</Card.Title>
                  <Card.Text>
                    This page now tells you where in Germany you can camp for one night free of charge.
                  </Card.Text>
                  <Button
                    variant="primary"
                    onClick={(e) => {
                      window.open('https://1nitetent.com/en/home/', '_blank');
                      e.preventDefault();
                    }}>
                    Check It Out
                  </Button>
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card className="otherProjectsCard">
                <Card.Img variant="top" src={TrustRootsImg} />
                <Card.Body>
                  <Card.Title>Trustroots</Card.Title>
                  <Card.Text>Travellers&apos; community Sharing, hosting and getting people together.</Card.Text>
                  <Button
                    variant="primary"
                    onClick={(e) => {
                      window.open('https://www.trustroots.org', '_blank');
                      e.preventDefault();
                    }}>
                    Check It Out
                  </Button>
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card className="otherProjectsCard">
                <Card.Img variant="top" src={BeWelcomeImg} />
                <Card.Body>
                  <Card.Title>Be Welcome</Card.Title>
                  <Card.Text>
                    BeWelcome lets you share a place to stay, connect with travellers, meet up and find accommodation on
                    your journey.
                  </Card.Text>
                  <Button
                    variant="primary"
                    onClick={(e) => {
                      window.open('https://www.bewelcome.org/', '_blank');
                      e.preventDefault();
                    }}>
                    Check It Out
                  </Button>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card className="otherProjectsCard">
                <Card.Img variant="top" src={NaturStyrelsen} />
                <Card.Body>
                  <Card.Title>Natur Styrelsen</Card.Title>
                  <Card.Text>
                    The Norwegian Nature Agency gives you many opportunities to enjoy nature in the state&apos;s forest
                    and natural areas - also at night. On the pages here you can find out about rules and guidelines for
                    the use of our common nature at night. At the bottom of this page you can get an insight into the
                    overall legislation. In the menu you will find the rules in practice for the various accommodation
                    options in the state&apos;s forest and nature areas. Different rules apply in private forests.
                  </Card.Text>
                  <Button
                    variant="primary"
                    onClick={(e) => {
                      window.open('https://naturstyrelsen.dk/naturoplevelser/overnatning/', '_blank');
                      e.preventDefault();
                    }}>
                    Check It Out
                  </Button>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
}

export default OtherProjectsModal;
