import React from 'react';
import Select from 'react-dropdown-select';
import { useTranslation } from 'react-i18next';

import Store from '../utils/store';

const LanguageSelector = () => {
  const { t } = useTranslation();
  const selectedLanguage = Store.useState((s) => s.selectedLanguage);

  const click = (values) => {
    Store.update((s) => {
      s.selectedLanguage = values[0];
    });
  };
  return (
    <Select
      options={Store.useState((s) => s.languages)}
      multi={false}
      className="languageSelector"
      placeholder={t('profile.selectLanguage')}
      searchBy="name"
      labelField="name"
      valueField="code"
      onChange={click}
      values={[selectedLanguage]}
      closeOnSelect={true}
    />
  );
};
export default LanguageSelector;
