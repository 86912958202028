import axios from 'axios';
import { toast } from 'react-toastify';
import { getCSRFToken } from '../utils/cookies';
import history from '../utils/history';
import { API_URL } from './constants';

axios.defaults.baseURL = API_URL;

if (process.env.REACT_APP_USERNAME) {
  axios.defaults.headers.common['Authorization'] =
    'Basic ' + btoa(process.env.REACT_APP_USERNAME + ':' + process.env.REACT_APP_PASSWORD);
  axios.withCredentials = true;
}

axios.defaults.headers.common['X-CSRFToken'] = getCSRFToken('CSRF-TOKEN');
axios.defaults.headers.common['Content-Type'] = 'application/json';

axios.defaults.headers.post['Content-Type'] = 'multipart/form-data; boundary=----WebKitFormBoundarywqGn7yMonzBi1uBQ';

// Add a request interceptor
axios.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  },
);

// Add a response interceptor
axios.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  },
);

let networkErrorReported = false;

const sendRequest = (method, url, data, responseType = 'json') => {
  return axios({
    method: method,
    url: url,
    withCredentials: true,
    responseType: responseType,
    data: data,
  });
};

const sendRequestWithProgress = (method, url, data, onProgress) => {
  return axios({
    method: method,
    url: url,
    withCredentials: true,
    responseType: 'blob',
    data: data,
    onDownloadProgress: onProgress,
  });
};

const handleError = (error) => {
  if (error.message === 'Network Error' && !error.response) {
    if (!networkErrorReported) {
      toast.error('Network error');
      networkErrorReported = true;
    }

    return Promise.reject(error);
  }
  networkErrorReported = false;

  if (error.message === undefined) {
    history.push('/');
  }

  const { status } = error;

  if (status === 404) {
    history.push('/');
  }

  if (status === 500) {
    toast.error('Server error');
  }

  if (status === 401) {
    history.push('/');
  }
  return Promise.reject(error);
};

export const API = {
  fetchProfile: async () => {
    try {
      const response = await sendRequest('get', '/users/');
      return response.data;
    } catch (error) {
      return await handleError(error);
    }
  },

  updateProfile: async (payload) => {
    try {
      const response = await sendRequest('put', '/users/me', payload);
      return response.data;
    } catch (error) {
      return await handleError(error);
    }
  },

  fetchCountries: async () => {
    try {
      const response = await sendRequest('get', '/countries');
      return response.data;
    } catch (error) {
      return await handleError(error);
    }
  },

  fetchLanguages: async () => {
    try {
      const response = await sendRequest('get', '/languages/');
      return response.data;
    } catch (error) {
      return await handleError(error);
    }
  },

  fetchPlaces: async (filterConditions) => {
    try {
      const response = await sendRequest('get', `/places/?${new URLSearchParams(filterConditions)}`);
      return response.data;
    } catch (error) {
      return await handleError(error);
    }
  },
  fetchImages: async (placeId) => {
    try {
      const response = await sendRequest('get', `/places/${placeId}/images/`);
      return response.data;
    } catch (error) {
      return await handleError(error);
    }
  },
  fetchComments: async (placeId) => {
    try {
      const response = await sendRequest('get', `/places/${placeId}/comments/`);
      return response.data;
    } catch (error) {
      return await handleError(error);
    }
  },
  searchPlaces: async (searchTerms) => {
    try {
      const response = await sendRequest('get', `/places/?${new URLSearchParams(searchTerms)}`);
      return response.data;
    } catch (error) {
      return await handleError(error);
    }
  },

  fetchGPXFile: async (urlParams, onProgress) => {
    try {
      const response = await sendRequestWithProgress(
        'get',
        `/places/?${new URLSearchParams(urlParams)}`,
        {},
        onProgress,
      );
      return new Blob([response.data]);
    } catch (error) {
      return await handleError(error);
    }
  },

  fetchPlace: async (placeId) => {
    try {
      const response = await sendRequest('get', `places/${placeId}`);
      return response.data;
    } catch (error) {
      return await handleError(error);
    }
  },

  userActivities: async () => {
    try {
      const response = await sendRequest('get', `${API_URL}/users/me/activities`);
      return response.data;
    } catch (error) {
      return await handleError(error);
    }
  },

  getNews: async () => {
    try {
      const response = await sendRequest('get', `${API_URL}/news`);
      return response.data;
    } catch (error) {
      return await handleError(error);
    }
  },

  postPlace: async (payload) => {
    try {
      const response = await sendRequest('post', '/places/', payload);
      return response.data;
    } catch (error) {
      return await handleError(error);
    }
  },
  putPlace: async (placeId, payload) => {
    try {
      const response = await sendRequest('put', `/places/${placeId}`, payload);
      return response.data;
    } catch (error) {
      return await handleError(error);
    }
  },
  patchPlace: async (placeId, payload) => {
    try {
      const response = await sendRequest('patch', `/places/${placeId}`, payload);
      return response.data;
    } catch (error) {
      return await handleError(error);
    }
  },

  declinePlace: async (placeId) => {
    try {
      const response = await sendRequest('post', `/places/${placeId}/decline/`);
      return response.data;
    } catch (error) {
      return await handleError(error);
    }
  },

  reportPlace: async (placeId, payload) => {
    try {
      const response = await sendRequest('post', `/places/${placeId}/report/`, payload);
      return response.data;
    } catch (error) {
      return await handleError(error);
    }
  },

  deletePlace: async (placeId) => {
    try {
      const response = await sendRequest('delete', `/places/${placeId}`);
      return response.data;
    } catch (error) {
      return await handleError(error);
    }
  },

  deleteImage: async (placeId, imageId) => {
    try {
      const response = await sendRequest('delete', `/places/${placeId}/images/${imageId}`);
      return response.data;
    } catch (error) {
      return await handleError(error);
    }
  },

  deleteComment: async (placeId, commentId) => {
    try {
      const response = await sendRequest('delete', `/places/${placeId}/comments/${commentId}`);
      return response.data;
    } catch (error) {
      return await handleError(error);
    }
  },

  verifyPlace: async (placeId) => {
    try {
      const response = await sendRequest('post', `/places/${placeId}/verify/`);
      return response.data;
    } catch (error) {
      return await handleError(error);
    }
  },

  postFavorite: async (placeId) => {
    try {
      const response = await sendRequest('post', `/places/${placeId}/favorites/`);
      return response.data;
    } catch (error) {
      return await handleError(error);
    }
  },

  deleteFavorite: async (placeId) => {
    try {
      const response = await sendRequest('delete', `/places/${placeId}/favorites/`);
      return response.data;
    } catch (error) {
      return await handleError(error);
    }
  },

  postImages: async (placeId, payload) => {
    try {
      const response = await sendRequest('post', `/places/${placeId}/images/`, payload);
      return response.data;
    } catch (error) {
      return await handleError(error);
    }
  },

  translateText: async (payload) => {
    try {
      const response = await sendRequest('post', '/translate/', payload);
      return response.data;
    } catch (error) {
      return await handleError(error);
    }
  },

  postComment: async (placeId, payload) => {
    try {
      const response = await sendRequest('post', `places/${placeId}/comments/`, payload);
      return response.data;
    } catch (error) {
      return await handleError(error);
    }
  },

  getNewUsers: async () => {
    try {
      const response = await sendRequest('get', 'users/new');
      return response.data;
    } catch (error) {
      return await handleError(error);
    }
  },

  getNewPlaces: async () => {
    try {
      const response = await sendRequest('get', 'places/new');
      return response.data;
    } catch (error) {
      return await handleError(error);
    }
  },

  getNewImages: async () => {
    try {
      const response = await sendRequest('get', 'images/new');
      return response.data;
    } catch (error) {
      return await handleError(error);
    }
  },
  getLeaders: async () => {
    try {
      const response = await sendRequest('get', 'users/leaderboard');
      return response.data;
    } catch (error) {
      return await handleError(error);
    }
  },

  getNewComments: async () => {
    try {
      const response = await sendRequest('get', 'comments/new');
      return response.data;
    } catch (error) {
      return await handleError(error);
    }
  },

  reportComment: async (placeId, commentId, payload) => {
    try {
      const response = await sendRequest('post', `/places/${placeId}/comments/${commentId}/report`, payload);
      return response.data;
    } catch (error) {
      return await handleError(error);
    }
  },

  postFeedback: async (payload) => {
    try {
      const response = await sendRequest('post', '/feedbacks/', payload);
      return response.data;
    } catch (error) {
      return await handleError(error);
    }
  },

  logout: async () => {
    try {
      const response = await sendRequest('get', '/logout');
      return response.data;
    } catch (error) {
      return await handleError(error);
    }
  },
};
