import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import MyCloseButton from '../../components/button/closeButton';
import { useTranslation } from 'react-i18next';

import Store from '../../utils/store';

function ConfirmNewPlaceModal() {
  const { t } = useTranslation();

  const showConfirmNewPlaceModal = Store.useState((s) => s.showConfirmNewPlaceModal);
  const markerCoords = Store.useState((s) => s.markerCoords);

  const hide = () => {
    Store.update((s) => {
      s.showConfirmNewPlaceModal = false;
      s.showNewMarker = false;
    });
  };

  const confirm = () => {
    Store.update((s) => {
      s.centerCoords = markerCoords;
      s.zoomLevel = 15;
      s.showNewMarker = true;
      s.showConfirmNewPlaceModal = false;
      s.showNewPlaceModal = true;
    });
  };
  return (
    <Modal show={showConfirmNewPlaceModal} centered onHide={hide}>
      <Modal.Header>
        <Modal.Title>Create New Place?</Modal.Title><MyCloseButton hide={hide} />
      </Modal.Header>

      <Modal.Body>
        <p>It seems that we could not find a place with given coordinates. </p>
        <p>Do you want to create one?</p>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={hide} className="newPlaceBtnNo">
          {t('button.no')}
        </Button>
        <Button variant="primary" onClick={confirm} className="newPlaceBtnYes">
          {t('button.yes')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ConfirmNewPlaceModal;
