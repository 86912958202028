import React, { useRef, useState } from 'react';
import Overlay from 'react-bootstrap/Overlay';
import Tooltip from 'react-bootstrap/Tooltip';
import { useTranslation } from 'react-i18next';

import CopyIcon from '../../images/icons/copy.svg';
import Store from '../../utils/store';
import ReportButton from '../../components/reportButton';

const Location = () => {
  const { t } = useTranslation();

  const place = Store.useState((s) => s.selectedPlace);
  const profile = Store.useState((s) => s.profile);

  let lat = parseFloat(place.location.lat).toFixed(7);
  let lng = parseFloat(place.location.lng).toFixed(7);
  const [show, setShow] = useState(false);
  const target = useRef(null);

  const onCopyClicked = () => {
    navigator.clipboard.writeText(`${lat},${lng}`);
    setShow(true);
  };

  const reportClicked = () => {
    Store.update((s) => {
      if (profile.username) {
        s.showReportPlaceModal = true;
      } else {
        s.showSignInModal = true;
      }
    });
  };

  return (
    <>
      <div className="locationReportWrapper">
        <div>{`${lat},${lng}`}</div>
        <img
          className="pointer locationPointer"
          ref={target}
          onClick={onCopyClicked}
          src={CopyIcon}
          onMouseLeave={() => setShow(false)}
        />
        <Overlay target={target.current} show={show} placement="right">
          {(props) => <Tooltip {...props}>{t('tooltips.copied')}</Tooltip>}
        </Overlay>
        <ReportButton reportClicked={reportClicked} />
      </div>
      <div className="sectionDivider" />
    </>
  );
};

export default Location;
