import { EXIF } from 'exif-js';
import React from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import Store from '../utils/store';

const moduleName = 'coordsFromPhoto';

function CoordsFromPhoto() {
  const { t } = useTranslation();

  const disabled = Store.useState((s) => s.savingNewPlace);

  const ConvertDMSToDD = (degrees, minutes, seconds, direction) => {
    var dd = degrees + minutes / 60 + seconds / 3600;

    if (direction === 'S' || direction === 'W') {
      dd = dd * -1;
    }
    return dd;
  };

  const readLocationFromPhoto = (files) => {
    if (files.length === 0) {
      return;
    }

    let reader = new FileReader();
    reader.onload = (e) => {
      const exif = EXIF.readFromBinaryFile(e.target.result);
      console.log(`${moduleName}: readLocationFromPhoto ->  GPSLatitude: ${exif.GPSLatitude}`);
      console.log(`${moduleName}: readLocationFromPhoto ->  GPSLatitude: ${exif.GPSLongitude}`);

      if (exif.GPSLatitude === undefined || exif.GPSLongitude === undefined) {
        toast.error(`Image ${files[0].name} doesn't have geo information`);
        return;
      }
      // Calculate latitude decimal
      const latDegree = exif.GPSLatitude[0].numerator;
      const latMinute = exif.GPSLatitude[1].numerator;
      const latSecond = exif.GPSLatitude[2].numerator / exif.GPSLatitude[2].denominator;
      const latDirection = exif.GPSLatitudeRef;

      const latFinal = ConvertDMSToDD(latDegree, latMinute, latSecond, latDirection);
      // Calculate longitude decimal
      const lonDegree = exif.GPSLongitude[0].numerator;
      const lonMinute = exif.GPSLongitude[1].numerator;
      const lonSecond = exif.GPSLongitude[2].numerator / exif.GPSLongitude[2].denominator;
      const lonDirection = exif.GPSLongitudeRef;

      const lonFinal = ConvertDMSToDD(lonDegree, lonMinute, lonSecond, lonDirection);
      console.log(`${moduleName}: readLocationFromPhoto ->  latFinal: ${latFinal}`);
      console.log(`${moduleName}: readLocationFromPhoto ->  lonFinal: ${lonFinal}`);

      Store.update((s) => {
        s.markerCoords = { lat: latFinal, lng: lonFinal };
        s.centerCoords = { lat: latFinal, lng: lonFinal };
        s.coordsFromPhoto = `${parseFloat(latFinal.toFixed(7))},${parseFloat(lonFinal.toFixed(7))}`;
      });
    };
    reader.readAsArrayBuffer(files[0]);
  };

  return (
    <div>
      <Row className="coordsFromPhotoWrapper">
        <Col className='col'>
          <input
            id="coordsFromPhoto"
            className="coordsFromPhoto"
            type="file"
            accept="image/*"
            disabled={disabled}
            onChange={(e) => readLocationFromPhoto(e.target.files)}
          />
          <Button
            className="coordsBtn"
            variant="outline-primary"
            disabled={disabled}
            onClick={() => document.getElementById('coordsFromPhoto').click()}>
            {t('newPlace.coordsFromPhoto')}
          </Button>
        </Col>
      </Row>
    </div>
  );
}

export default CoordsFromPhoto;
