import React from 'react';
import WarningIcon from '../images/icons/warning-red.svg';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const ReportButton = ({reportClicked}) => {
  const { t } = useTranslation();

  return (
    <div className="reportButton" onClick={reportClicked}>
      <img className="warningIcon" src={WarningIcon} alt="warning-icon"></img>
      {t('button.report')}
    </div>
  );
};

ReportButton.propTypes = {
  reportClicked: PropTypes.any,
};

export default ReportButton;
