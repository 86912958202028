import PropTypes from 'prop-types';
import React from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Row from 'react-bootstrap/Row';
import Tooltip from 'react-bootstrap/Tooltip';
import { useTranslation } from 'react-i18next';
import _ from 'underscore';


import CanSwimIcon from '../images/facilities/canSwim.svg';
import DrinkableWaterIcon from '../images/facilities/drinkableWater.svg';
import ElectricityIcon from '../images/facilities/electricity.svg';
import FireplaceIcon from '../images/facilities/fireplace.svg';
import WoodIcon from '../images/facilities/firewood.svg';
import GrillIcon from '../images/facilities/grill.svg';
import HeatingIcon from '../images/facilities/heating.svg';
import MoneyIcon from '../images/facilities/money.svg';
import NearRoadIcon from '../images/facilities/nearRoad.svg';
import NearWaterIcon from '../images/facilities/nearWater.svg';
import ParkingIcon from '../images/facilities/parking.svg';
import ShowerIcon from '../images/facilities/shower.svg';
import TableIcon from '../images/facilities/table.svg';
import ToiletIcon from '../images/facilities/toilet.svg';
import TrashIcon from '../images/facilities/trash.svg';
import { FACILITY_TYPES, OBJECT_TYPES } from '../utils/constants';

const moduleName = 'Facilities';

const Facilities = ({ facilities, disabled, setFacilities }) => {
  const { t } = useTranslation();

  const setFacility = (name) => {
    const tmp = { ...facilities };

    console.log(`${moduleName}: setFacility before -> ${JSON.stringify(tmp)}`);

    tmp[name] = !facilities[name];

    console.log(`${moduleName}: setFacility after -> ${JSON.stringify(tmp)}`);
    // if we can swim then we have water near by also
    if (name === 'can_swim') {
      tmp['near_water'] = tmp[name];
    }
    // if we remove can_swim then remove near water also
    if (name === 'near_water' && tmp['can_swim']) {
      tmp['can_swim'] = false;
    }

    _.each(OBJECT_TYPES, (item) => {
      if (item === name || !OBJECT_TYPES.includes(name)) {
        return;
      }
      tmp[item] = false;
    });
    console.log(`${moduleName}: setFacility -> ${JSON.stringify(tmp)}`);
    setFacilities(tmp);
  };

  const resetFilters = () => {
    setFacilities({});
  };

  const selectAllFacilities = () => {
    const all = {};
    FACILITY_TYPES.forEach((item) => {
      all[item] = true;
    });
    setFacilities(all);
  };
  
  return (
    <Row>
      <Form.Group as={Col}>
        <Container className="facilities">
          <Row>
            <Col>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip-top">{t('place.facilities.firewood.tooltip')}</Tooltip>}>
                <img alt="firewood" src={WoodIcon} />
              </OverlayTrigger>
              <Form.Check
                label={t('place.facilities.firewood.name')}
                disabled={disabled}
                type="checkbox"
                id="firewood"
                checked={facilities.firewood || false}
                onChange={() => setFacility('firewood')}
              />
            </Col>
            <Col>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip-top">{t('place.facilities.nearRoad.tooltip')}</Tooltip>}>
                <img alt="nearRoad" src={NearRoadIcon} />
              </OverlayTrigger>
              <Form.Check
                label={t('place.facilities.nearRoad.name')}
                disabled={disabled}
                type="checkbox"
                id="nearRoad"
                checked={facilities.near_road || false}
                onChange={() => setFacility('near_road')}
              />
            </Col>
          </Row>

          <Row>
            <Col>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip-top">{t('place.facilities.fireplace.tooltip')}</Tooltip>}>
                <img alt="fireplace" src={FireplaceIcon} />
              </OverlayTrigger>
              <Form.Check
                label={t('place.facilities.fireplace.name')}
                disabled={disabled}
                type="checkbox"
                id="fireplace"
                checked={facilities.fireplace || false}
                onChange={() => setFacility('fireplace')}
              />
            </Col>
            <Col>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip-top">{t('place.facilities.parking.tooltip')}</Tooltip>}>
                <img alt="parking" src={ParkingIcon} />
              </OverlayTrigger>
              <Form.Check
                label={t('place.facilities.parking.name')}
                disabled={disabled}
                type="checkbox"
                id="parking"
                checked={facilities.parking || false}
                onChange={() => setFacility('parking')}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip-top">{t('place.facilities.grill.tooltip')}</Tooltip>}>
                <img alt="grill" src={GrillIcon} />
              </OverlayTrigger>
              <Form.Check
                className="formCheckGrill"
                label={t('place.facilities.grill.name')}
                disabled={disabled}
                type="checkbox"
                id="grill"
                checked={facilities.grill || false}
                onChange={() => setFacility('grill')}
              />
            </Col>
            <Col>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip-top">{t('place.facilities.trash.tooltip')}</Tooltip>}>
                <img alt="trash" src={TrashIcon} />
              </OverlayTrigger>
              <Form.Check
                label={t('place.facilities.trash.name')}
                disabled={disabled}
                type="checkbox"
                id="trash"
                checked={facilities.trash || false}
                onChange={() => setFacility('trash')}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip-top">{t('place.facilities.electricity.tooltip')}</Tooltip>}>
                <img alt="electricity" src={ElectricityIcon} />
              </OverlayTrigger>
              <Form.Check
                label={t('place.facilities.electricity.name')}
                disabled={disabled}
                type="checkbox"
                id="electricity"
                checked={facilities.electricity || false}
                onChange={() => setFacility('electricity')}
              />
            </Col>
            <Col>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip-top">{t('place.facilities.table.tooltip')}</Tooltip>}>
                <img alt="table" src={TableIcon} />
              </OverlayTrigger>
              <Form.Check
                label={t('place.facilities.table.name')}
                disabled={disabled}
                type="checkbox"
                id="table"
                checked={facilities.table || false}
                onChange={() => setFacility('table')}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip-top">{t('place.facilities.nearWater.tooltip')}</Tooltip>}>
                <img alt="nearWater" src={NearWaterIcon} />
              </OverlayTrigger>
              <Form.Check
                label={t('place.facilities.nearWater.name')}
                disabled={disabled}
                type="checkbox"
                id="nearWater"
                checked={facilities.near_water || false}
                onChange={() => setFacility('near_water')}
              />
            </Col>

            <Col>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip-top">{t('place.facilities.toilet.tooltip')}</Tooltip>}>
                <img alt="toilet" src={ToiletIcon} />
              </OverlayTrigger>
              <Form.Check
                label={t('place.facilities.toilet.name')}
                disabled={disabled}
                type="checkbox"
                id="toilet"
                checked={facilities.toilet || false}
                onChange={() => setFacility('toilet')}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip-top">{t('place.facilities.canSwim.tooltip')}</Tooltip>}>
                <img alt="can_swim" src={CanSwimIcon} />
              </OverlayTrigger>
              <Form.Check
                label={t('place.facilities.canSwim.name')}
                disabled={disabled}
                type="checkbox"
                id="can_swim"
                checked={facilities.can_swim || false}
                onChange={() => setFacility('can_swim')}
              />
            </Col>
            <Col>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip-top">{t('place.facilities.heating.tooltip')}</Tooltip>}>
                <img alt="heating" src={HeatingIcon} />
              </OverlayTrigger>
              <Form.Check
                label={t('place.facilities.heating.name')}
                disabled={disabled}
                type="checkbox"
                id="heating"
                checked={facilities.heating || false}
                onChange={() => setFacility('heating')}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip-top">{t('place.facilities.drinkableWater.tooltip')}</Tooltip>}>
                <img alt="drinkableWater" src={DrinkableWaterIcon} />
              </OverlayTrigger>
              <Form.Check
                label={t('place.facilities.drinkableWater.name')}
                disabled={disabled}
                type="checkbox"
                id="drinkableWater"
                checked={facilities.drinkable_water || false}
                onChange={() => setFacility('drinkable_water')}
              />
            </Col>
            <Col>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip-top">{t('place.facilities.money.tooltip')}</Tooltip>}>
                <img alt="money" src={MoneyIcon} />
              </OverlayTrigger>
              <Form.Check
                label={t('place.facilities.money.name')}
                disabled={disabled}
                type="checkbox"
                id="money"
                checked={facilities.money || false}
                onChange={() => setFacility('money')}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip-top">{t('place.facilities.shower.tooltip')}</Tooltip>}>
                <img alt="shower" src={ShowerIcon} />
              </OverlayTrigger>
              <Form.Check
                label={t('place.facilities.shower.name')}
                disabled={disabled}
                type="checkbox"
                id="shower"
                checked={facilities.shower || false}
                onChange={() => setFacility('shower')}
              />
            </Col>
          </Row>
          <Row className="buttonsDangerSuccess">
            <Button className="reset" variant="outline-danger" onClick={() => resetFilters('reset')}>
              {t('button.resetAll')}
            </Button>
            <Button className="reset" variant="outline-success" onClick={() => selectAllFacilities()}>
              {t('button.selectAll')}
            </Button>
          </Row>
        </Container>
      </Form.Group>
    </Row>
  );
};

Facilities.propTypes = {
  facilities: PropTypes.object,
  disabled: PropTypes.bool,
  setFacilities: PropTypes.func,
};

export default Facilities;
