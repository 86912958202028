import React from 'react';


const DropAreaMsg = () => {

  return (
    <>
      <div className="dropAreaMsgDesktop">
        <div>Drag & Drop</div>
        <div>Your Images</div>
        <div>Here</div>
      </div>
      <div className="dropAreaMsgMobile">
        <div>Click Here</div>
        <div>to</div>
        <div>Upload Images</div>
      </div>
    </>
  );
};

export default DropAreaMsg;
