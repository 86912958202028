import React from 'react';
import Draggable from 'react-draggable';
import ModalDialog from 'react-bootstrap/ModalDialog';

export default class DraggableModalDialog extends React.Component {
  render() {
    return (
      <Draggable handle=".modal-title">
        <ModalDialog {...this.props} />
      </Draggable>
    );
  }
}
