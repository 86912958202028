import { Store as DataStore } from 'pullstate';

import { getCoordCenter, getMapType, getZoomLevel } from '../utils/cookies';

export const AdminStore = new DataStore({
  profileLoaded: false,
  profile: {},
});

export const Store = new DataStore({
  mapTypeId: getMapType(),
  centerCoords: getCoordCenter(),
  lastCoords: getCoordCenter(),
  coordsFromPhoto: null,
  currentPosition: null,
  zoomLevel: getZoomLevel(),
  profile: {},
  map: null,
  locating: false,
  placesToVerify: 0,
  minimumClusterSize: 40,

  // Data store
  selectedPlaceId: null,
  selectedPlace: null,
  selectedPlaceImages: [],
  selectedPlaceComments: [],
  newFacilities: {},
  newObjectTypes: {},
  filterObjectTypes: {},
  filterFacilities: {},
  editObjectTypes: {},
  editFacilities: {},
  showOnlyFavourites: false,
  showOnlyConfirmed: false,
  showOnlyUncofirmed: false,
  selectedCountries: [],
  imagesToUpload: [],
  newPlaceImagesFiles: [],
  newPlaceImagesPreview: [],
  markerCoords: { lat: 0, lng: 0 },
  newPlace: {},
  places: [],
  commentId: null,
  translateTo: {},
  news: [],
  languages: [],
  pointOnMap: false,
  placeIconActive: false,

  // UI Store
  showNewsModal: false,
  showWhatsNewModal: false,
  showOtherProjectsModal: false,
  showTeamModal: false,
  showUnknownPlaceModal: false,
  showConfirmNewPlaceModal: false,
  showTermsModal: false,
  showPrivacyModal: false,
  showSelectedPlaceModal: false,
  showLeaderboardModal: false,
  showProfileModal: false,
  showSignInModal: false,
  showNewMarker: false,
  showCurrentLocationMarker: false,
  showGalleryModal: false,
  showEditPlaceModal: false,
  showFeedbackModal: false,
  showNewPlaceModal: false,
  showAddPhotosModal: false,
  showActivitiesModal: false,
  showSelectCountriesModal: false,
  showFilterModal: false,
  loadingPlace: false,
  loadingImages: false,
  loading: false,
  savingPlace: false,
  newPlaceLoadingImages: false,
  savingNewPlace: false,
  deletingPlace: false,
  showReportPlaceModal: false,
  showReportCommentModal: false,
  showObjectTypeHelpModal: false,
  showAddByCoordinatesModal: false,
  showPlacesToVerify: false,
});

export default Store;
