import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import MyCloseButton from '../../components/button/closeButton';
import { useTranslation } from 'react-i18next';

import Store from '../../utils/store';
import PrivacyText from '../../components/privacyText';

const PrivacyModal = () => {
  const { t } = useTranslation();

  const showPrivacyModal = Store.useState((s) => s.showPrivacyModal);

  const handleClose = () => {
    Store.update((s) => {
      s.showPrivacyModal = false;
    });
  };

  return (
    <Modal show={showPrivacyModal} onHide={handleClose} size="lg" centered>
      <Modal.Header><MyCloseButton hide={handleClose} /></Modal.Header>
      <Modal.Body>
        <PrivacyText />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          {t('button.close')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PrivacyModal;
