import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Store from '../../utils/store';
import _ from 'underscore';

const moduleName = 'dragAndDrop';

const DragAndDrop = ({ children }) => {
  const [drag, setDrag] = useState(false);
  const [dragCounter, setDragCounter] = useState(0);

  const dropRef = React.createRef();

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    console.log(`${moduleName}: handleDrag:`);
  };

  const handleDragIn = (e) => {
    e.preventDefault();
    e.stopPropagation();
    console.log(`${moduleName}: handleDragIn: ${dragCounter}`);

    setDragCounter(dragCounter + 1);
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      setDrag(true);
    }
  };
  const handleDragOut = (e) => {
    e.preventDefault();
    e.stopPropagation();
    console.log(`${moduleName}: handleDragOut: ${dragCounter}`);

    setDragCounter(dragCounter - 1);
    if (dragCounter === 0) {
      setDrag(false);
    }
  };
  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    console.log(`${moduleName}: handleDrop:`);
    setDrag(false);

    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      Store.update((s) => {
        s.imagesToUpload = _.union(s.imagesToUpload, Array.from(e.dataTransfer.files));
      });
      e.dataTransfer.clearData();
      setDragCounter(0);
    }
  };

  useEffect(() => {
    console.log(`${moduleName}: componentDidMount:`);

    let div = dropRef.current;
    div.addEventListener('dragenter', handleDragIn, { passive: false });
    div.addEventListener('dragleave', handleDragOut, { passive: false });
    div.addEventListener('dragover', handleDrag, { passive: false });
    div.addEventListener('drop', handleDrop, { passive: false });
    return () => {
      console.log(`${moduleName}: componentWillUnmount:`);
      div.removeEventListener('dragenter', handleDragIn, { passive: false });
      div.removeEventListener('dragleave', handleDragOut, { passive: false });
      div.removeEventListener('dragover', handleDrag, { passive: false });
      div.removeEventListener('drop', handleDrop, { passive: false });
    };
  });
  return (
    <div ref={dropRef} className={`${drag ? 'drag' : ''}`}>
      {drag}
      {children}
    </div>
  );
};

DragAndDrop.propTypes = {
  children: PropTypes.node,
};
export default DragAndDrop;
