import ReactGA from 'react-ga';
import { GA_TOKEN } from './constants';
import history from './history';

const moduleName = 'Analytics';

export const initializeReactGA = () => {
  // if (process.env.NODE_ENV !== 'production') return;

  ReactGA.initialize(GA_TOKEN);
  ReactGA.pageview(window.location.pathname + window.location.search);
  // Initialize google analytics page view tracking
  history.listen((location) => {
    ReactGA.set({ page: location.pathname }); // Update the user's current page
    ReactGA.pageview(location.pathname); // Record a pageview for the given page
  });
};

export const fireGAEvent = (category, action) => {
  // if (process.env.NODE_ENV !== 'production') return;
  console.log(`${moduleName}: fireGAEvent -> Category: ${category} | Action: ${action}`);
  ReactGA.event({
    category: category,
    action: action,
  });
};
