import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import { useTranslation } from 'react-i18next';
import { Row } from 'react-bootstrap';

import Store from '../utils/store';
import CoordsFromPhoto from './coordinatesFromPhoto';

const moduleName = 'Coordinate';

const Coordinates = ({ tabIndex, required, location }) => {
  const { t } = useTranslation();
  const coordsFromPhoto = Store.useState((s) => s.coordsFromPhoto);
  const disabled = Store.useState((s) => s.savingNewPlace);

  const [coordinates, setCoordinates] = useState(
    `${parseFloat(location.lat.toFixed(7))},${parseFloat(location.lng.toFixed(7))}`,
  );

  useEffect(() => {
    if (coordsFromPhoto) {
      setCoordinates(coordsFromPhoto);
      Store.update((s) => {
        s.coordsFromPhoto = null;
      });
    }
  }, [coordsFromPhoto]);

  const handleFocus = (event) => event.target.select();

  const updateCoords = (e) => {
    console.log(`${moduleName}: updateCoords -> value:`, e.target.value);
    setCoordinates(e.target.value);
    const [lt, ln] = e.target.value.replace(' ', '').split(',');

    const coords = new window.google.maps.LatLng(lt, ln).toJSON();
    console.log(`${moduleName}: updateCoords -> ${JSON.stringify(coords)}`);

    Store.update((s) => {
      s.markerCoords = coords;
      s.centerCoords = coords;
    });
  };

  return (
    <>
      <Row className="coordinates">
        <Col>
          <Form.Label  className='label'>{t('newPlace.location')}</Form.Label>
        </Col>
      </Row>

      <Container className="containerCoordinates">
        <Row>
          <Form.Group as={Col}   className="coordinate" controlId="coordinate">
            <Form.Label >{t('newPlace.coordinates')}</Form.Label>
            <Form.Control
              required={required}
              type="text"
              pattern="-?[0-9.]+,\s*-?[0-9.]+"
              onFocus={handleFocus}
              disabled={disabled}
              value={coordinates}
              onChange={updateCoords}
              tabIndex={tabIndex}
            />
            <Form.Control.Feedback type="invalid">Coordinates must delimitered by &apos;,&apos;</Form.Control.Feedback>
          </Form.Group>
        </Row>
        <CoordsFromPhoto />
      </Container>
    </>
  );
};
Coordinates.propTypes = {
  tabIndex: PropTypes.string,
  required: PropTypes.bool,
  location: PropTypes.object,
};
export default Coordinates;
