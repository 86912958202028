import { saveAs } from 'file-saver';
import React, { useState } from 'react';
import Spinner from 'react-bootstrap/Spinner';

import Gpx from '../images/icons/gpx.png';
import Kml from '../images/icons/kml.png';
import { API } from '../utils/api';
import Store from '../utils/store';

const DownloadFiles = () => {
  const filterObjectTypes = Store.useState((s) => s.filterObjectTypes);
  const filterFacilities = Store.useState((s) => s.filterFacilities);
  const selectedCountries = Store.useState((s) => s.selectedCountries);
  const showOnlyFavourites = Store.useState((s) => s.showOnlyFavourites);
  const showOnlyUncofirmed = Store.useState((s) => s.showOnlyUncofirmed);
  const showOnlyConfirmed = Store.useState((s) => s.showOnlyConfirmed);

  const [savingType, setSavingType] = useState('');
  const [saving, setSaving] = useState(false);

  const downloadFiles = (type) => {
    if (saving) return;
    setSaving(true);

    const condition = {};
    condition[type] = true;
    setSavingType(type);

    API.fetchGPXFile({
      ...filterFacilities,
      ...filterObjectTypes,
      ...condition,
      countries: selectedCountries.map((i) => i.id),
      favourites: showOnlyFavourites,
      unknown: showOnlyUncofirmed,
      confirmed: showOnlyConfirmed,
    })
      .then((res) => {
        setSaving(false);
        setSavingType(null);
        const today = new Date();
        const date = `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`;
        saveAs(res, `campwild-${date}.${type}`);
      })
      .catch((err) => {
        setSaving(false);
        console.log(err);
      });
  };

  return (
    <div className="downloadContainer">
      <>
        {savingType === 'gpx' && (
          <Spinner as="span" className="gpx" animation="border" size="sm" role="status" aria-hidden="true" />
        )}
        <img alt="location" src={Gpx} className="gpx" onClick={() => downloadFiles('gpx')} />
        {savingType === 'kml' && (
          <Spinner as="span" className="kml" animation="border" size="sm" role="status" aria-hidden="true" />
        )}
        <img alt="kml" src={Kml} className="kml" onClick={() => downloadFiles('kml')} />
      </>
    </div>
  );
};

export default DownloadFiles;
