import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Form, Modal, Spinner } from 'react-bootstrap';
import MyCloseButton from '../../components/button/closeButton';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import Coordinates from '../../components/coordinates';
import DropArea from '../../components/dnd/dropArea';
import Facilities from '../../components/facilities';
import ObjectType from '../../components/objectType';
import PasteImageArea from '../../components/pasteArea';
import { API } from '../../utils/api';
import { FACILITY_TYPES, OBJECT_TYPES } from '../../utils/constants';
import Store from '../../utils/store';
import HelpButton from '../../components/button/helpButton';
import { Row } from 'react-bootstrap';


const moduleName = 'Edit Place';

const EditPlaceModal = () => {
  const { t } = useTranslation();

  const place = Store.useState((s) => s.selectedPlace);
  const places = Store.useState((s) => s.places);
  const editFacilities = Store.useState((s) => s.editFacilities);
  const editObjectTypes = Store.useState((s) => s.editObjectTypes);
  const imagesToUpload = Store.useState((s) => s.imagesToUpload);
  const markerCoords = Store.useState((s) => s.markerCoords);

  const showEditPlaceModal = Store.useState((s) => s.showEditPlaceModal);

  const [name, setName] = useState(place.name);
  const [description, setDescription] = useState(place.description);

  const [validated, setValidated] = useState(false);
  const [saving, setSaving] = useState(false);

  const hide = () => {
    Store.update((s) => {
      s.showEditPlaceModal = false;
      s.editFacilities = {};
      s.editObjectTypes = {};
      s.imagesToUpload = [];
    });
  };

  useEffect(() => {
    Store.update((s) => {
      s.editFacilities = { ...place };
      s.editObjectTypes = { ...place };
      s.markerCoords = place.location;
    });
  }, []);

  const save = (e) => {
    const form = e.currentTarget;
    const valid = form.checkValidity();
    setValidated(true);
    e.preventDefault();
    e.stopPropagation();
    if (valid === true) {
      setSaving(true);
      const formData = new FormData();
      formData.append('name', name?.trim());
      formData.append('description', description);
      for (let i in imagesToUpload) {
        formData.append('new_images', imagesToUpload[i]);
      }
      formData.append(
        'location',
        JSON.stringify({
          latitude: markerCoords.lat,
          longitude: markerCoords.lng,
        }),
      );

      OBJECT_TYPES.map((key) => {
        formData.append(key, Boolean(editObjectTypes[key]));
      });
      FACILITY_TYPES.map((key) => {
        formData.append(key, Boolean(editFacilities[key]));
      });

      console.log(`${moduleName}: save: form valid, saving ->`);
      API.putPlace(place.id, formData)
        .then((res) => {
          console.log(`${moduleName}: save: form valid, saved ->`, res);

          const tmp = [...places];
          tmp[tmp.findIndex((x) => +x.id === res.id)] = res;
          Store.update((s) => {
            s.selectedPlace = res;
            s.places = [];
            s.places = tmp;
          });

          setSaving(false);
          hide();
        })
        .catch((err) => {
          setSaving(false);
          if (err.response && err.response.data) {
            if (err.response.data.detail) {
              toast.error(err.response.data.detail);
            }
            return;
          }

          toast.error('Could not update a place');
          console.error(`${moduleName}: save: error -> ${err}`);
        });
    }
  };

  const showObjectTypeHelpModal = () => {
    Store.update((s) => {
      s.showObjectTypeHelpModal = true;
    });
  };

  return (
    <div>
      <Modal show={showEditPlaceModal} onHide={hide} dialogClassName="editPlaceModal" className='editPlaceModal'>
        <Form validated={validated} noValidate onSubmit={save}>
          <Modal.Header  className="modalHeader">
            <Modal.Title>Edit {place.name}</Modal.Title>
            <MyCloseButton hide={hide} />
          </Modal.Header>
          <Modal.Body>
            <Container>
              <Row>
                <Form.Group as={Col}  controlId="name">
                  <Form.Label className='label'>Name</Form.Label>
                  <Form.Control
                    required={false}
                    type="text"
                    disabled={saving}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    autoCorrect="on"
                    autoFocus="autofocus"
                    autoCapitalize="on"
                    tabIndex="3"
                  />
                </Form.Group>
              </Row>
              <Row>
                <Form.Group className='description' controlId="description">
                  <Form.Label className='label'>Description</Form.Label>
                  <Form.Control
                    className="editPlaceDescription"
                    as="textarea"
                    required={false}
                    disabled={saving}
                    componentclass="textarea"
                    value={description}
                    placeholder="Write something here to help others..."
                    onChange={(e) => setDescription(e.target.value)}
                    autoCorrect="on"
                    autoCapitalize="on"
                    tabIndex="4"
                  />
                </Form.Group>
              </Row>
              <Coordinates required={false} tabIndex="3" location={place.location} />
              <Form.Label className='label'>
                <div className="objectType">
                  {t('newPlace.objectType')}
                  <HelpButton help={showObjectTypeHelpModal} />
                </div>
              </Form.Label>
              <ObjectType
                objectTypes={editObjectTypes}
                disabled={saving}
                setObjectTypes={(types) => {
                  Store.update((s) => {
                    s.editObjectTypes = types;
                  });
                }}
              />
              <Form.Label className='label'>Facilities</Form.Label>
              <Facilities
                facilities={editFacilities}
                disabled={saving}
                setFacilities={(facilities) => {
                  Store.update((s) => {
                    s.editFacilities = facilities;
                  });
                }}
              />
              <PasteImageArea />
              <DropArea className="dropZone" />
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-secondary" className="editPlaceButtonCancel" onClick={hide} disabled={saving}>
              {t('button.cancel')}
            </Button>

            <Button variant="outline-primary" className="editPlaceButtonSave" disabled={saving} type="submit">
              {saving && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />}
              {t('button.save')}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </div>
  );
};

export default EditPlaceModal;
