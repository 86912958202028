import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import { useTranslation } from 'react-i18next';
import DeleteButton from '../button/deleteButton';
import Store from '../../utils/store';

const AdminButtons = () => {
  const { t } = useTranslation();

  const editButton = () => {
    Store.update((s) => {
      s.showEditPlaceModal = true;
    });
  };

  return (
    <>
      <Row className="verification">
        <Col>
          <div
            className="buttonsWrapper">
            <DeleteButton />
            <Button variant="success" onClick={editButton}>
              {t('button.edit')}
            </Button>
          </div>
        </Col>
      </Row>
      <div className="sectionDivider" />
      <div className="sectionDividerBottomLine" />
      <div className="sectionDivider" />
    </>
  );
};

export default AdminButtons;
