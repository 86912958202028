import PropTypes from 'prop-types';
import React from 'react';

import avatarPlaceholder from '../images/icons/avatar.png';
import Store from '../utils/store';

const Avatar = ({ avatar, places_to_verify }) => {
  const profile = Store.useState((s) => s.profile);
  const showBadge = profile.is_admin && places_to_verify > 0;

  return (
    <div className="avatar">
      <img
        className="icon"
        alt="avatar"
        onError={(e) => {
          e.target.onerror = null;
          e.target.src = avatarPlaceholder;
        }}
        src={avatar ? avatar : avatarPlaceholder}
      />
      {showBadge && <span className="badge badge-pill badge-warning">{places_to_verify}</span>}
    </div>
  );
};

Avatar.propTypes = {
  avatar: PropTypes.string,
  places_to_verify: PropTypes.number,
};
export default Avatar;
