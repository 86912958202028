import PropTypes from 'prop-types';
import React from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Row from 'react-bootstrap/Row';
import Tooltip from 'react-bootstrap/Tooltip';
import { useTranslation } from 'react-i18next';
import _ from 'underscore';

import BivouacIcon from '../images/object-types/bivouac.png';
import HutIcon from '../images/object-types/hut.png';
import LeanToIcon from '../images/object-types/lean-to.png';
import RuinIcon from '../images/object-types/ruin.png';
import BasicShelterIcon from '../images/object-types/shelter.png';
import TowerIcon from '../images/object-types/tower.png';
import WeatherShelterIcon from '../images/object-types/weather-shelter.png';
import { OBJECT_TYPES } from '../utils/constants';

const moduleName = 'ObjectType';

const ObjectType = ({ objectTypes, disabled, setObjectTypes, isFilterModal }) => {
  const { t } = useTranslation();

  const setObjectType = (name) => {
    const tmp = { ...objectTypes };

    console.log(`${moduleName}: setObjectType before -> ${JSON.stringify(tmp)}`);

    tmp[name] = !objectTypes[name];

    console.log(`${moduleName}: setObjectType after -> ${JSON.stringify(tmp)}`);
    const exclusive = OBJECT_TYPES;

    if (!isFilterModal) {
      _.each(exclusive, (item) => {
        if (item === name || !OBJECT_TYPES.includes(name)) {
          return;
        }
        tmp[item] = false;
      });
    }
    console.log(`${moduleName}: setObjectType -> ${JSON.stringify(tmp)}`);
    setObjectTypes(tmp);
  };

  return (
    <Row className="facilitiesWrapper">
      <Form.Group as={Col} controlId="object-types">
        <Container className="facilities formRowObjectType">
          <Row>
            <Col>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip-top">{t('place.objectTypes.hut.tooltip')}</Tooltip>}>
                <img className="tooltipTopImg" alt="huts" src={HutIcon} />
              </OverlayTrigger>
              <Form.Check
                className="formCheck formCheckHut"
                label={t('place.objectTypes.hut.name')}
                disabled={disabled}
                type={isFilterModal ? 'checkbox' : 'radio'}
                id="hut"
                checked={objectTypes.hut || false}
                onChange={() => setObjectType('hut')}
              />
            </Col>
            <Col>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip-top">{t('place.objectTypes.tower.tooltip')}</Tooltip>}>
                <img className="tooltipTopImg" alt="tower" src={TowerIcon} />
              </OverlayTrigger>
              <Form.Check
                className="formCheck formCheckTower"
                label={t('place.objectTypes.tower.name')}
                disabled={disabled}
                type={isFilterModal ? 'checkbox' : 'radio'}
                id="tower"
                checked={objectTypes.tower || false}
                onChange={() => setObjectType('tower')}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip-top">{t('place.objectTypes.weather_shelter.tooltip')}</Tooltip>}>
                <img className="tooltipTopImg" alt="weather_shelter" src={WeatherShelterIcon} />
              </OverlayTrigger>
              <Form.Check
                className="formCheck formCheckBasicWatherShelter"
                label={t('place.objectTypes.weather_shelter.name')}
                disabled={disabled}
                type={isFilterModal ? 'checkbox' : 'radio'}
                id="wather_shelter"
                checked={objectTypes.weather_shelter || false}
                onChange={() => setObjectType('weather_shelter')}
              />
            </Col>
            <Col>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip-top">{t('place.objectTypes.bivouac.tooltip')}</Tooltip>}>
                <img className="tooltipTopImg" alt="bivouac" src={BivouacIcon} />
              </OverlayTrigger>
              <Form.Check
                className="formCheck formCheckBivouac"
                label={t('place.objectTypes.bivouac.name')}
                disabled={disabled}
                type={isFilterModal ? 'checkbox' : 'radio'}
                id="bivouac"
                checked={objectTypes.bivouac || false}
                onChange={() => setObjectType('bivouac')}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip-top">{t('place.objectTypes.leanTo.tooltip')}</Tooltip>}>
                <img className="tooltipTopImg" alt="lean-to" src={LeanToIcon} />
              </OverlayTrigger>
              <Form.Check
                className="formCheck formCheckLeanTo"
                label={t('place.objectTypes.leanTo.name')}
                disabled={disabled}
                type={isFilterModal ? 'checkbox' : 'radio'}
                id="lean-to"
                checked={objectTypes.lean_to || false}
                onChange={() => setObjectType('lean_to')}
              />
            </Col>

            <Col>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip-top">{t('place.objectTypes.ruin.tooltip')}</Tooltip>}>
                <img className="tooltipTopImg" alt="ruin" src={RuinIcon} />
              </OverlayTrigger>
              <Form.Check
                className="formCheck formCheckBasicRuin"
                label={t('place.objectTypes.ruin.name')}
                disabled={disabled}
                type={isFilterModal ? 'checkbox' : 'radio'}
                id="ruin"
                checked={objectTypes.ruin || false}
                onChange={() => setObjectType('ruin')}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip-top">{t('place.objectTypes.basic_shelter.tooltip')}</Tooltip>}>
                <img className="tooltipTopImg" alt="basic_shelter" src={BasicShelterIcon} />
              </OverlayTrigger>
              <Form.Check
                className="formCheck formCheckBasicShelter"
                label={t('place.objectTypes.basic_shelter.name')}
                disabled={disabled}
                type={isFilterModal ? 'checkbox' : 'radio'}
                id="basic_shelter"
                checked={objectTypes.basic_shelter || false}
                onChange={() => setObjectType('basic_shelter')}
              />
            </Col>
          </Row>
        </Container>
      </Form.Group>
    </Row>
  );
};

ObjectType.propTypes = {
  objectTypes: PropTypes.object,
  disabled: PropTypes.bool,
  setObjectTypes: PropTypes.func,
  isFilterModal: PropTypes.bool,
};

export default ObjectType;
