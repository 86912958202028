import moment from 'moment-timezone';
import React, { useRef, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Overlay from 'react-bootstrap/Overlay';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Row from 'react-bootstrap/Row';
import Tooltip from 'react-bootstrap/Tooltip';
import { FaSearchLocation, FaShareAlt } from 'react-icons/fa';
import { FaHeart, FaPen, FaRegHeart } from 'react-icons/fa';
import { toast } from 'react-toastify';

import Location from '../../components/place/location';
import { API } from '../../utils/api';
import Store from '../../utils/store';

const moduleName = 'Name';

const Name = () => {
  const place = Store.useState((s) => s.selectedPlace);
  const profile = Store.useState((s) => s.profile);
  const map = Store.useState((s) => s.map);
  const placeId = Store.useState((s) => s.selectedPlaceId);
  const [show, setShow] = useState(false);
  const target = useRef(null);

  const searchLocation = () => {
    console.log('fff');
    map.panTo(place.location);
  };

  const addFavorite = () => {
    console.log('add');

    API.postFavorite(placeId)
      .then(() => {
        console.log(`${moduleName}: addFavorite -> Added`);

        Store.update((s) => {
          const tmp = { ...place };
          tmp.is_favorite = true;
          s.selectedPlace = tmp;
        });
      })
      .catch(() => {
        console.log(`${moduleName}: addFavorite -> Could not add place into favorites`);
      });
  };

  const removeFavorite = () => {
    console.log('remove');
    API.deleteFavorite(placeId)
      .then(() => {
        console.log(`${moduleName}: removeFavorite -> Removed`);
        Store.update((s) => {
          const tmp = { ...place };
          tmp.is_favorite = false;
          s.selectedPlace = tmp;
        });
      })
      .catch(() => {
        toast.error('Could not remove from favorites');
        console.log(`${moduleName}: removeFavorite -> Could not remove place from favorites`);
      });
  };

  const onShareClicked = () => {
    if (navigator.share) {
      navigator
        .share({
          title: 'CampWild Place',
          url: window.location.href,
        })
        .then(() => {
          console.log('Thanks for sharing!');
        })
        .catch(console.error);
    } else {
      navigator.clipboard.writeText(window.location.href);
      setShow(true);
    }
  };

  return (
    <>
      <div className="sectionDivider" />

      <div className="nameSection">
        <h5 className="locationName"> {place.name || 'No Name'}</h5>

        <div className="iconsWrapper">
          <OverlayTrigger placement="bottom" overlay={<Tooltip id="tooltip-top">Search</Tooltip>}>
            <div className="iconSearch" href="#">
              <FaSearchLocation onClick={searchLocation} className="faSearchLocation" />
            </div>
          </OverlayTrigger>

          <OverlayTrigger
            placement="bottom"
            overlay={
              <Tooltip className="tooltip" id="tooltip-top">
                Copy link <br /> to share
              </Tooltip>
            }>
            <div className="wrapperFaSharAlt" ref={target} onMouseLeave={() => setShow(false)}>
              <FaShareAlt className="shareAlt" onClick={onShareClicked} />
            </div>
          </OverlayTrigger>
          <Overlay target={target.current} show={show} placement="right">
            {(props) => (
              <Tooltip id="overlay-copied" {...props}>
                Copied!
              </Tooltip>
            )}
          </Overlay>
          <OverlayTrigger placement="bottom" overlay={<Tooltip id="tooltip-top">Add to favorite</Tooltip>}>
            <div href="#">
              {profile.username && !place.is_favorite && <FaRegHeart onClick={addFavorite} className="faRegHeart" />}
            </div>
          </OverlayTrigger>
          <OverlayTrigger
            placement="bottom"
            overlay={
              <Tooltip id="tooltip-top">
                Remove <br /> from favorite
              </Tooltip>
            }>
            <div href="#">
              {profile.username && place.is_favorite && <FaHeart onClick={removeFavorite} className="faHeart" />}
            </div>
          </OverlayTrigger>

          {profile.username && place.editable && (
            <OverlayTrigger
              placement="bottom"
              trigger={['hover', 'focus']}
              overlay={<Tooltip id="tooltip-top">Edit place</Tooltip>}>
              <div>
                <FaPen
                  color="blue"
                  className="faPen"
                  onClick={() =>
                    Store.update((s) => {
                      s.showEditPlaceModal = true;
                    })
                  }
                />
              </div>
            </OverlayTrigger>
          )}
        </div>
      </div>

      <Row>
        <Col className="timeWhenAddedUpdated">
          <div>{`Added: ${moment.utc(place.created_at).local().fromNow()} by ${place.user.first_name} ${
            place.user.last_name
          }`}</div>
          <div>{`Updated: ${moment.utc(place.modified).local().fromNow()} by ${
            place.modified_by ? place.modified_by.first_name : 'Unknown'
          } ${place.modified_by ? place.modified_by.last_name : ''}`}</div>
        </Col>
      </Row>
      <Location />
      <div className="sectionDividerBottomLine" />
      <div className="sectionDivider" />
    </>
  );
};

export default Name;
