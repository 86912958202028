import PropTypes from 'prop-types';
import React from 'react';

const Image = ({ src, size, height, style, ...rest }) => {
  const defaults = {
    height: height || 600,
  };

  const important = {
    backgroundImage: `url("${src}")`,
    backgroundSize: size || 'cover',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
  };

  return (
    <>
      <div {...rest} style={{ ...defaults, ...style, ...important }}></div>
    </>
  );
};

Image.propTypes = {
  src: PropTypes.string,
  size: PropTypes.any,
  style: PropTypes.object,
  height: PropTypes.any,
  rest: PropTypes.object,
  imageId: PropTypes.number,
  placeId: PropTypes.number,
  isAdmin: PropTypes.bool,
};

export default Image;
