import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import MyCloseButton from '../../components/button/closeButton';
import { useTranslation } from 'react-i18next';

import Store from '../../utils/store';
import { API } from '../../utils/api';
import { toast } from 'react-toastify';

const ReportPlaceModal = () => {
  const { t } = useTranslation();
  const place = Store.useState((s) => s.selectedPlace);

  const showReportPlaceModal = Store.useState((s) => s.showReportPlaceModal);
  const [placeholder, setPlaceholder] = useState('');
  const [disabled, setDisabled] = useState(false);
  const [reason, setReason] = useState('');
  const [text, setText] = useState('');

  const close = () => {
    Store.update((s) => {
      s.showReportPlaceModal = false;
    });
  };

  const onSubmitt = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDisabled(true);
    API.reportPlace(place.id, { reason: reason, text: text, place: place.id })
      .then(() => {
        setDisabled(false);
        toast.success(t('toast.placeReported'));
        close();
      })
      .catch(() => {
        setDisabled(false);
        toast.error(t('toast.error'));
      });
  };

  const onRadioClicked = (e) => {
    console.log(e.target.id);
    if (e.target.id === 'duplicated') {
      setPlaceholder(t('reportPlace.duplicatedText'));
    }

    if (e.target.id === 'not-suitable') {
      setPlaceholder(t('reportPlace.notSuitableForOvernightText'));
    }

    if (e.target.id === 'does-not-exist') {
      setPlaceholder(t('reportPlace.doesNotExistText'));
    }

    if (e.target.id === 'incorrect-coordinates') {
      setPlaceholder(t('reportPlace.incorrectCoordinatesText'));
    }

    if (e.target.id === 'other') {
      setPlaceholder(t('reportPlace.otherText'));
    }
    setReason(e.target.id);
  };

  return (
    <Modal show={showReportPlaceModal} onHide={close} size="md" centered>
      <Modal.Header className="headerModalReport ">{t('reportPlace.header')}<MyCloseButton hide={close} /></Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Check
            type="radio"
            label={t('reportPlace.duplicated')}
            name="reasons"
            id="duplicated"
            disabled={disabled}
            onClick={onRadioClicked}
            className="formCheckReport"
          />
          <Form.Check
            type="radio"
            label={t('reportPlace.doesNotExist')}
            name="reasons"
            id="does-not-exist"
            disabled={disabled}
            onClick={onRadioClicked}
            className="formCheckReport"
          />
          <Form.Check
            type="radio"
            label={t('reportPlace.incorrectCoordinates')}
            name="reasons"
            id="incorrect-coordinates"
            disabled={disabled}
            onClick={onRadioClicked}
            className="formCheckReport"
          />
          <Form.Check
            type="radio"
            label={t('reportPlace.notSuitableForOvernight')}
            name="reasons"
            id="not-suitable"
            onClick={onRadioClicked}
            disabled={disabled}
            className="formCheckReport"
          />
          <Form.Check
            type="radio"
            label={t('reportPlace.other')}
            name="reasons"
            id="other"
            onClick={onRadioClicked}
            disabled={disabled}
            className="formCheckReport"
          />
          <Form.Control
            className="reportPlaceFormControl"
            as="textarea"
            placeholder={placeholder}
            disabled={disabled}
            value={text}
            onChange={(e) => setText(e.target.value)}
          />
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={onSubmitt} disabled={disabled | !reason}>
          {t('button.send')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ReportPlaceModal;
