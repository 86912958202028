import React from 'react';
import Button from 'react-bootstrap/Button';
import { useTranslation } from 'react-i18next';
import _ from 'underscore';

import Store from '../../utils/store';
import { API } from '../../utils/api';

const DeleteButton = () => {
  const { t } = useTranslation();

  const place = Store.useState((s) => s.selectedPlace);
  const places = Store.useState((s) => s.places);

  const deletingPlace = Store.useState((s) => s.deletingPlace);

  const deleteButton = () => {
    if (!window.confirm(t('confirmDelete'))) {
      return;
    }
    Store.update((s) => {
      s.deletingPlace = true;
    });
    API.deletePlace(place.id)
      .then(() => {
        Store.update((s) => {
          s.deletingPlace = false;
          s.places = [];
          s.selectedPlace = null;
          s.selectedPlaceId = null;
          s.showSelectedPlaceModal = false;
          s.showUnknownPlaceModal = false;
          s.places = _.reject(places, (item) => item.id === place.id);
        });
      })
      .catch(() => {
        Store.update((s) => {
          s.deletingPlace = false;
        });
      });
  };

  return (
    <Button variant="danger" onClick={deleteButton} disabled={deletingPlace}>
      {t('button.delete')}
    </Button>
  );
};

export default DeleteButton;
