import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';

import avatarPlaceholder from '../../images/icons/avatar.png';

const ActivitiesCommentTabModal = ({ activities }) => {
  return (
    <>
      {activities?.map((activity) => (
        <div className="activitiesCommentContainer" key={activity.id}>
          <div>
            <a href={window.location.origin + '/places/' + activity.place} target="_blank" rel="noreferrer">
              <div className="userName">
                <img
                  className="tabImg"
                  src={activity.user.avatar_url.length > 0 ? activity.user.avatar_url : avatarPlaceholder}
                  alt="place"
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = avatarPlaceholder;
                  }}
                />
                {activity.user.username}
              </div>

              <div className="commentText"> {activity.message}</div>
              <div>
                <div>Added: {moment.utc(activity.created_at).local().fromNow()}</div>
                <div className="sectionDivider" />
                <div className="sectionDividerBottomLine" />
                <div className="sectionDivider" />
              </div>
            </a>
          </div>
        </div>
      ))}
    </>
  );
};

ActivitiesCommentTabModal.propTypes = {
  activities: PropTypes.any,
};

export default ActivitiesCommentTabModal;
