import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';

import { Row } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import { toast } from 'react-toastify';
import MyCloseButton from '../../components/button/closeButton';

import { API } from '../../utils/api';
import Store from '../../utils/store';

const moduleName = 'Feedback';

const FeedbackModal = () => {
  const showFeedbackModal = Store.useState((s) => s.showFeedbackModal);

  const [message, setMessage] = useState();
  const [saving, setSaving] = useState(false);
  const [validated, setValidated] = useState(false);

  const hide = () => {
    Store.update((s) => {
      s.showFeedbackModal = false;
    });
  };

  const save = (e) => {
    const form = e.currentTarget;
    const valid = form.checkValidity();
    setValidated(true);

    e.preventDefault();
    e.stopPropagation();

    if (valid === true) {
      setSaving(true);
      console.log(`${moduleName}: save: Saving feedback ->`);
      const formData = new FormData();
      formData.append('message', message);

      API.postFeedback(formData)
        .then(() => {
          setSaving(false);
          hide();
          toast.info('We have received your feedback');
        })
        .catch((err) => {
          toast.error(err);
          setSaving(false);
        });
    }
  };

  return (
    <Modal show={showFeedbackModal} onHide={hide}>
      <Form validated={validated} noValidate onSubmit={save}>
        <Modal.Header>
          <Modal.Title>Share your feedback with us</Modal.Title><MyCloseButton hide={hide} />
        </Modal.Header>
        <Modal.Body className="feedbackModalBody">
          <Container>
            <Row>
              <Form.Group as={Col} controlId="feedback">
                <Form.Control
                  className="feedbackFormControl"
                  as="textarea"
                  required={true}
                  disabled={saving}
                  placeholder="Write something here..."
                  type="text"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  autoCorrect="off"
                  autoFocus="autofocus"
                  autoCapitalize="off"
                  tabIndex="1"
                />
                <Form.Control.Feedback type="invalid">Feedback cannot be empty.</Form.Control.Feedback>
              </Form.Group>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-secondary"
            className="feedbackFooterButtonCancel"
            disabled={saving}
            onClick={hide}>
            Cancel
          </Button>
          <Button variant="outline-primary" className="feedbackFooterButtonPost" disabled={saving} type="submit">
            {saving && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />}
            Post
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default FeedbackModal;
