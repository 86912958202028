import React, { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import AdminButtons from '../../components/place/adminButtons';
import Comments from '../../components/place/comments';
import Description from '../../components/place/description';
import Facilities from '../../components/place/facilities';
import Gallery from '../../components/place/gallery';
import Name from '../../components/place/name';
import Navigation from '../../components/place/navigation';
import ReplyComment from '../../components/place/replyComment';
import Verification from '../../components/place/verification';
import CloseWindowIcon from '../../images/icons/close-window.svg';
import { API } from '../../utils/api';
import history from '../../utils/history';
import Store from '../../utils/store';

// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root');

const moduleName = 'PlaceModal';

const PlaceModal = () => {
  const { t } = useTranslation();

  const place = Store.useState((s) => s.selectedPlace);
  const comments = Store.useState((s) => s.selectedPlaceComments);
  const profile = Store.useState((s) => s.profile);
  const map = Store.useState((s) => s.map);
  const placeId = Store.useState((s) => s.selectedPlaceId);
  const showSelectedPlaceModal = Store.useState((s) => s.showSelectedPlaceModal);
  const location = useLocation();

  const closeModal = () => {
    const selectedCountries = JSON.parse(localStorage.getItem('countries')) || [];
    console.log(`${moduleName}: getSelectedCountries -> ${JSON.stringify(selectedCountries)}`);

    Store.update((s) => {
      s.showSelectedPlaceModal = false;
      s.selectedPlace = null;
      s.selectedPlaceComments = [];
      s.selectedPlaceImages = [];
      s.selectedPlaceId = null;
      s.showGalleryModal = false;
      if (!selectedCountries || selectedCountries.length === 0) {
        s.showSelectCountriesModal = true;
      }
    });
    history.push('/');
  };

  useEffect(() => {
    if (location.pathname === '/') {
      Store.update((s) => {
        s.showSelectedPlaceModal = false;
        s.selectedPlace = null;
        s.selectedPlaceId = null;
      });
    }
  }, [location]);

  useEffect(() => {
    console.log(`${moduleName}: useEffect -> selectedPlaceId: ${placeId}`);

    if (!placeId) {
      return;
    }
    API.fetchImages(placeId).then((res) => {
      console.log(`${moduleName}: useEffect -> Got images:`, res);
      Store.update((s) => {
        s.selectedPlaceImages = res;
      });
    });
    API.fetchComments(placeId).then((res) => {
      console.log(`${moduleName}: useEffect -> Got comments:`, res);
      Store.update((s) => {
        s.selectedPlaceComments = res;
      });
    });
    API.fetchPlace(placeId)
      .then((res) => {
        console.log(`${moduleName}: useEffect -> Got place:`, res);
        if (res.detail === 'Not found.') {
          closeModal();
          return;
        }
        if (map) {
          map.panTo(res.location);
        } else {
          Store.update((s) => {
            s.centerCoords = res.location;
          });
        }
        Store.update((s) => {
          if (s.selectedPlace && !s.places.some((place) => place.id === Number(s.selectedPlaceId))) {
            console.log(
              `${moduleName}: useEffect -> current places:`,
              s.places.map((place) => place.id),
            );
            s.places = [...s.places].concat(res);
          }
          s.selectedPlace = res;
        });
      })
      .catch((err) => {
        if (err.response && err.response.status === 404) {
          toast.error('Place not found');
          Store.update((s) => {
            s.showSelectedPlaceModal = false;
          });
          history.push('/');
          return;
        }
        console.log(err);
      });
  }, [placeId]);

  return (
    <div>
      <Modal className="placeModal" overlayClassName="Overlay" isOpen={showSelectedPlaceModal}>
        <Container>
          <Helmet>
            <meta property="og:url" content={window.location.href} />
          </Helmet>

          {place ? (
            <>
              <Gallery />
              <img src={CloseWindowIcon} alt="" onClick={closeModal} className="closeButton"></img>
              <Name />
              {profile.is_admin && <AdminButtons />}
              {place.description?.length > 0 && <Description />}
              <Facilities />
              <Navigation showHeader={true} showDivider={true} />
              {profile.is_admin && !place.verified && <Verification />}
              {profile.username && <ReplyComment classNameInput={'addCommentInput'} placeholder={t('comment.add')} />}
              {comments?.length > 0 && <Comments />}
            </>
          ) : (
            <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
          )}
        </Container>
      </Modal>
    </div>
  );
};

export default PlaceModal;
