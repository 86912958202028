import React, { useEffect } from 'react';
import { Modal, Button, Spinner } from 'react-bootstrap';
import MyCloseButton from '../../components/button/closeButton';
import Store from '../../utils/store';
import { useTranslation } from 'react-i18next';
import { API } from '../../utils/api';

import Bivouac4 from '../../images/examples/Bivouac4.jpeg';

const News = () => {
  const showNewsModal = Store.useState((s) => s.showNewsModal);
  const loading = Store.useState((s) => s.loading);
  const { t } = useTranslation();
  const news = Store.useState((s) => s.news);
  const newsStorageValue = localStorage.getItem('newsId');

  const hide = () => {
    localStorage.setItem('newsId', `${news.id}`);
    Store.update((s) => {
      s.showNewsModal = false;
    });
  };

  useEffect(() => {
    Store.update((s) => {
      s.loading = true;
    });
    API.getNews()
      .then((res) => {
        if (res.length > 0 && Number(newsStorageValue) !== res[0].id) {
          Store.update((s) => {
            s.showNewsModal = true;
            s.news = res[0];
            s.loading = false;
          });
        }
      })
      .catch((res) => {
        console.error(res);
      });
  }, []);

  return (
    <Modal scrollable={true} className="news" show={showNewsModal} onHide={hide}>
      <Modal.Header className="header">
        <Modal.Title>What&apos;s New</Modal.Title>
        <MyCloseButton hide={hide} />
      </Modal.Header>
      {loading ? (
        <Spinner className="spinner" as="span" animation="border" size="lg" role="status" aria-hidden="true" />
      ) : (
        <Modal.Body className="body">
          <div className="imgWrapper">
            <img className="img" src={Bivouac4} alt="bivouac" />
          </div>
          <ul>
            {news.new && (
              <div>
                <span className="divider line one-line">NEW</span>

                <p dangerouslySetInnerHTML={{ __html: news.new }}></p>
              </div>
            )}
            {news.bugs && (
              <div>
                <span className="divider line one-line">BUGS</span>

                <p dangerouslySetInnerHTML={{ __html: news.bugs }}></p>
              </div>
            )}
            {news.improvments && (
              <div>
                <span className="divider line one-line">IMPROVMENTS</span>

                <p dangerouslySetInnerHTML={{ __html: news.improvments }}></p>
              </div>
            )}
            {news.upcoming && (
              <div>
                <span className="divider line one-line">UPCOMING</span>

                <p dangerouslySetInnerHTML={{ __html: news.upcoming }}></p>
              </div>
            )}
          </ul>
        </Modal.Body>
      )}
      <Modal.Footer className="footer">
        <Button className="button" variant="secondary" onClick={hide}>
          {t('button.ok')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default News;
