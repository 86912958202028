import Moment from 'moment';
import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import MyCloseButton from '../../components/button/closeButton';
import LanguageSelector from '../../components/languageSelector';
import { API } from '../../utils/api';
import Store from '../../utils/store';

const Profile = () => {
  const { t } = useTranslation();

  const profile = Store.useState((s) => s.profile);
  const showProfileModal = Store.useState((s) => s.showProfileModal);
  const [saving, setSaving] = useState(false);
  const [approved, setApproved] = useState(profile.notify_approved);
  const [comment, setComment] = useState(profile.notify_comment);
  const [image, setImage] = useState(profile.notify_image);
  const [notificationEmail, setNotificationEmail] = useState(profile.notification_email);
  const [firstName, setFirstName] = useState(profile.first_name);
  const [lastName, setLastName] = useState(profile.last_name);
  const [disableAll, setDisableAll] = useState(profile.disable_notifications);
  const selectedLanguage = Store.useState((s) => s.selectedLanguage);

  useEffect(() => {
    if (!profile.notification_email) {
      setNotificationEmail(profile.email);
    }
  }, []);

  const handleClose = () => {
    Store.update((s) => {
      s.showProfileModal = false;
    });
  };

  const handleDisableAll = (e) => {
    setApproved(!e.target.checked);
    setComment(!e.target.checked);
    setImage(!e.target.checked);
    setDisableAll(e.target.checked);
  };

  useEffect(() => {
    API.fetchLanguages()
      .then((res) => {
        Store.update((s) => {
          s.languages = res;
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleSubmit = () => {
    setSaving(true);
    const formData = new FormData();
    formData.append('notify_approved', approved);
    formData.append('notify_image', image);
    formData.append('notification_email', notificationEmail);
    formData.append('notify_comment', comment);
    formData.append('disable_notifications', disableAll);
    formData.append('first_name', firstName);
    formData.append('last_name', lastName);
    formData.append('language', selectedLanguage.code);

    API.updateProfile(formData)
      .then((res) => {
        Store.update((s) => {
          s.profile = res;
          s.selectedLanguage = res.language;
        });
        toast.success('Profile updated');
      })
      .catch((err) => {
        toast.error('Could not update profile');
        console.log(err);
      });
    setSaving(false);
    handleClose();
  };

  return (
    <Modal
      show={showProfileModal}
      onHide={handleClose}
      size="lg"
      className="profileModal"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
      <Modal.Header>
        <Modal.Title>{t('profile.header')}</Modal.Title>
        <MyCloseButton hide={handleClose} />
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="formFirstName">
            
            <Form.Label>{t('profile.firstName')}</Form.Label>
            <Form.Control value={firstName} onChange={(e) => setFirstName(e.target.value)} />
          </Form.Group>
          <Form.Group controlId="formLastName">
            <Form.Label className='formLabel'>{t('profile.lastName')}</Form.Label>
            <Form.Control value={lastName} onChange={(e) => setLastName(e.target.value)} />
          </Form.Group>
          <Form.Group controlId="formUserName">
            <Form.Label className='formLabel'>{t('profile.userName')}</Form.Label>
            <Form.Control value={profile.username} disabled={true} />
          </Form.Group>
          <Form.Group controlId="formLastLogin">
            <Form.Label className='formLabel'>{t('profile.lastLogin')}</Form.Label>
            <Form.Control type="" value={Moment(profile.last_login).format('YYYY-MM-DD HH:MM')} disabled={true} />
          </Form.Group>
          <Form.Group controlId="formBasicEmail">
            <Form.Label className='formLabel'>{t('profile.email')}</Form.Label>
            <Form.Control
              type="email"
              placeholder={t('profile.emailPlaceholder')}
              value={profile.email}
              disabled={true}
            />
          </Form.Group>
          <Form.Group controlId="formBasicEmail">
            <Form.Label className='formLabel'>{t('profile.emailNotification')}</Form.Label>
            <Form.Control
              type="email"
              placeholder={t('profile.emailPlaceholder')}
              value={notificationEmail}
              disabled={saving}
              onChange={(e) => {
                setNotificationEmail(e.target.value);
              }}
            />
          </Form.Group>
          <Form.Group controlId="formLanguage">
            <Form.Label className='formLabel'>{t('profile.languageSelector')}</Form.Label>
            <LanguageSelector />
          </Form.Group>

          <Form.Label className='formLabel'>{t('profile.checkBoxHeader')}</Form.Label>
          <Form.Check
            
            id="approved"
            type="switch"
            label={t('profile.approved')}
            checked={approved}
            onChange={(e) => {
              setApproved(e.target.checked);
              setDisableAll(false);
            }}
            disabled={saving}
          />
          <Form.Check
            
            id="comment"
            type="switch"
            label={t('profile.newComment')}
            checked={comment}
            onChange={(e) => {
              setComment(e.target.checked);
              setDisableAll(false);
            }}
            disabled={saving}
          />
          <Form.Check
            
            id="image"
            type="switch"
            label={t('profile.newImage')}
            checked={image}
            onChange={(e) => {
              setImage(e.target.checked);
              setDisableAll(false);
            }}
            disabled={saving}
          />
          <Form.Check
            
            id="disable"
            type="switch"
            label={t('profile.disableAll')}
            checked={disableAll}
            onChange={handleDisableAll}
            disabled={saving}
          />
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          {t('button.cancel')}
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          {t('button.save')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default Profile;
