import React from 'react';
import { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';

import MyCloseButton from '../../components/button/closeButton';
import avatarPlaceholder from '../../images/icons/avatar.png';
import { API } from '../../utils/api';
import Store from '../../utils/store';

const Leaderboard = () => {
  const { t } = useTranslation();
  const [leaders, setLeaders] = useState([]);
  const [loading, setLoading] = useState(false);

  const showLeaderboardModal = Store.useState((s) => s.showLeaderboardModal);

  useEffect(() => {
    setLoading(true);
    API.getLeaders()
      .then((res) => {
        setLeaders(res);
        setLoading(false);
      })
      .catch((res) => {
        console.error(res);
      });
  }, []);

  const hide = () => {
    Store.update((s) => {
      s.showLeaderboardModal = false;
    });
  };

  const getUsername = (user) => {
    if (user.user__first_name && user.user__last_name) {
      return `${user.user__first_name} ${user.user__last_name}`;
    }
    return user.user__username;
  };

  return (
    <Modal scrollable={true} show={showLeaderboardModal} onHide={hide} size="lg" className="leaderBoardModal">
      <Modal.Header className="modalHeaderCloseButton">
        <Modal.Title>{t('leaderboard.title')}</Modal.Title>
        <MyCloseButton hide={hide} />
      </Modal.Header>

      <Modal.Body className="activitiesModalBody">
        {loading ? (
          <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
        ) : (
          leaders?.map((user, index) => (
            <div className="LeaderboardUsername" key={index}>
              <div>
                <div className="userAvatar">
                  <img
                    className="tabImg"
                    src={user.user__avatar_url.length > 0 ? user.user__avatar_url : avatarPlaceholder}
                    alt="place"
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = avatarPlaceholder;
                    }}
                  />
                  <div>Username: {getUsername(user)}</div>
                  <div>Contributions: {user.contributions}</div>
                  <div className="sectionDivider" />
                  <div className="sectionDividerBottomLine" />
                  <div className="sectionDivider" />
                </div>
              </div>
            </div>
          ))
        )}
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={hide}>
          {t('button.close')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default Leaderboard;
