import React from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import { useTranslation } from 'react-i18next';

import MyCloseButton from '../../components/button/closeButton';
import HelpButton from '../../components/button/helpButton';
import CountrySelector from '../../components/countrySelector';
import DraggableModalDialog from '../../components/draggable';
import Facilities from '../../components/facilities';
import ObjectType from '../../components/objectType';
import { API } from '../../utils/api';
import Store from '../../utils/store';

export const FilterModal = () => {
  const { t } = useTranslation();
  const profile = Store.useState((s) => s.profile);

  const showFilterModal = Store.useState((s) => s.showFilterModal);
  const filterObjectTypes = Store.useState((s) => s.filterObjectTypes);
  const filterFacilities = Store.useState((s) => s.filterFacilities);
  const selectedCountries = Store.useState((s) => s.selectedCountries);
  const showOnlyFavourites = Store.useState((s) => s.showOnlyFavourites);
  const showOnlyUncofirmed = Store.useState((s) => s.showOnlyUncofirmed);
  const showOnlyConfirmed = Store.useState((s) => s.showOnlyConfirmed);

  const hide = () => {
    Store.update((s) => {
      s.showFilterModal = false;
    });
  };

  const applyFilter = () => {
    hide();
    Store.update((s) => {
      s.places = [];
    });

    API.fetchPlaces({
      ...filterObjectTypes,
      ...filterFacilities,
      ...{ countries: selectedCountries.map((i) => i.id) },
      ...{ favourites: showOnlyFavourites },
      ...{ only_unconfirmed: showOnlyUncofirmed },
      ...{ only_confirmed: showOnlyConfirmed },
    }).then((res) => {
      Store.update((s) => {
        if (s.selectedPlace && !res.some((place) => place.id === Number(s.selectedPlaceId))) {
          res.push(s.selectedPlace);
        }
        s.places = res;
      });
    });
  };

  const showObjectTypeHelpModal = () => {
    Store.update((s) => {
      s.showObjectTypeHelpModal = true;
    });
  };

  return (
    <Modal show={showFilterModal} centered onHide={hide} dialogAs={DraggableModalDialog} className="filterModal">
      <Modal.Header>
        <Modal.Title>Show Places with Facilities </Modal.Title>
        <MyCloseButton hide={hide} />
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Form.Label className="label">
            <div className="objectType">
              {t('newPlace.objectType')}
              <HelpButton help={showObjectTypeHelpModal} />
            </div>
          </Form.Label>
          <ObjectType
            objectTypes={filterObjectTypes}
            setObjectTypes={(types) => {
              Store.update((s) => {
                s.filterObjectTypes = types;
              });
            }}
            isFilterModal={true}
          />
          <Form.Label className="label">Facilities</Form.Label>
          <Facilities
            facilities={filterFacilities}
            setFacilities={(facilities) => {
              Store.update((s) => {
                s.filterFacilities = facilities;
              });
            }}
          />
          <CountrySelector />
          {profile.username && (
            <Row>
              <Col>
                <Form.Check
                  label={t('showFavourites')}
                  type="checkbox"
                  id="favourite"
                  className="filterFormCheck"
                  checked={showOnlyFavourites}
                  onChange={() =>
                    Store.update((s) => {
                      s.showOnlyFavourites = !showOnlyFavourites;
                    })
                  }
                />
              </Col>
            </Row>
          )}

          <Row>
            <Col>
              <Form.Check
                label={t('onlyConfirmedPlaces')}
                type="checkbox"
                id="confirmed"
                className="filterFormCheck"
                checked={showOnlyConfirmed}
                onChange={() =>
                  Store.update((s) => {
                    s.showOnlyUncofirmed = false;
                    s.showOnlyConfirmed = !showOnlyConfirmed;
                  })
                }
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Check
                label={t('onlyUnconfirmedPlaces')}
                type="checkbox"
                id="unconfirmed"
                className="filterFormCheck"
                checked={showOnlyUncofirmed}
                onChange={() =>
                  Store.update((s) => {
                    s.showOnlyUncofirmed = !showOnlyUncofirmed;
                    s.showOnlyConfirmed = false;
                  })
                }
              />
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button size="ms" className="mr-auto cancel" variant="outline-secondary" onClick={hide}>
          {t('button.cancel')}
        </Button>
        <Button
          size="md"
          className="ok"
          variant="outline-primary"
          onClick={applyFilter}
          disabled={selectedCountries.length === 0}>
          {t('button.ok')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default FilterModal;
