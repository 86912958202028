import React from 'react';
import { useEffect, useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { Spinner } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';

import MyCloseButton from '../../components/button/closeButton';
import { API } from '../../utils/api';
import Store from '../../utils/store';
import ActivitiesCommentTabModal from './activitiesCommentTabModal';
import ActivitiesTab from './activitiesTab';
import WhatsNewTabUsernames from './whatsNew/whatsNewTabUsernames';

const WhatsNew = () => {
  const { t } = useTranslation();
  const showWhatsNewModal = Store.useState((s) => s.showWhatsNewModal);

  const [newUsers, setNewUsers] = useState([]);
  const [newPlaces, setNewPlaces] = useState([]);
  const [newImages, setNewImages] = useState([]);
  const [newComments, setNewComments] = useState([]);

  const [loading, setLoading] = useState(false);

  const hide = () => {
    Store.update((s) => {
      s.showWhatsNewModal = false;
      s.activities = [];
    });
  };

  useEffect(() => {
    setLoading(true);
    API.getNewPlaces()
      .then((res) => {
        setNewPlaces(res);
      })
      .catch((res) => {
        console.error(res);
      });
    API.getNewImages()
      .then((res) => {
        setNewImages(res);
      })
      .catch((res) => {
        console.error(res);
      });
    API.getNewComments()
      .then((res) => {
        setNewComments(res);
      })
      .catch((res) => {
        console.error(res);
      });
    API.getNewUsers()
      .then((res) => {
        setNewUsers(res);
        setLoading(false);
      })
      .catch((res) => {
        console.error(res);
      });
  }, []);

  return (
    <Modal scrollable={true} show={showWhatsNewModal} onHide={hide} size="lg" className="activitiesModal">
      <Modal.Header className="modalHeaderCloseButton">
        <Modal.Title>{t('whatsNew.title')}</Modal.Title>
        <MyCloseButton hide={hide} />
      </Modal.Header>

      <Modal.Body className="activitiesModalBody">
        {loading ? (
          <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
        ) : (
          <Tabs id="controlled-tab-example" className="mb-3">
            <Tab eventKey="places" title={t('whatsNew.places')}>
              <ActivitiesTab activityType="Places:" activities={newPlaces} />
            </Tab>
            <Tab eventKey="comments" title={t('whatsNew.comments')}>
              <ActivitiesCommentTabModal activities={newComments} />
            </Tab>
            <Tab eventKey="newImages" title={t('whatsNew.images')}>
              <ActivitiesTab activityType="Images:" activities={newImages} />
            </Tab>
            <Tab eventKey="users" title={t('whatsNew.users')}>
              <WhatsNewTabUsernames activityType="Users:" activities={newUsers} />
            </Tab>
          </Tabs>
        )}
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={hide}>
          {t('button.close')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default WhatsNew;
