import React from 'react';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import _ from 'underscore';
import Store from '../../utils/store';
import Image from '../image';
import Close from '../../images/icons/close-btn.png';

const moduleName = 'Drop Area Images';

const DropAreaImages = () => {
  const imagesToUpload = Store.useState((s) => s.imagesToUpload);
  const savingNewPlace = Store.useState((s) => s.savingNewPlace);

  const removeImage = (e, index) => {
    e.preventDefault();
    e.stopPropagation();

    const images_copy = [...imagesToUpload];
    images_copy.splice(index, 1);
    Store.update((s) => {
      s.imagesToUpload = images_copy;
    });
    console.log(`${moduleName}: save: After Remove -> ${imagesToUpload}`);
  };

  return _.chunk(imagesToUpload, 3).map((row, rowIndex) => {
    return (
      <Form.Group as={Row} key={rowIndex} className="dropAreaImage">
        {row.map((image, colIndex) => {
          return (
            <Col xs={4} key={colIndex}>
              
              <div
                key={colIndex}
                disabled={savingNewPlace}
                type="button"
                className="closeImg"
                aria-label="Close"
                onClick={(e) => removeImage(e, rowIndex + colIndex)}>
                <img src={Close} alt="" />
              </div>
              <Image className="uploadedImage" alt="image" src={URL.createObjectURL(image)} height={100} />
            </Col>
          );
        })}
      </Form.Group>
    );
  });
};
export default DropAreaImages;
