import React from 'react';
import { useEffect, useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { Spinner } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';

import MyCloseButton from '../../components/button/closeButton';
import { API } from '../../utils/api';
import Store from '../../utils/store';
import ActivitiesCommentTabModal from './activitiesCommentTabModal';
import ActivitiesTab from './activitiesTab';

const Activities = () => {
  const { t } = useTranslation();
  const showActivitiesModal = Store.useState((s) => s.showActivitiesModal);
  const [activities, setActivities] = useState({});
  const [loading, setLoading] = useState(false);

  const hide = () => {
    Store.update((s) => {
      s.showActivitiesModal = false;
      s.activities = [];
    });
  };

  useEffect(() => {
    setLoading(true);
    API.userActivities()
      .then((res) => {
        setActivities(res);
        setLoading(false);
      })
      .catch((res) => {
        console.error(res);
      });
  }, []);

  return (
    <Modal scrollable={true} show={showActivitiesModal} onHide={hide} size="lg" className="activitiesModal">
      <Modal.Header className="modalHeaderCloseButton">
        <Modal.Title>{t('activities.title')}</Modal.Title>
        <MyCloseButton hide={hide} />
      </Modal.Header>

      <Modal.Body className="activitiesModalBody">
        {loading ? (
          <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
        ) : (
          <Tabs id="controlled-tab-example" className="mb-3">
            <Tab eventKey="viewed" title={t('activities.viewed')}>
              <ActivitiesTab event={'Viewed: '} activities={activities.viewed} />
            </Tab>
            <Tab eventKey="added" title={t('activities.added')}>
              <ActivitiesTab event={'Added: '} activities={activities.added} />
            </Tab>
            <Tab eventKey="updated" title={t('activities.updated')}>
              <ActivitiesTab event={'Updated: '} activities={activities.updated} />
            </Tab>
            <Tab eventKey="comments" title={t('activities.comments')}>
              <ActivitiesCommentTabModal activities={activities.comments} />
            </Tab>
          </Tabs>
        )}
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={hide}>
          {t('button.close')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default Activities;
