import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import SignIn from '../components/signin';

function Login() {
  return (
    <div>
      <HelmetProvider>
        <Helmet>
          <title>Login</title>
        </Helmet>
      </HelmetProvider>

      <SignIn />
    </div>
  );
}

export default Login;
