import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useTranslation } from 'react-i18next';
import CountrySelector from '../../components/countrySelector';

import Store from '../../utils/store';

const SelectCountriesModal = () => {
  const { t } = useTranslation();

  const showSelectCountriesModal = Store.useState((s) => s.showSelectCountriesModal);
  const selectedCountries = Store.useState((s) => s.selectedCountries);

  const handleClose = () => {
    Store.update((s) => {
      s.showSelectCountriesModal = false;
    });
  };

  const handleOk = () => {
    Store.update((s) => {
      s.showSelectCountriesModal = false;
    });
  };

  return (
    <Modal
      show={showSelectCountriesModal}
      onHide={handleClose}
      size="sm"
      backdrop="static"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
      <Modal.Header>
        <Modal.Title>{t('selectCountries')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{ marginBottom: '15px' }}>
          You can always change this selection via the filter button at the right side of the screen{' '}
        </div>
        <CountrySelector />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleOk} disabled={!selectedCountries || selectedCountries.length === 0}>
          {t('button.ok')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SelectCountriesModal;
