import L from 'leaflet';
import React, { useEffect } from 'react';
import { Marker, useMap } from 'react-leaflet';

import CurrentPositionPin from '../../images/icons/pin.svg';
import Store from '../../utils/store';

const moduleName = 'LocationMarker';

function LocationMarker() {
  const currentPosition = Store.useState((s) => s.currentPosition);
  const centerCoords = Store.useState((s) => s.centerCoords);
  const showCurrentLocationMarker = Store.useState((s) => s.showCurrentLocationMarker);

  const map = useMap();
  console.log(`${moduleName}: locationMarker: currentPosition`, currentPosition);
  console.log(`${moduleName}: locationMarker: centerCoords`, centerCoords);
  console.log(`${moduleName}: locationMarker: render`, showCurrentLocationMarker);

  useEffect(() => {
    console.log(`${moduleName} locationMarker: useEffect`, currentPosition);
    if (!showCurrentLocationMarker || !currentPosition) {
      console.log('locationMarker: no showCurrentLocationMarker or currentPosition');
      return;
    }

    if (centerCoords.lat && centerCoords.lng) {
      console.log(`${moduleName} locationMarker: flyTo`, centerCoords);
      map.flyTo(centerCoords, 16);
    }

  }, [map, currentPosition, centerCoords, showCurrentLocationMarker]);


  return !showCurrentLocationMarker ? null : (
    <Marker
      position={[currentPosition.lat, currentPosition.lng]}
      icon={L.icon({
        iconUrl: CurrentPositionPin,
        iconRetinaUrl: CurrentPositionPin,
        iconSize: [40, 40],
      })}></Marker>
  );
}

export default LocationMarker;
