import React from 'react';
import _ from 'underscore';
import { useTranslation } from 'react-i18next';

import Store from '../utils/store';

function PasteImageArea() {
  const { t } = useTranslation();

  const handlePasteImage = (e) => {
    if (e.clipboardData.files.length) {
      Store.update((s) => {
        s.imagesToUpload = _.union(s.imagesToUpload, Array.from(e.clipboardData.files));
      });
    }
  };

  return (
    <div className="pasteArea" onPaste={handlePasteImage}>
      <p>{t('pasteArea')}</p>
    </div>
  );
}

export default PasteImageArea;
