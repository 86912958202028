import React from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';

import avatarPlaceholder from '../../../images/icons/avatar.png';

const WhatsNewTabUsernames = ({ activities }) => {
  return (
    <>
      {activities?.map((activity, index) => (
        <div className="whatsNewUsername" key={index}>
          <div>
            {activity.message}
            <div className='userAvatar'>
              <img
                className="tabImg"
                src={activity.avatar_url.length > 0 ? activity.avatar_url : avatarPlaceholder}
                alt="place"
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = avatarPlaceholder;
                } } />
              <div>Username: {activity.username}</div>
              <div>Joined: {moment.utc(activity.created_at).local().fromNow()}</div>
              <div className="sectionDivider" />
              <div className="sectionDividerBottomLine" />
              <div className="sectionDivider" />
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

WhatsNewTabUsernames.propTypes = {
  activities: PropTypes.any,
};

export default WhatsNewTabUsernames;
