import React, { useState } from 'react';

import LocateIcon from '../images/icons/locate.svg';
import LocatingIcon from '../images/icons/locating.svg';
import Store from '../utils/store';

const moduleName = 'locatePosition';

const LocatePositionButton = () => {
  const [locating, setLocating] = useState(false);

  const onLocationClick = () => {
    console.log(`${moduleName}: onLocationClick -> `);
    if (navigator.geolocation) {
      setLocating(true);
      navigator.geolocation.getCurrentPosition((position) => {
        const pos = {
          lat: parseFloat(position.coords.latitude.toFixed(7)),
          lng: parseFloat(position.coords.longitude.toFixed(7)),
        };
        console.log(`${moduleName}: onLocationClick -> positioned: ${JSON.stringify(pos)}`);
        Store.update((s) => {
          s.currentPosition = pos;
          s.centerCoords = pos;
          s.zoomLevel = 14;
          s.markerCoords = pos;
          s.showCurrentLocationMarker = true;
          s.showNewMarker = false;
        });
        setLocating(false);
      });
    }
  };

  return (
    <div className="locatePositionIcon">
      {locating ? (
        <img disabled={locating} alt="location" src={LocatingIcon} onClick={onLocationClick} />
      ) : (
        <img disabled={locating} alt="location" src={LocateIcon} onClick={onLocationClick} />
      )}
    </div>
  );
};

export default LocatePositionButton;
