import Cookies from 'universal-cookie';

const cookies = new Cookies();
const moduleName = 'Cookies';
// 3 days
var expirationTime = new Date(new Date().getTime() + 3 * 24 * 60 * 60 * 1000);

export const saveZoomLevel = (zoomLevel) => {
  console.log(`${moduleName}: saveZoomLevel -> ${zoomLevel}`);
  cookies.set('zoomLevel', zoomLevel, { path: '/', sameSite: true, expires: expirationTime });
};

export const saveMapType = (mapTypeId) => {
  console.log(`${moduleName}: saveMapType -> ${mapTypeId}`);
  cookies.set('mapTypeId', mapTypeId, { path: '/', sameSite: true, expires: expirationTime });
};

export const getMapType = () => {
  const mapTypeId = cookies.get('mapTypeId') || 'roadmap';
  console.log(`${moduleName}: getMapType -> ${mapTypeId}`);
  return mapTypeId;
};

export const getZoomLevel = () => {
  const zoomLevel = cookies.get('zoomLevel') || 4;
  console.log(`${moduleName}: getZoomLevel -> ${zoomLevel}`);
  return parseInt(zoomLevel);
};

export const getCSRFToken = () => {
  const token = cookies.get('csrftoken');
  return token;
};

export const saveCoordCenter = (coords) => {
  const lat = typeof coords.lat === 'function' ? coords.lat() : coords.lat;
  const lng = typeof coords.lng === 'function' ? coords.lng() : coords.lng;

  console.log(`${moduleName}: saveCoordCenter -> lat: ${lat} lng: ${lng}`);

  cookies.set('centerLat', lat, { path: '/', sameSite: true, expires: expirationTime });
  cookies.set('centerLng', lng, { path: '/', sameSite: true, expires: expirationTime });
};

export const getCoordCenter = () => {
  const lat = cookies.get('centerLat');
  const lng = cookies.get('centerLng');

  if (lat === undefined || lng === undefined) {
    console.log(`${moduleName}: center coords not defined -> lat: ${lat} lng: ${lng}`);
    return { lat: 57.92872236512259, lng: 12.171900290917973 };
  }
  if (process.env.NODE_ENV === 'development') {
    console.log(`${moduleName}: getCoordCenter -> lat: ${lat} lng: ${lng}`);
  }
  return { lat: parseFloat(lat), lng: parseFloat(lng) };
};

export const getApiKey = () => {
  let api_key = cookies.get('key');
  if (!api_key) {
    const keys = process.env.REACT_APP_GMAP_KEY.split(':');
    const random = Math.floor(Math.random() * keys.length);

    api_key = keys[random];

    cookies.set('key', api_key, {
      path: '/',
      sameSite: true,
      expires: expirationTime,
    });
  }
  return api_key;
};
