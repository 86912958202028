import Pluralize from 'pluralize';
import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import MyCloseButton from '../../components/button/closeButton';
import DropArea from '../../components/dnd/dropArea';
import PasteImageArea from '../../components/pasteArea';
import { API } from '../../utils/api';
import Store from '../../utils/store';

const moduleName = 'imageUpload';

const AddPhotoModal = () => {
  const { t } = useTranslation();
  const showAddPhotosModal = Store.useState((s) => s.showAddPhotosModal);
  const place = Store.useState((s) => s.selectedPlace);
  const imagesToUpload = Store.useState((s) => s.imagesToUpload);
  const [saving, setSaving] = useState(false);
  const profile = Store.useState((s) => s.profile);

  const hide = () => {
    Store.update((s) => {
      s.imagesToUpload = [];
      s.showAddPhotosModal = false;
    });
    setSaving(false);
  };

  const save = () => {
    setSaving(true);
    console.log(`${moduleName}: save: Saving images ->`);
    const formData = new FormData();
    formData.append('place', place.id);

    for (let i in imagesToUpload) {
      formData.append('images', imagesToUpload[i]);
    }

    API.postImages(place.id, formData)
      .then((images) => {
        // assigning who uploaded images since we don't return this data when images
        // are uploaded
        for (let i in images) {
          images[i].user = profile;
        }
        Store.update((s) => {
          // merge already existing images with newly uploaded
          s.selectedPlaceImages = [...s.selectedPlaceImages].concat(images);
          // reset this so no more images to upload
          s.imagesToUpload = [];
        });
        setSaving(false);
        hide();
        toast.info(`${Pluralize('Images', images.length)} have been posted`);
      })
      .catch((err) => {
        toast.error(err);
        setSaving(false);
      });
  };

  return (
    <Modal show={showAddPhotosModal} onHide={hide}>
      <Modal.Header>
        <Modal.Title>Upload photos</Modal.Title> <MyCloseButton hide={hide} />
      </Modal.Header>
      <Modal.Body className="addPhotosBody">
        <Container>
          <PasteImageArea />
          <DropArea className="dropZone" />
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-secondary" className="addPhotosCancel" onClick={hide} disabled={saving}>
          {t('button.cancel')}
        </Button>
        <Button variant="outline-primary" className="addPhotosSave" onClick={save} disabled={saving} type="submit">
          {saving && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />}
          {t('button.save')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default AddPhotoModal;
