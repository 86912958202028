import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { Carousel } from 'react-responsive-carousel';

import Image from '../../components/image';
import CloseWindowIcon from '../../images/icons/close-window.svg';
import DeleteIcon from '../../images/icons/delete-button.svg';
import { API } from '../../utils/api';
import Store from '../../utils/store';

import 'react-responsive-carousel/lib/styles/carousel.min.css';

const moduleName = 'GalleryModal';

const GalleryModal = () => {
  const { t } = useTranslation();

  const images = Store.useState((s) => s.selectedPlaceImages);
  const placeId = Store.useState((s) => s.selectedPlaceId);
  const profile = Store.useState((s) => s.profile);

  const show = Store.useState((s) => s.showGalleryModal);
  const [selectedImageId, setSelectedImageId] = useState(0);
  const [selectedItem, setSelectedItem] = useState(0);
  const [showTrashIcon, setShowTrashIcon] = useState(profile.is_admin || (images.length > 0 && images[0].deletable));

  const hide = () => {
    Store.update((s) => {
      s.showGalleryModal = false;
    });
  };

  const handleOutsideClick = (e) => {
    if (e.target.className.includes('ReactModal__Overlay')) {
      hide();
      document.removeEventListener('click', handleOutsideClick, { passive: true });
      window.removeEventListener('keyup', handleEscapeKey, { passive: true });
    }
  };

  const slideChanged = (index) => {
    setSelectedImageId(index);
    setShowTrashIcon(profile.is_admin || images[index].deletable);
  };

  const handleEscapeKey = (e) => {
    if (e.keyCode === 27) {
      hide();
    }
  };

  useEffect(() => {
    window.addEventListener('keyup', handleEscapeKey, { passive: true });
    document.addEventListener('click', handleOutsideClick, { passive: true });

    return function cleanup() {
      window.removeEventListener('keyup', handleEscapeKey, { passive: true });
      document.removeEventListener('click', handleOutsideClick, { passive: true });
    };
  }, []);

  const deleteImage = () => {
    if (!window.confirm(t('confirmDelete'))) {
      return;
    }
    const image = images[selectedImageId];
    API.deleteImage(placeId, image.id).then(() => {
      Store.update((s) => {
        const tmp = [...s.selectedPlaceImages];
        tmp.splice(selectedImageId, 1);
        s.selectedPlaceImages = tmp;
        if (tmp.length > 1) {
          console.log(`${moduleName}: deleteImage: More than 1 image left`);
          if (selectedImageId > 0) {
            setSelectedItem(selectedImageId - 1);
          } else {
            setSelectedItem(0);
          }
          return;
        } else if (tmp.length === 1) {
          console.log(`${moduleName}: deleteImage: Only 1 image left`);
          setSelectedItem(0);
        } else {
          console.log(`${moduleName}: deleteImage: Deleted all images`);
          hide();
        }
      });
    });
  };

  return (
    <Modal isOpen={show} className="modalGallery" overlayClassName="overlayGallery">
      <div className="modalGalleryWrapper">
        {showTrashIcon && <img className="deleteIcon" src={DeleteIcon} onClick={deleteImage} />}
        <img src={CloseWindowIcon} alt="" onClick={hide} className="closeButton"></img>
      </div>

      <Carousel
        selectedItem={selectedItem}
        showThumbs={false}
        infiniteLoop
        useKeyboardArrows
        autoFocus={true}
        emulateTouch={true}
        showStatus={false}
        onChange={(index) => slideChanged(index)}>
        {images &&
          images.map((image, i) => (
            <div key={i}>
              <Image className="galleryImage" alt="" size="contain" src={image.image} height="100vw" />
              <p className="legend">{`Added ${moment.utc(image.created_at).local().fromNow()} by ${
                image.user.first_name
              } ${image.user.last_name}`}</p>
            </div>
          ))}
      </Carousel>
    </Modal>
  );
};

export default GalleryModal;
