import L from 'leaflet';
import PropTypes from 'prop-types';
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Marker as OSMMarker } from 'react-leaflet';
import { useParams } from 'react-router-dom';

import BivouacSelectedIcon from '../../images/marker-types/bivouac-selected.svg';
import BivouacIcon from '../../images/marker-types/bivouac.svg';
import HutSelectedIcon from '../../images/marker-types/hut-selected.svg';
import HutIcon from '../../images/marker-types/hut.svg';
import LeanToSelectedIcon from '../../images/marker-types/lean-to-selected.svg';
import LeanToIcon from '../../images/marker-types/lean-to.svg';
import OrangeDotIcon from '../../images/marker-types/orange-dot.svg';
import RuinSelectedIcon from '../../images/marker-types/ruin-selected.svg';
import RuinIcon from '../../images/marker-types/ruin.svg';
import ShelterSelectedIcon from '../../images/marker-types/shelter-selected.svg';
import ShelterIcon from '../../images/marker-types/shelter.svg';
import TowerSelectedIcon from '../../images/marker-types/tower-selected.svg';
import TowerIcon from '../../images/marker-types/tower.svg';
import WeatherShelterSelectedIcon from '../../images/marker-types/weather-shelter-selected.svg';
import WeatherShelterIcon from '../../images/marker-types/weather-shelter.svg';
import history from '../../utils/history';
import Store from '../../utils/store';

const moduleName = 'placeMarker';

const Marker = forwardRef(({ resetPrevious, place }, ref) => {
  const selectedPlaceId = Store.useState((s) => s.selectedPlaceId);
  const [isSelected, setIsSelected] = useState(place.id[selectedPlaceId]);
  const markerRef = useRef();
  const urlParams = useParams();

  useImperativeHandle(ref, () => ({
    reset: () => {
      reset();
    },
  }));

  useEffect(() => {
    if (urlParams.placeId && place.id.toString() == urlParams.placeId) {
      setIsSelected(true);
    }
  }, []);

  useEffect(() => {
    if (isSelected) {
      resetPrevious(place.id);
    }
  }, [resetPrevious, place.id]);

  const onMarkerClick = () => {
    console.log(`${moduleName} marker clicked`, place, isSelected);

    if (isSelected) return;

    if (place.type === 'unknown' || place.type === 'imported') {
      console.log('unknown marker clicked', place);
      Store.update((s) => {
        s.markerCoords = place.location;
        s.selectedPlace = place;
        s.selectedPlaceId = place.id;
        s.showUnknownPlaceModal = true;
        s.showSelectedPlaceModal = false;
      });
      history.push('/places');
      return;
    }

    Store.update((s) => {
      s.showSelectedPlaceModal = false;
    });

    setIsSelected(true);
    resetPrevious(place.id);
    history.push(`/places/${place.id}`);
  };

  const reset = () => {
    console.log(`${moduleName} reset`, place);
    setIsSelected(false);
  };

  const getMarkerIcon = () => {
    const iconMapping = {
      bivouac: { icon: BivouacIcon, selectedIcon: BivouacSelectedIcon, width: 40, height: 40 },
      hut: { icon: HutIcon, selectedIcon: HutSelectedIcon, width: 40, height: 40 },
      basic_shelter: { icon: ShelterIcon, selectedIcon: ShelterSelectedIcon, width: 40, height: 40 },
      weather_shelter: { icon: WeatherShelterIcon, selectedIcon: WeatherShelterSelectedIcon, width: 40, height: 40 },
      ruin: { icon: RuinIcon, selectedIcon: RuinSelectedIcon, width: 40, height: 40 },
      tower: { icon: TowerIcon, selectedIcon: TowerSelectedIcon, width: 40, height: 40 },
      'lean-to': { icon: LeanToIcon, selectedIcon: LeanToSelectedIcon, width: 40, height: 40 },
      unknown: { icon: OrangeDotIcon, selectedIcon: OrangeDotIcon, width: 20, height: 20 },
      imported: { icon: OrangeDotIcon, selectedIcon: OrangeDotIcon, width: 20, height: 20 },
    };

    const type = place.type;
    const iconData = iconMapping[type] || iconMapping.bivouac;
    const icon = isSelected ? iconData.selectedIcon : iconData.icon;

    return L.icon({
      iconUrl: icon,
      iconRetinaUrl: icon,
      iconSize: [iconData.width, iconData.height],
    });
  };

  return (
    <OSMMarker
      eventHandlers={{ click: onMarkerClick }}
      position={[place.location.lat, place.location.lng]}
      key={place.id}
      ref={markerRef}
      icon={getMarkerIcon()}
    />
  );
});

Marker.displayName = 'Marker';

Marker.propTypes = {
  place: PropTypes.object.isRequired,
  resetPrevious: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
};

export default Marker;
