import React, { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { LANGUAGES, getLanguage } from '../utils/constants';

const LangSelector = () => {
  const { i18n } = useTranslation();

  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language || window.localStorage.i18nextLng || '');

  return (
    <Dropdown className="langSelector">
      <Dropdown.Toggle variant="light">{getLanguage(selectedLanguage).code}</Dropdown.Toggle>
      <Dropdown.Menu>
        {Object.keys(LANGUAGES).map((lng) => (
          <Dropdown.Item
            key={lng}
            style={{ fontWeight: i18n.language === lng ? 'bold' : 'normal' }}
            type="submit"
            onClick={() => {
              console.log('FFFDDD');
              console.log(lng);
              i18n.changeLanguage(lng);
              setSelectedLanguage(lng);
            }}>
            {getLanguage(lng).nativeName}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};
export default LangSelector;
