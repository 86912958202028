import React, { useState } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useTranslation } from 'react-i18next';
import Linkify from 'react-linkify';

import { API } from '../../utils/api';
import Store from '../../utils/store';
import { useEffect } from 'react';

const Description = () => {
  const { t } = useTranslation();
  const profile = Store.useState((s) => s.profile);

  const [showTranslation, setShowTranslation] = useState(false);
  const [translation, setTranslation] = useState(null);
  const place = Store.useState((s) => s.selectedPlace);
  const selectedLanguage = Store.useState((s) => s.selectedLanguage);

  useEffect(() => {
    setShowTranslation(false);
    setTranslation(null);
  }, [selectedLanguage]);

  const translate = () => {
    if (!profile.username) {
      Store.update((s) => {
        s.showSignInModal = true;
      });
      return;
    }

    if (translation) {
      setShowTranslation(true);
      return;
    }
    API.translateText({ text: place.description, lang_to: selectedLanguage.code }).then((res) => {
      setTranslation(res);
      setShowTranslation(true);
    });
  };

  const componentDecorator = (href, text, key) => (
    <a href={href} key={key} target="_blank" rel="noreferrer">
      {text}
    </a>
  );
  return (
    <div>
      <Row>
        <Col>
          <div className="descriptionPlace">
            <h5>{t('place.description')}</h5>
          </div>
          <Linkify componentDecorator={componentDecorator}>
            <span className="sectionReviewText">
              {showTranslation && translation ? translation.text : place.description}
            </span>
          </Linkify>
          {!showTranslation ? (
            <div className="sectionTranslateTo" onClick={translate}>
              {t('translateTo') + ` ${selectedLanguage ? selectedLanguage?.name : ''}`}
            </div>
          ) : (
            <div className="sectionTranslateShow" onClick={() => setShowTranslation(false)}>
              {t('showOriginal')}
            </div>
          )}
        </Col>
      </Row>
      <div className="sectionDivider" />
      <div className="sectionDividerBottomLine" />
      <div className="sectionDivider" />
    </div>
  );
};

export default Description;
