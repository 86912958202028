import React from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

const PrivacyText = () => {
  return (
    <Container>
      <Row>
        <Col>
          <h3>CampWild Privacy Policy</h3>
          <p>
            This Privacy Policy describes our policies on the collection, use, and disclosure of information about you
            in connection with your use of our services, including those offered through our websites and emails
            (collectively, the <strong>&quot;Service&quot;</strong>
            ).
          </p>
          <p>
            When you use the Service, you consent to our collection, use, and disclosure of information about you as
            described in this Privacy Policy.
          </p>

          <h4 id="table-of-contents">TABLE OF CONTENTS</h4>
          <ol>
            <li>
              <p>
                <a href="#information">Information We Collect and How We Use It</a>
              </p>
            </li>
            <li>
              <p>
                <a href="#cookies">Cookies</a>
              </p>
            </li>
            <li>
              <p>
                <a href="#third-parties">Third Parties</a>
              </p>
            </li>
            <li>
              <p>
                <a href="#control-personal-data">Controlling Your Personal Data</a>
              </p>
            </li>
            <li>
              <p>
                <a href="#data-retention">Data Retention and Account Termination</a>
              </p>
            </li>
            <li>
              <p>
                <a href="#children">Children</a>
              </p>
            </li>
            <li>
              <p>
                <a href="#security">Security</a>
              </p>
            </li>
            <li>
              <p>
                <a href="#contact-information">Contact Information</a>
              </p>
            </li>
            <li>
              <p>
                <a href="#modifications">Modifications to This Privacy Policy</a>
              </p>
            </li>
          </ol>
          <h5 id="information">1. INFORMATION WE COLLECT AND HOW WE USE IT</h5>
          <p>
            We may collect and store information about you in connection with your use of the Service, including any
            information you transmit to or through the Service. We use that information to provide the Service’s
            functionality, fulfill your requests, improve the Service’s quality, engage in research and analysis
            relating to the Service, personalize your experience, track usage of the Service, provide customer support,
            message you, back up our systems, allow for disaster recovery, enhance the security of the Service, and
            comply with legal obligations. Even when we do not retain such information, it still must be transmitted to
            our servers initially and stored long enough to process.
          </p>
          <p>Please also note:</p>
          <ol type="a">
            <li>
              <p>
                <strong>Account Information:</strong> If you create a CampWild account, we may store and use the
                information you provide during that process, such as your user name, email address and other information
                you may provide with your account. We may publicly display your user name, as well as any photo or other
                content you submit through the registration process, as part of your account profile. You can modify
                some of the information associated with your account through your profile settings. If you believe that
                someone has created an unauthorized account depicting you or your likeness, you can request its removal
                by sending an email to support@campwild.org.
              </p>
            </li>
            <li>
              <p>
                <strong>Public Content:</strong> Your contributions to the Service are intended for public consumption
                and are therefore viewable by the public, including your photos, ratings, reviews and edits to already
                existing places. Your account profile (e.g., username, profile photos) is also intended for public
                consumption.
              </p>
            </li>
            <li>
              <p>
                <strong>Communications:</strong> When you sign up for an account or use certain features, you are opting
                to receive messages from CampWild. You can manage some of your messaging preferences through your
                account settings, but note that you cannot opt out of receiving certain administrative or legal messages
                from CampWild. We may also store information that you provide through communications to us from emails.
              </p>
            </li>
            <li>
              <p>
                <strong>Activity:</strong> We may store information about your use of the Service, the date and time of
                your visit. We may also store information that your computer or mobile device may provide to us in
                connection with your use of the Service, such as your browser type, type of computer or mobile device,
                browser language, IP address, location (including geolocation).
              </p>
            </li>
          </ol>
          <h5 id="cookies">2. COOKIES</h5>
          <p>
            We may use cookies (<strong>&quot;Cookies&quot;</strong>) in connection with your use of the Service Cookies
            may have unique identifiers, and reside, among other places, on your computer or mobile device, in emails we
            send to you, and on our web pages. Cookies may transmit information about you and your use of the Service,
            such as your browser type, IP address, and the date and time of your use. Cookies may be persistent or
            stored only during an individual session.
          </p>
          <p>The purposes for which we use Cookies in the Service include:</p>
          <table>
            <thead>
              <tr>
                <th>
                  <strong>Purpose</strong>
                </th>
                <th>
                  <strong>Explanation</strong>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Processes</td>
                <td>
                  Intended to make the Service work in the way you expect. For example, we use a Cookie that tells us
                  whether you have already signed up for an account.
                </td>
              </tr>
              <tr>
                <td>Authentication, Security, and Compliance</td>
                <td>
                  Intended to prevent fraud, protect your data from unauthorized parties, and comply with legal
                  requirements. For example, we use Cookies to determine if you are logged in.
                </td>
              </tr>
              <tr>
                <td>Preferences</td>
                <td>Intended to remember information about how you prefer the Service to behave and look.</td>
              </tr>
              <tr>
                <td>Notifications</td>
                <td>
                  Intended to allow or prevent notices of information or options that we think could improve your use of
                  the Service. For example, we use a Cookie that stops us from showing you the signup notification if
                  you have already seen it.
                </td>
              </tr>
              <tr>
                <td>Analytics</td>
                <td>Intended to help us understand how visitors use the Service.</td>
              </tr>
            </tbody>
          </table>
          <p>
            You can set some Cookie preferences through your device or browser settings, but doing so may affect the
            functionality of the Service. The method for disabling Cookies may vary by device and browser, but can
            usually be found in your device or browser preferences or security settings.
          </p>
          <h5 id="third-parties">3. THIRD PARTIES</h5>
          <p>Third parties may receive information about you as follows:</p>
          <ol type="a">
            <li>
              <p>
                <strong>Investigations and Legal Disclosures:</strong> We may investigate and disclose information from
                or about you if we have a good faith belief that such investigation or disclosure: (a) is reasonably
                necessary to comply with legal processes and law enforcement instructions and orders, such as a search
                warrant, subpoena, statute, judicial proceeding, or other legal process or law enforcement requests
                served on us; (b) is helpful to prevent, investigate, or identify possible wrongdoing in connection with
                the Service; or (c) protects our rights, reputation, property, or that of our users, affiliates, or the
                public. If you flag or otherwise complain to us about content through the Service, we may share the
                substance of your complaint with the contributor of that content in order to provide an opportunity for
                the contributor to respond.
              </p>
            </li>
          </ol>
          <h5 id="control-personal-data">4. CONTROLLING YOUR PERSONAL DATA</h5>
          <p>
            Other users may be able to identify you, or associate you with your account, if you include personal
            information in the content you post publicly. You can reduce the risk of being personally identified by
            using the Service pseudonymously, though doing so could detract from the credibility of your contributions
            to the Service.
          </p>
          <p>
            If you are a resident of the EEA or Switzerland, you have the right to access, correct, update or request
            deletion of your personal information. You can object to the processing of your personal information, ask us
            to restrict processing of your personal information or request portability of your personal information.
            Similarly, if we have collected and processed your personal information with your consent, then you can
            withdraw your consent at any time. Withdrawing your consent will not affect the lawfulness of any processing
            we conducted prior to your withdrawal, nor will it affect processing of your personal information conducted
            in reliance on lawful processing grounds other than consent. You also have the right to opt-out of marketing
            communications we send you at any time. The Service generally provides you with a reasonable means to view
            and change your profile information and you can opt-out of marketing communications at any time by clicking
            on the &quot;Disable all email notifications&quot; in your &quot;Profile&quot; settings. However, if you
            have any questions or concerns about the processing of your personal information, you may contact us as
            outlined in the “Contact” section below.
          </p>
          <h5 id="data-retention">5. DATA RETENTION AND ACCOUNT TERMINATION</h5>
          <p>
            You can close your account by sending an email to support@campwild.org. We will remove certain public posts
            from view and/or dissociate them from your account profile, but we may retain information about you for the
            purposes authorized under this Privacy Policy unless prohibited by law. For example, we may retain
            information to prevent, investigate, or identify possible wrongdoing in connection with the Service or to
            comply with legal obligations.
          </p>
          <h5 id="security">7. SECURITY</h5>
          <p>
            We use various safeguards to protect the personal information submitted to us, both during transmission and
            once we receive it. However, no method of transmission over the Internet or via mobile device, or method of
            electronic storage, is 100% secure. Therefore, while we strive to use commercially acceptable means to
            protect your personal information, we cannot guarantee its absolute security.
          </p>
          <h5 id="contact-information">8. CONTACT</h5>
          <p>
            You may <a href="mailto:support@campwild.org">contact us online</a> concerning our Privacy Policy, or write
            to us at the following address support@campwild.org
          </p>
          <p>
            We will take such steps as we deem necessary to confirm your identity before sharing any personal data with
            you. We will respond to proper and confirmed requests relating to personal data within 30 days, or as
            otherwise required by applicable law.
          </p>
          <h5 id="modifications">9. MODIFICATIONS TO THIS PRIVACY POLICY</h5>
          <p>
            We may revise this Privacy Policy from time to time. The most current version of the Privacy Policy will
            govern our collection, use, and disclosure of information about you and will be located{' '}
            <a href="/privacy">here</a>. If we make material changes to this Privacy Policy, we will notify you by email
            or by posting a notice on the Service prior to or on the effective date of the changes. By continuing to
            access or use the Service after those changes become effective, you acknowledge the revised Privacy Policy.
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default PrivacyText;
