import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import GoogleIcon from '../images/icons/google.svg';
import { API_URL } from '../utils/constants';

const SignIn = () => {
  const { t } = useTranslation();

  return (
    <div className="signInForm">
      <div>
        <div className="slogan">
          <p>{t('signIn.slogan_p1')}</p>
          <p>{t('signIn.slogan_p2')}</p>
        </div>
        <div className="facebookButton">
          <a
            id="facebook"
            alt="facebook"
            className="btn btn-light fb connect"
            href={`${API_URL}/social-auth/login/facebook/`}>
            {t('signIn.fbButton')}
          </a>
        </div>
        <div className="googleButton">
          <a
            id="google"
            alt="google"
            className="btn btn-light google"
            href={`${API_URL}/social-auth/login/google-oauth2/`}>
            <img src={GoogleIcon} alt="google" /> {t('signIn.googleButton')}
          </a>
        </div>
        <p id="disclaimer">
          {t('signIn.footer_p1')}{' '}
          <Link target="_blank" to={'/terms'}>
            {t('signIn.terms')}{' '}
          </Link>
          {t('signIn.and')}{' '}
          <Link target="_blank" to={'/privacy'}>
            {t('signIn.privacy')}
          </Link>
          .
        </p>
      </div>
    </div>
  );
};

export default SignIn;
