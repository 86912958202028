import React, { useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import MyCloseButton from '../../components/button/closeButton';
import GoogleIcon from '../../images/icons/google.svg';
import { API_URL } from '../../utils/constants';
import Store from '../../utils/store';

const SignIn = () => {
  const { t } = useTranslation();

  const showSignInModal = Store.useState((s) => s.showSignInModal);

  useEffect(() => {
    // saving url before sign in so we can restore it later
    localStorage.setItem('preSignUrl', window.location.href);
  }, []);

  const handleClose = () => {
    localStorage.removeItem('preSignUrl');
    Store.update((s) => {
      s.showSignInModal = false;
    });
  };

  return (
    <Modal
      show={showSignInModal}
      onHide={handleClose}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
      <Modal.Header>
        <Modal.Title>{t('signIn.modalHeader')}</Modal.Title>
        <MyCloseButton hide={handleClose} />
      </Modal.Header>
      <Modal.Body>
        <div className="signPage">
          <div className="signGroup">
            <div className="slogan">
              <p>{t('signIn.slogan_p1')}</p>
              <p>{t('signIn.slogan_p2')}</p>
            </div>
            <div className="facebookButton">
              <a
                id="facebook"
                alt="facebook"
                className="btn btn-light fb connect"
                href={`${API_URL}/social-auth/login/facebook/`}>
                {t('signIn.fbButton')}
              </a>
            </div>
            <div className="googleButton">
              <a
                id="google"
                alt="google"
                className="btn btn-light google"
                href={`${API_URL}/social-auth/login/google-oauth2/`}>
                <img src={GoogleIcon} alt="google" /> {t('signIn.googleButton')}
              </a>
            </div>

            <p id="disclaimer">
              {t('signIn.footer_p1')}{' '}
              <Link target="_blank" to={'/terms'}>
                {t('signIn.terms')}{' '}
              </Link>
              {t('signIn.and')}{' '}
              <Link target="_blank" to={'/privacy'}>
                {t('signIn.privacy')}
              </Link>
              .
            </p>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SignIn;
