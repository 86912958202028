import BasicShelterIcon from '../../images/object-types/shelter.svg';
import WheatherShelterIcon from '../../images/object-types/weather-shelter.svg';
import React from 'react';
import Col from 'react-bootstrap/Col';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Row from 'react-bootstrap/Row';
import Tooltip from 'react-bootstrap/Tooltip';
import { useTranslation } from 'react-i18next';

import MoneyIcon from '../../images/facilities/money.svg';
import BivouacIcon from '../../images/object-types/bivouac.svg';
import TowerIcon from '../../images/object-types/tower.svg';
import FireplaceIcon from '../../images/facilities/fireplace.svg';
import CanSwimIcon from '../../images/facilities/canSwim.svg';
import DrinkableWaterIcon from '../../images/facilities/drinkableWater.svg';
import ElectricityIcon from '../../images/facilities/electricity.svg';
import GrillIcon from '../../images/facilities/grill.svg';
import HutIcon from '../../images/object-types/hut.svg';
import NearWaterIcon from '../../images/facilities/nearWater.svg';
import NearRoadIcon from '../../images/facilities/nearRoad.svg';
import ParkingIcon from '../../images/facilities/parking.svg';
import RuinIcon from '../../images/object-types/ruin.svg';
import LeanToIcon from '../../images/object-types/lean-to.svg';
import TableIcon from '../../images/facilities/table.svg';
import ToiletIcon from '../../images/facilities/toilet.svg';
import TrashIcon from '../../images/facilities/trash.svg';
import WoodIcon from '../../images/facilities/firewood.svg';
import HeatingIcon from '../../images/facilities/heating.svg';
import ShowerIcon from '../../images/facilities/shower.svg';

import Store from '../../utils/store';

const Facilities = () => {
  const { t } = useTranslation();

  const place = Store.useState((s) => s.selectedPlace);


  return (
    <div>
      <Row>
        <Col>
          <div className="facilitesCol">
            <h5>{t('place.facilities.header')}</h5>
          </div>
          <div className="sectionDivider" />
          <span className="sectionReviewText">
            <div className="facilitiesIcons">
              {place.bivouac && (
                <div className="facilitesIconAndName">
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="tooltip-top">{t('place.objectTypes.bivouac.tooltip')}</Tooltip>}>
                    <img alt="bivouac" src={BivouacIcon} />
                  </OverlayTrigger>
                  {t('place.objectTypes.bivouac.name')}
                </div>
              )}
              {place.parking && (
                <div className="facilitesIconAndName">
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="tooltip-top">{t('place.facilities.parking.tooltip')}</Tooltip>}>
                    <img alt="parking" src={ParkingIcon} />
                  </OverlayTrigger>
                  {t('place.facilities.parking.name')}
                </div>
              )}
              {place.heating && (
                <div className="facilitesIconAndName">
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="tooltip-top">{t('place.facilities.heating.tooltip')}</Tooltip>}>
                    <img alt="heating" src={HeatingIcon} />
                  </OverlayTrigger>
                  {t('place.facilities.heating.name')}
                </div>
              )}
              {place.near_road && (
                <div className="facilitesIconAndName">
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="tooltip-top">{t('place.facilities.nearRoad.tooltip')}</Tooltip>}>
                    <img alt="nearRoad" src={NearRoadIcon} />
                  </OverlayTrigger>
                  {t('place.facilities.nearRoad.name')}
                </div>
              )}
              {place.table && (
                <div className="facilitesIconAndName">
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="tooltip-top">{t('place.facilities.table.tooltip')}</Tooltip>}>
                    <img alt="table" className="tableIconPlace" src={TableIcon} />
                  </OverlayTrigger>
                  {t('place.facilities.table.name')}
                </div>
              )}
              {place.lean_to && (
                <div className="facilitesIconAndName">
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="tooltip-top">{t('place.objectTypes.leanTo.tooltip')}</Tooltip>}>
                    <img alt="lean_to" src={LeanToIcon} />
                  </OverlayTrigger>
                  {t('place.objectTypes.leanTo.name')}
                </div>
              )}
              {place.trash && (
                <div className="facilitesIconAndName">
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="tooltip-top">{t('place.facilities.trash.tooltip')}</Tooltip>}>
                    <img alt="trash" src={TrashIcon} />
                  </OverlayTrigger>
                  {t('place.facilities.trash.name')}
                </div>
              )}
              {place.near_water && (
                <div className="facilitesIconAndName">
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="tooltip-top">{t('place.facilities.nearWater.tooltip')}</Tooltip>}>
                    <img alt="nearWater" src={NearWaterIcon} />
                  </OverlayTrigger>
                  {t('place.facilities.nearWater.name')}
                </div>
              )}
              {place.drinkable_water && (
                <div className="facilitesIconAndName">
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="tooltip-top">{t('place.facilities.drinkableWater.tooltip')}</Tooltip>}>
                    <img alt="drinkableWater" src={DrinkableWaterIcon} />
                  </OverlayTrigger>
                  {t('place.facilities.drinkableWater.name')}
                </div>
              )}
              {place.shower && (
                <div className="facilitesIconAndName">
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="tooltip-top">{t('place.facilities.shower.tooltip')}</Tooltip>}>
                    <img alt="shower" src={ShowerIcon} />
                  </OverlayTrigger>
                  {t('place.facilities.shower.name')}
                </div>
              )}
              {place.hut && (
                <div className="facilitesIconAndName">
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="tooltip-top">{t('place.objectTypes.hut.tooltip')}</Tooltip>}>
                    <img alt="hut" src={HutIcon} />
                  </OverlayTrigger>
                  {t('place.objectTypes.hut.name')}
                </div>
              )}
              {place.can_swim && (
                <div className="facilitesIconAndName">
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="tooltip-top">{t('place.facilities.canSwim.tooltip')}</Tooltip>}>
                    <img alt="can_swim" src={CanSwimIcon} />
                  </OverlayTrigger>
                  {t('place.facilities.canSwim.name')}
                </div>
              )}
              {place.toilet && (
                <div className="facilitesIconAndName">
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="tooltip-top">{t('place.facilities.toilet.tooltip')}</Tooltip>}>
                    <img alt="toilet" src={ToiletIcon} />
                  </OverlayTrigger>
                  {t('place.facilities.toilet.name')}
                </div>
              )}
              {place.firewood && (
                <div className="facilitesIconAndName">
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="tooltip-top">{t('place.facilities.firewood.tooltip')}</Tooltip>}>
                    <img alt="firewood" src={WoodIcon} />
                  </OverlayTrigger>
                  {t('place.facilities.firewood.name')}
                </div>
              )}
              {place.fireplace && (
                <div className="facilitesIconAndName">
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="tooltip-top">{t('place.facilities.fireplace.tooltip')}</Tooltip>}>
                    <img alt="fireplace" src={FireplaceIcon} />
                  </OverlayTrigger>
                  {t('place.facilities.fireplace.name')}
                </div>
              )}
              {place.grill && (
                <div className="facilitesIconAndName">
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="tooltip-top">{t('place.facilities.grill.tooltip')}</Tooltip>}>
                    <img alt="grill" src={GrillIcon} />
                  </OverlayTrigger>
                  {t('place.facilities.grill.name')}
                </div>
              )}
              {place.money && (
                <div className="facilitesIconAndName">
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="tooltip-top">{t('place.facilities.money.tooltip')}</Tooltip>}>
                    <img alt="money" src={MoneyIcon} />
                  </OverlayTrigger>
                  {t('place.facilities.money.name')}
                </div>
              )}
              {place.ruin && (
                <div className="facilitesIconAndName">
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="tooltip-top">{t('place.objectTypes.ruin.tooltip')}</Tooltip>}>
                    <img alt="ruin" src={RuinIcon} />
                  </OverlayTrigger>
                  {t('place.objectTypes.ruin.name')}
                </div>
              )}
              {place.basic_shelter && (
                <div className="facilitesIconAndName">
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="tooltip-top">{t('place.objectTypes.basic_shelter.tooltip')}</Tooltip>}>
                    <img alt="basic_shelter" src={BasicShelterIcon} />
                  </OverlayTrigger>
                  {t('place.objectTypes.basic_shelter.name')}
                </div>
              )}
              {place.weather_shelter && (
                <div className="facilitesIconAndName">
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="tooltip-top">{t('place.objectTypes.weather_shelter.tooltip')}</Tooltip>}>
                    <img alt="weather_shelter" src={WheatherShelterIcon} />
                  </OverlayTrigger>
                  {t('place.objectTypes.weather_shelter.name')}
                </div>
              )}
              {place.tower && (
                <div className="facilitesIconAndName">
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="tooltip-top">{t('place.objectTypes.tower.tooltip')}</Tooltip>}>
                    <img alt="tower" src={TowerIcon} />
                  </OverlayTrigger>
                  {t('place.objectTypes.tower.name')}
                </div>
              )}
              {place.electricity && (
                <div className="facilitesIconAndName">
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="tooltip-top">{t('place.facilities.electricity.tooltip')}</Tooltip>}>
                    <img alt="electricity" src={ElectricityIcon} />
                  </OverlayTrigger>
                  {t('place.facilities.electricity.name')}
                </div>
              )}
            </div>
          </span>
        </Col>
      </Row>
      <div className="sectionDivider" />
      <div className="sectionDividerBottomLine" />
      <div className="sectionDivider" />
    </div>
  );
};
export default Facilities;
