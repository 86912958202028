import Leaderboard from './modals/leaderboard';
import { loadProgressBar } from 'axios-progress-bar';
import React, { useEffect } from 'react';
import CookieConsent from 'react-cookie-consent';
import { useParams } from 'react-router-dom';

import Store from '../utils/store';
import Header from './../components/header';

import Activities from './modals/activities';
import AddByCoordinates from './modals/addByCoordinates';
import AddPhotoModal from './modals/addPhotos';
import ConfirmNewPlaceModal from './modals/confirmNewPlace';
import EditPlaceModal from './modals/editPlace';
import FeedbackModal from './modals/feedback';
import FilterModal from './modals/filter';
import GalleryModal from './modals/gallery';
import NewPlaceModal from './modals/newPlace';
import News from './modals/news';
import ObjectTypeHelp from './modals/objectTypeHelp.jsx';
import OtherProjectsModal from './modals/otherProjects';
import PlaceModal from './modals/place';
import PrivacyModal from './modals/privacy';
import Profile from './modals/profile';
import ReportComment from './modals/reportComment';
import ReportPlaceModal from './modals/reportPlace';
import SelectCountriesModal from './modals/selectCountries';
import SignIn from './modals/signIn';
import TeamModal from './modals/team';
import TermsModal from './modals/terms';
import UnknownPlace from './modals/unknownPlace';
import WhatsNew from './modals/whatsNew';
import OSMMap from './osmMap';
// import GMap from './gMap';

import { API } from '../utils/api';

import 'axios-progress-bar/dist/nprogress.css';
const moduleName = 'Main';

const Main = () => {
  loadProgressBar();

  const urlParams = useParams();

  const showNewsModal = Store.useState((s) => s.showNewsModal);
  const showObjectTypeHelpModal = Store.useState((s) => s.showObjectTypeHelpModal);
  const showSelectedPlaceModal = Store.useState((s) => s.showSelectedPlaceModal);
  const showGalleryModal = Store.useState((s) => s.showGalleryModal);
  const showFilterModal = Store.useState((s) => s.showFilterModal);
  const showFeedbackModal = Store.useState((s) => s.showFeedbackModal);
  const showEditPlaceModal = Store.useState((s) => s.showEditPlaceModal);
  const showNewPlaceModal = Store.useState((s) => s.showNewPlaceModal);
  const showProfileModal = Store.useState((s) => s.showProfileModal);
  const showTeamModal = Store.useState((s) => s.showTeamModal);
  const showReportPlaceModal = Store.useState((s) => s.showReportPlaceModal);
  const showUnknownPlaceModal = Store.useState((s) => s.showUnknownPlaceModal);
  const showOtherProjectsModal = Store.useState((s) => s.showOtherProjectsModal);
  const showActivitiesModal = Store.useState((s) => s.showActivitiesModal);
  const showSignInModal = Store.useState((s) => s.showSignInModal);
  const showReportCommentModal = Store.useState((s) => s.showReportCommentModal);
  const showSelectCountriesModal = Store.useState((s) => s.showSelectCountriesModal);
  const showAddByCoordinatesModal = Store.useState((s) => s.showAddByCoordinatesModal);
  const showWhatsNewModal = Store.useState((s) => s.showWhatsNewModal);
  const showLeaderboardModal = Store.useState((s) => s.showLeaderboardModal);

  /**
   * This hook is used to load the same url after sign in
   * basically to open a place window if a user wants to use some
   * extra functionality which is available only for registered users
   */
  useEffect(() => {
    const preSignUrl = localStorage.getItem('preSignUrl');

    // open a URL before sign in
    if (preSignUrl !== null) {
      localStorage.removeItem('preSignUrl');
      window.location = preSignUrl;
    }

    // open a specific place if a user came by a direct link
    if (urlParams.placeId) {
      Store.update((s) => {
        s.showSelectedPlaceModal = true;
        s.selectedPlaceId = urlParams.placeId;
        s.zoomLevel = 13;
      });
    }
  }, [urlParams.placeId]);

  /**
   * To filter places we need to put everything into url params
   * When url is changed we trigger this hook
   */
  useEffect(() => {
    const selectedCountries = JSON.parse(localStorage.getItem('countries')) || [];
    console.log(`${moduleName}: getSelectedCountries -> ${JSON.stringify(selectedCountries)}`);

    Store.update((s) => {
      s.selectedCountries = selectedCountries;
      // if no selected countries then force users to select ones by showing this modal window
      if (!selectedCountries || selectedCountries.length === 0) {
        s.showSelectCountriesModal = true;
      } else {
        // fetch places for selected countries
        API.fetchPlaces({ ...{ countries: selectedCountries.map((i) => i.id) } }).then((res) => {
          console.log(`${moduleName}: useEffect fetchPlaces -> Got places`);

          Store.update((s) => {
            console.log(`${moduleName}: useEffect fetchPlaces selectedPlaceId -> ${s.selectedPlaceId}`);
            // if a user already followed a link to a specific place then we need to keep showing
            // a marker for this place even if selected countries do not contain already opened place
            // so here we do a check if an id is in the list of returned place, then no need to add
            // it there
            if (s.selectedPlace && !res.some((place) => place.id === Number(s.selectedPlaceId))) {
              console.log(`${moduleName}: useEffect adding selected place  -> ${s.selectedPlace}`);
              res.push(s.selectedPlace);
            }
            s.places = res;
          });
        });
      }
    });
  }, [showSelectCountriesModal]);

  return (
    <>
      {showLeaderboardModal && <Leaderboard />}
      {showWhatsNewModal && <WhatsNew />}
      {showActivitiesModal && <Activities />}
      {showNewsModal && <News />}
      {showProfileModal && <Profile />}
      {showSignInModal && <SignIn />}
      {showTeamModal && <TeamModal />}
      {showSelectedPlaceModal && <PlaceModal />}
      {showUnknownPlaceModal && <UnknownPlace />}
      {showOtherProjectsModal && <OtherProjectsModal />}
      {showSelectCountriesModal && <SelectCountriesModal />}
      {showAddByCoordinatesModal && <AddByCoordinates />}
      <AddPhotoModal />
      {showNewPlaceModal && <NewPlaceModal />}
      {showEditPlaceModal && <EditPlaceModal />}
      <TermsModal />
      <PrivacyModal />
      {showFilterModal && <FilterModal />}
      {showObjectTypeHelpModal && <ObjectTypeHelp />}
      {showReportPlaceModal && <ReportPlaceModal />}
      {showReportCommentModal && <ReportComment />}
      <ConfirmNewPlaceModal />
      {showFeedbackModal && <FeedbackModal />}
      {showGalleryModal && <GalleryModal />}

      <Header />
      {/* <GMap /> */}
      <OSMMap />

      <CookieConsent>
        This website uses cookies to enhance the user experience. Read more in{' '}
        <a
          className="cookieConsentA"
          onClick={() =>
            Store.update((s) => {
              s.showPrivacyModal = true;
            })
          }>
          Privacy Policy
        </a>
      </CookieConsent>
    </>
  );
};

export default Main;
