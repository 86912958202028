// export const API_URL = process.env.REACT_APP_API_URL || 'https://api.campwild.org';
export const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:8000';
// export const API_URL = process.env.REACT_APP_API_URL || 'http://192.168.1.131:8000';
// export const API_URL = process.env.REACT_APP_API_URL || 'https://dev.api.campwild.org';

export const VERSION = process.env.REACT_APP_VERSION;
export const GA_TOKEN = process.env.REACT_APP_GA_TRACKING_ID;

export const OBJECT_TYPES = ['hut', 'lean_to', 'bivouac', 'ruin', 'tower', 'basic_shelter', 'weather_shelter'];
export const FACILITY_TYPES = [
  'fireplace',
  'parking',
  'grill',
  'trash',
  'electricity',
  'table',
  'firewood',
  'toilet',
  'can_swim',
  'near_water',
  'drinkable_water',
  'money',
  'heating',
  'near_road',
  'shower',
];

export const LANGUAGES = {
  en: { nativeName: 'English', code: 'EN' },
  de: { nativeName: 'Deutsch', code: 'DE' },
  se: { nativeName: 'Svenska', code: 'SE' },
  ru: { nativeName: 'Русский', code: 'RU' },
  fr: { nativeName: 'Français', code: 'FR' },
};

export const getLanguage = (lang) => {
  if (lang in LANGUAGES) {
    return LANGUAGES[lang];
  }
  return LANGUAGES['en'];
};

export const getApiKey = () => {
  return process.env.REACT_APP_API_KEY;
};
