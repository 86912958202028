import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import MyCloseButton from '../../components/button/closeButton';
import { useTranslation } from 'react-i18next';

import Store from '../../utils/store';
import TermsText from '../../components/termsText';

const TermsModal = () => {
  const { t } = useTranslation();

  const showTermsModal = Store.useState((s) => s.showTermsModal);

  const handleClose = () => {
    Store.update((s) => {
      s.showTermsModal = false;
    });
  };

  return (
    <Modal
      show={showTermsModal}
      onHide={handleClose}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
      <Modal.Header><MyCloseButton hide={handleClose} /></Modal.Header>
      <Modal.Body>
        <TermsText />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          {t('button.close')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default TermsModal;
