import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { API } from '../../utils/api';
import Store from '../../utils/store';
import Avatar from '../avatar';

const moduleName = 'ReplyComment';

const ReplyComment = ({ replyToCommentId, hideReplyForm, placeholder, classNameInput }) => {
  const { t } = useTranslation();
  const profile = Store.useState((s) => s.profile);
  const place = Store.useState((s) => s.selectedPlace);
  const [text, setText] = useState('');
  const [saving, setSaving] = useState(false);

  const handleChange = (e) => {
    setText(e.target.value);
  };

  const textareaRef = useRef(null);

  useEffect(() => {
    textareaRef.current.style.height = '0px';
    const { scrollHeight } = textareaRef.current;
    textareaRef.current.style.height = `${scrollHeight}px`;
  }, [text]);

  const sendReply = () => {
    setSaving(true);
    const payload = { message: text, place: place.id, reply_to: replyToCommentId };
    console.log(`${moduleName}: sendReply: Saving message -> ${JSON.stringify(payload)}`);

    API.postComment(place.id, payload)
      .then((comment) => {
        Store.update((s) => {
          comment['user'] = profile;
          let tmp = [...s.selectedPlaceComments];
          tmp.push(comment);
          s.selectedPlaceComments = tmp;
        });
        setText('');
        setSaving(false);
        if (hideReplyForm) {
          hideReplyForm();
        }
        toast.info('Reply has been posted');
      })
      .catch((err) => {
        toast.error('Could not save reply');
        setSaving(false);
        console.log(err);
        console.log(`${moduleName}: sendReply: Error -> ${JSON.stringify(err)}`);
      });
  };

  return (
    <>
      <div className="replyComment">
        <Avatar avatar={profile?.avatar_url} />
        <textarea
          ref={textareaRef}
          value={text}
          disabled={saving}
          onChange={handleChange}
          className={classNameInput}
          type="text"
          placeholder={placeholder}
        />
      </div>
      <div className="sendButton">
        {text.length > 0 && (
          <Button className="sendCommentBtn" disabled={saving} onClick={sendReply} variant="outline-primary">
            {t('button.send')}
          </Button>
        )}
      </div>
    </>
  );
};

ReplyComment.propTypes = {
  replyToCommentId: PropTypes.any,
  hideReplyForm: PropTypes.func,
  placeholder: PropTypes.any,
  classNameInput: PropTypes.string,
};

export default ReplyComment;
