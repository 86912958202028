import React from 'react';
import Help from '../../images/icons/help.png';
import PropTypes from 'prop-types';

const HelpButton = ({ help }) => {
  return (
    <div className="helpBtn">
      <img alt="help" src={Help} onClick={help} />
    </div>
  );
};

HelpButton.propTypes = {
  help: PropTypes.func,
};

export default HelpButton;
