import PropTypes from 'prop-types';
import React from 'react';

import CloseBtn from '../../images/icons/close-btn.png';
import { Button } from 'react-bootstrap';

const MyCloseButton = ({ children, props, hide }) => {
  return (
    <Button onClick={hide} {...props} variant="outline-secondary" className="closeButton">
      <img src={CloseBtn} className="btnCloseModalImg" alt="" />
      {children}
    </Button>
  );
};

MyCloseButton.propTypes = {
  children: PropTypes.any,
  props: PropTypes.any,
  hide: PropTypes.func,
};

export default MyCloseButton;
