import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import history from '../../utils/history';
import Store from '../../utils/store';
import { API } from '../../utils/api';

const Verification = () => {
  const { t } = useTranslation();

  const place = Store.useState((s) => s.selectedPlace);

  const verify = () => {
    API.verifyPlace(place.id)
      .then((res) => {
        Store.update((s) => {
          s.selectedPlace = res;
          if (s.placesToVerify > 0) {
            s.placesToVerify = --s.placesToVerify;
          }
        });
        toast.info(t('toast.placeVerified'));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const decline = () => {
    API.declinePlace(place.id)
      .then(() => {
        Store.update((s) => {
          s.selectedPlace = null;
          s.selectedPlaceId = null;
          s.showSelectedPlaceModal = false;
          if (s.placesToVerify > 0) {
            s.placesToVerify = --s.placesToVerify;
          }
        });

        history.push('/');
        toast.info(t('toast.placeDeclined'));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Row className="verification">
        <Col>
          <h5>Verification</h5>
          <div className="verificationButtons">
            <Button variant="danger" onClick={decline}>
              {t('place.decline')}
            </Button>
            <Button variant="success" onClick={verify}>
              {t('place.verify')}
            </Button>
          </div>
        </Col>
      </Row>
      <div className="sectionDivider" />
      <div className="sectionDividerBottomLine" />
      <div className="sectionDivider" />
    </>
  );
};

export default Verification;
