import React, { useState } from 'react';
import { Button, Col, FormControl } from 'react-bootstrap';
import { InputGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FaSearch } from 'react-icons/fa';
import { toast } from 'react-toastify';

import { API } from '../utils/api';
import Store from '../utils/store';
import GeoPoint from '../utils/utils';

const SearchBy = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const { t } = useTranslation();
  const search = () => {
    let lt = 0.0;
    let ln = 0.0;
    if (!searchTerm) {
      return;
    }
    if (searchTerm.indexOf('°') !== -1) {
      [lt, ln] = searchTerm.match(/-?\d+°\s*\d+.\d+'/g);
      const geo = new GeoPoint(ln, lt);
      lt = geo.getLatDec();
      ln = geo.getLonDec();
    } else {
      const result = searchTerm.match(/-?\d+.\d+/g);
      if (result) {
        [lt, ln] = result;
      }
    }
    if (searchTerm && (!lt || !ln)) {
      toast.error('Invalid corrdinates');
      return;
    }

    const pos = { lat: parseFloat(lt), lng: parseFloat(ln) };

    const query = searchTerm ? pos : {};
    API.searchPlaces({ location: JSON.stringify(query) })
      .then((res) => {
        console.log(`searchPlaces: ${JSON.stringify(res)}`);
        Store.update((s) => {
          s.centerCoords = res[0].location;
          s.currentPosition = res[0].location;
          s.zoomLevel = 15;
          s.showAddByCoordinatesModal = false;
        });
      })
      .catch((err) => {
        if (err.response && err.response.status === 404) {
          Store.update((s) => {
            s.showConfirmNewPlaceModal = true;
            s.markerCoords = pos;
          });
          return;
        }

        toast.error('Invalid request');
      });
  };
  const handleFocus = (event) => event.target.select();

  const onEnter = (e) => {
    if (e.code === 'Enter') {
      search();
    }
  };

  const handleChange = (e) => {
    setSearchTerm(e.target.value);
  };

  return (
    <Col className="searchField">
      <InputGroup className="inputGroup">
        <FormControl
          placeholder={t('place.searchBy')}
          value={searchTerm}
          pattern="-?[0-9.]+,\s*-?[0-9.]+"
          onChange={handleChange}
          onKeyUp={onEnter}
          onClick={handleFocus}
        />
        <Button variant="outline-success" onClick={search}>
          <FaSearch className="faSearch" />
        </Button>
      </InputGroup>
    </Col>
  );
};
export default SearchBy;
