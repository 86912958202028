import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';

import newTab from '../../images/icons/newTab.png';
import placeHolder from '../../images/icons/placeholder.png';

const ActivitiesTab = ({ activityType, activities }) => {
  return (
    <div className={activityType === 'Images:' ? 'activitiesTabImage' : 'activitiesTabs'}>
      {activities?.map((activity) => (
        <div className="contentWrapper" key={activity.id}>
          <a
            href={
              window.location.origin +
              '/places/' +
              (activityType === 'Images:' ? activity.place : activity.place_id || activity.id)
            }
            target="_blank"
            rel="noreferrer"
            className="linkToMap pointer tab">
            <div className="tabRow tabRowImg">
              {activity?.image && (
                <img
                  className="tabImg"
                  src={activity.image.length > 0 ? activity.image : placeHolder}
                  alt="place"
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = placeHolder;
                  }}
                />
              )}
            </div>
            <div className="tabRow text">
              {activityType !== 'Images:' && (
                <>
                  <h4 className="tabRow tabRowName">
                    {activity?.name?.length > 0 ? activity.name : 'No name'} <img src={newTab} alt="" />
                  </h4>
                  <div className="tabRow tabRowDescription">
                    {activity?.description ? activity?.description : 'No description'}
                  </div>
                </>
              )}
              <div className="linkToMapContainer">
                <div>Added: {moment.utc(activity?.created_at).local().fromNow()}</div>
              </div>
            </div>
            <div className="sectionDivider" />
            <div className="sectionDividerBottomLine" />
            <div className="sectionDivider" />
          </a>
        </div>
      ))}
    </div>
  );
};

ActivitiesTab.propTypes = {
  activities: PropTypes.any,
  activityType: PropTypes.any,
};

export default ActivitiesTab;
