import React from 'react';
import Col from 'react-bootstrap/Col';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Row from 'react-bootstrap/Row';
import Tooltip from 'react-bootstrap/Tooltip';
import { useTranslation } from 'react-i18next';
import { FaDirections } from 'react-icons/fa';
import PropTypes from 'prop-types';

import KomootIcon from '../../images/icons/komoot.jpeg';

import OSMIcon from '../../images/icons/osm.png';
import Store from '../../utils/store';

const Navigation = ({ showHeader, showDivider }) => {
  const { t } = useTranslation();

  const place = Store.useState((s) => s.selectedPlace);
  let lat = parseFloat(place.location.lat).toFixed(7);
  let lng = parseFloat(place.location.lng).toFixed(7);

  return (
    <>
      <Row>
        <Col sm={12} className="navigationWrapper">
          {showHeader && <h5>{t('place.navigation')}</h5>}
          <div className="navigationWrapperIcons">
            <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-top">{t('location.gmap')}</Tooltip>}>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`https://www.google.com/maps/search/?api=1&query=${lat},${lng}`}>
                <FaDirections className="faDirections"/>
              </a>
            </OverlayTrigger>

            <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-top">{t('location.osm')}</Tooltip>}>
              <a
                className="linkOpenMap"
                target="_blank"
                rel="noopener noreferrer"
                href={`https://www.openstreetmap.org/?mlat=${lat}&mlon=${lng}#map=19/${lat}/${lng}`}>
                <img className="imgOpenMap" src={OSMIcon} />
              </a>
            </OverlayTrigger>

            <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-top">{t('location.komoot')}</Tooltip>}>
              <a
                className="linkOpenKomoot"
                target="_blank"
                rel="noopener noreferrer"
                href={`https://www.komoot.com/plan/@${lat},${lng},16.636z`}>
                <img className="imgOpenKomoot" src={KomootIcon}/>
              </a>
            </OverlayTrigger>
          </div>
        </Col>
      </Row>
      {showDivider && (
        <>
          <div className="sectionDivider" />
          <div className="sectionDividerBottomLine" />
          <div className="sectionDivider" />
        </>
      )}
    </>
  );
};
Navigation.propTypes = {
  showHeader: PropTypes.bool,
  showDivider: PropTypes.bool,
};
export default Navigation;
