import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useTranslation } from 'react-i18next';
import Linkify from 'react-linkify';
import { toast } from 'react-toastify';

import WarningIcon from '../../images/icons/warning-red.svg';
import { API } from '../../utils/api';
import Store from '../../utils/store';
import Image from '../image';
import ReplyComment from './replyComment';

const Comment = ({ comment, allComments }) => {
  const { t } = useTranslation();
  const profile = Store.useState((s) => s.profile);
  const [showTranslation, setShowTranslation] = useState(false);
  const [translation, setTranslation] = useState(null);
  const [reply, setReply] = useState(false);
  const selectedLanguage = Store.useState((s) => s.selectedLanguage);

  const componentDecorator = (href, text, key) => (
    <a href={href} key={key} target="_blank" rel="noreferrer">
      {text}
    </a>
  );

  useEffect(() => {
    setTranslation(null);
  }, [selectedLanguage]);

  const reportClicked = () => {
    Store.update((s) => {
      if (profile.username) {
        s.showReportCommentModal = true;
        s.commentId = comment.id;
      } else {
        s.showSignInModal = true;
      }
    });
  };

  const translate = () => {
    if (!profile.username) {
      Store.update((s) => {
        s.showSignInModal = true;
      });
      return;
    }
    if (translation) {
      setShowTranslation(true);
      return;
    }
    API.translateText({ text: comment.message, lang_to: selectedLanguage.code }).then((res) => {
      setTranslation(res);
      setShowTranslation(true);
    });
  };

  const deleteComment = () => {
    if (!window.confirm(t('confirmDelete'))) {
      return;
    }

    API.deleteComment(comment.place, comment.id)
      .then(() => {
        toast.success(t('toast.commentDeleted'));
        Store.update((s) => {
          s.selectedcomments = s.selectedcomments.filter((item) => item.id !== comment.id);
        });
      })
      .catch(() => {
        toast.error(t('toast.error'));
      });
  };

  const openReply = () => {
    setReply(!reply);
    if (!profile.username) {
      Store.update((s) => {
        s.showSignInModal = true;
      });
    }
  };

  return (
    <div className="commentWrapper">
      <div className="comment">
        <Row className="nameDate">
          <Col className="nameDate">
            <Image className="commentsUserAvatar" src={comment.user.avatar_url} />
            <div className="commentUserFirstName">
              {comment.user.first_name} {comment.user.last_name}
              <div className="commentMoment">{moment.utc(comment.created_at).local().fromNow()}</div>
            </div>
          </Col>
          {profile.is_admin && (
            <Button
              className="adminDeleteButton"
              size="sm"
              variant="outline-danger"
              onClick={() => {
                deleteComment(comment.id);
              }}>
              Delete
            </Button>
          )}
        </Row>
        <Row className="commentRow"></Row>
        <Row>
          <Col>
            <Linkify componentDecorator={componentDecorator}>
              <span className="sectionReviewText">
                {showTranslation && translation ? translation.text : comment.message}
              </span>
            </Linkify>
            <div className="footerComment">
              {!showTranslation ? (
                <div className="sectionTranslateTo" onClick={translate}>
                  {t('translateTo') + ` ${selectedLanguage ? selectedLanguage?.name : ''}`}
                </div>
              ) : (
                <div className="sectionTranslateShow" onClick={() => setShowTranslation(false)}>
                  {t('showOriginal')}
                </div>
              )}
              <div onClick={reportClicked}>
                <img className="warningIcon pointer" src={WarningIcon} alt="warning-icon"></img>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      {reply ? (
        <a className="cancelLink pointer" onClick={openReply}>
          {t('button.cancel')}
        </a>
      ) : (
        <a className="replyLink pointer" onClick={openReply}>
          {t('button.reply')}
        </a>
      )}

      {profile.username && reply && (
        <ReplyComment
          placeholder={t('comment.reply')}
          hideReplyForm={openReply}
          replyToCommentId={comment.id}
          classNameInput={'replyCommentInput'}
        />
      )}
      {allComments
        .filter((c) => c.reply_to === comment.id)
        .map((childComment, index) => (
          <div className="childComment" key={index}>
            <Comment comment={childComment} allComments={allComments} parent_id={comment.id} />
          </div>
        ))}
    </div>
  );
};

const Comments = () => {
  const comments = Store.useState((s) => s.selectedPlaceComments);

  return comments
    .filter((c) => c.reply_to === null)
    .map((comment, index) => {
      return <Comment key={index} comment={comment} allComments={comments.filter((c) => c.reply_to !== null)} />;
    });
};

Comment.propTypes = {
  comment: PropTypes.any,
  allComments: PropTypes.any,
};

export default Comments;
