import React, { useEffect, useState } from 'react';
import Select from 'react-dropdown-select';
import { useTranslation } from 'react-i18next';

import { API } from '../utils/api';
import Store from '../utils/store';

const moduleName = 'CountrySelector';

const CountrySelector = () => {
  const { t } = useTranslation();
  const [countries, setCountries] = useState([]);
  const [loading, setLoading] = useState(false);
  const selectedCountries = Store.useState((s) => s.selectedCountries);

  useEffect(() => {
    setLoading(true);
    // fetching available countries
    API.fetchCountries()
      .then((res) => {
        setCountries(res);
        setLoading(false);
      })
      .catch((res) => {
        setLoading(false);
        console.error(res);
      });
  }, []);

  const click = (e) => {
    Store.update((s) => {
      s.selectedCountries = e;
    });
    // saving selected countries so user can select them only one time
    console.log(`${moduleName}: saveSelectedCountries -> ${countries}`);
    localStorage.setItem('countries', JSON.stringify(e));
  };
  return (
    <div className="countrySelector">
      <Select
        options={countries}
        placeholder={t('selectedCountries')}
        loading={loading}
        searchBy="name"
        labelField="name"
        valueField="id"
        onChange={click}
        multi={true}
        className="languageSelector"
        values={selectedCountries}
        closeOnSelect={true}
      />
    </div>
  );
};
export default CountrySelector;
