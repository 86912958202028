import React from 'react';
import { Modal } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Store from '../../utils/store';
import { Tabs, Tab } from 'react-bootstrap';
import MyCloseButton from '../../components/button/closeButton';

import HutIcon from '../../images/object-types/hut.svg';
import BivouacIcon from '../../images/object-types/bivouac.svg';
import LeanToIcon from '../../images/object-types/lean-to.svg';
import RuinIcon from '../../images/object-types/ruin.svg';
import BasicShelterIcon from '../../images/object-types/shelter.svg';
import WeatherShelterIcon from '../../images/object-types/weather-shelter.svg';
import TowerIcon from '../../images/object-types/tower.svg';

import Hut1 from '../../images/examples/Hut1.jpg';
import Hut2 from '../../images/examples/Hut2.jpg';
import Hut3 from '../../images/examples/Hut3.jpg';
import Hut4 from '../../images/examples/Hut4.jpg';
import Hut5 from '../../images/examples/Hut5.jpg';
import Shelter1 from '../../images/examples/Shelter1.png';
import Shelter2 from '../../images/examples/Shelter2.jpeg';
import Shelter3 from '../../images/examples/Shelter3.png';
import WeatherShelter1 from '../../images/examples/WeatherShelter1.png';
import WeatherShelter2 from '../../images/examples/WeatherShelter2.jpg';
import LeanTo1 from '../../images/examples/Lean-To1.png';
import LeanTo2 from '../../images/examples/Lean-To2.png';
import Tower1 from '../../images/examples/Tower1.png';
import Tower2 from '../../images/examples/Tower2.jpg';
import Tower3 from '../../images/examples/Tower3.jpg';
import Tower4 from '../../images/examples/Tower4.jpg';
import Bivouac1 from '../../images/examples/Bivouac1.jpeg';
import Bivouac2 from '../../images/examples/Bivouac2.jpeg';
import Bivouac3 from '../../images/examples/Bivouac3.jpeg';
import Bivouac4 from '../../images/examples/Bivouac4.jpeg';
import Ruin1 from '../../images/examples/Ruin1.jpeg';

const ObjectTypeHelp = () => {
  const { t } = useTranslation();
  const showObjectTypeHelpModal = Store.useState((s) => s.showObjectTypeHelpModal);

  const hide = () => {
    Store.update((s) => {
      s.showObjectTypeHelpModal = false;
    });
  };

  return (
    <Modal scrollable={true} size="lg" className="objectDescription" show={showObjectTypeHelpModal} onHide={hide}>
      <Modal.Header>
        <Modal.Title>Object Types Description</Modal.Title>
        <MyCloseButton hide={hide} />
      </Modal.Header>
      <Modal.Body className="body">
        <Tabs id="controlled-tab-example" className="mb-3">
          <Tab eventKey="hut" title={<img className="tabLinkImg" src={HutIcon} alt="" />}>
            <h4>{t('place.objectTypes.hut.name')}</h4>
            Cabins and small houses with doors and windows. You can also add objects that cost money. (max. around
            10€/12$). Available with or without reservation. <br /> If the hut has to be reserved, a reservation-link or
            contact option must be indicated in the description.
            <div className="exampleImgContainer">
              <img src={Hut1} alt="hut" />
              <img src={Hut2} alt="hut" />
              <img src={Hut3} alt="hut" />
              <img src={Hut4} alt="hut" />
              <img src={Hut5} alt="hut" />
            </div>
          </Tab>

          <Tab eventKey="weatherShelter" title={<img className="tabLinkImg" src={WeatherShelterIcon} alt="" />}>
            <h4>{t('place.objectTypes.weather_shelter.name')}</h4>
            Shelters with four walls with door and window openings <br /> but no doors/windows you could close.
            <div className="exampleImgContainer">
              <img src={WeatherShelter1} alt="weatherShelter" />
              <img src={WeatherShelter2} alt="weatherShelter" />
            </div>
          </Tab>

          <Tab eventKey="leanTo" title={<img className="tabLinkImg" src={LeanToIcon} alt="" />}>
            <h4>{t('place.objectTypes.leanTo.name')}</h4>
            Shelters with typically three walls.
            <div className="exampleImgContainer">
              <img src={LeanTo1} alt="leanTo" />
              <img src={LeanTo2} alt="leanTo" />
            </div>
          </Tab>

          <Tab eventKey="basicShelter" title={<img className="tabLinkImg" src={BasicShelterIcon} alt="" />}>
            <h4>{t('place.objectTypes.basic_shelter.name')}</h4>
            Basic shelters and pavilions: objects that have fewer than three walls or are open to all sides. <br />
            Please don’t add covered picnic tables and sun shelters. These objects are only approved by us if they
            belong to a bivouac site.
            <div className="exampleImgContainer">
              <img src={Shelter1} alt="basicShelter" />
              <img src={Shelter2} alt="basicShelter" />
              <img src={Shelter3} alt="basicShelter" />
            </div>
          </Tab>
          <Tab eventKey="tower" title={<img className="tabLinkImg" src={TowerIcon} alt="" />}>
            <h4>{t('place.objectTypes.tower.name')}</h4>
            Observation towers and lookout towers that have a roof. <br /> Please add only small observation towers (e.
            g. wooden) that are suitable for an overnight stay.
            <div className="exampleImgContainer">
              <img src={Tower4} alt="tower" />
              <img src={Tower3} alt="tower" />
              <img src={Tower1} alt="tower" />
              <img src={Tower2} alt="tower" />
            </div>
          </Tab>
          <Tab eventKey="bivouac" title={<img className="tabLinkImg" src={BivouacIcon} alt="" />}>
            <h4>{t('place.objectTypes.bivouac.name')}</h4>
            Campsites that not affiliated with commercial companys or institutions, <br />
            regardless of amenities or formal permissions. <br /> Camping must be officially allowed.
            <ul>
              Do not add if:
              <li>locals do not allow camping</li>
              <li>camping damages the environment</li>
              <li>&lsquo;no camping&lsquo; signs are attached </li>
              <li>if you just think it would be a nice spot</li>
            </ul>
            Bivouacs seldom have more amenities than simple toilet and are often (but not always) free of charge. You
            can also add objects that are subject of a charge. (max. around 10€/12$)
            <div className="exampleImgContainer">
              <img src={Bivouac3} alt="bivouac" />
              <img src={Bivouac4} alt="bivouac" />
              <img src={Bivouac1} alt="bivouac" />
              <img src={Bivouac2} alt="bivouac" />
            </div>
          </Tab>
          <Tab eventKey="ruin" title={<img className="tabLinkImg" src={RuinIcon} alt="" />}>
            <h4>{t('place.objectTypes.ruin.name')}</h4>
            <div className="exampleImgContainer">
              <img src={Ruin1} alt="ruin" />
            </div>
          </Tab>
        </Tabs>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={hide}>
          {t('button.close')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ObjectTypeHelp;
