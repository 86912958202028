import React, { useEffect } from 'react';
import { Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { BiExit } from 'react-icons/bi';
import { FaCheck, FaEnvelope, FaFacebook, FaHome, FaList, FaUsers } from 'react-icons/fa';
import { toast } from 'react-toastify';

import Activities from '../images/icons/activities.png';
import Leaderboard from '../images/icons/leaderboard.png';
import LogoIcon from '../images/icons/logo.png';
import News from '../images/icons/news.png';
import PayPalIcon from '../images/icons/paypal.png';
import ProfileIcon from '../images/icons/profile.png';
import signoutIcon from '../images/icons/signout.png';
import { API } from '../utils/api';
import Store from '../utils/store';
import Avatar from './avatar';
import LangSelector from './langSelector';
import SearchBy from './searchBy';

const Header = () => {
  const { t } = useTranslation();

  const profile = Store.useState((s) => s.profile);
  const placesToVerify = Store.useState((s) => s.placesToVerify);

  useEffect(() => {
    API.fetchProfile()
      .then((res) => {
        if (res.length > 0) {
          res = res[0];
          Store.update((s) => {
            s.profile = res.username ? res : {};
            s.placesToVerify = res.places_to_verify;
            s.selectedLanguage = res.language;
          });
        }
      })
      .catch((res) => {
        console.error(res);
      });
  }, []);

  const userAvatar = <Avatar avatar={profile?.avatar_url} places_to_verify={placesToVerify} />;

  const logout = () => {
    API.logout().then(() => {
      Store.update((s) => {
        s.profile = {};
      });
      toast.info('Signed out');
    });
  };

  return (
    <header>
      <Navbar>
        <Navbar.Brand
          className="brand"
          onClick={(e) => {
            window.location = 'https://campwild.org';
            e.preventDefault();
          }}>
          <img src={LogoIcon} className="d-inline-block align-top logo" alt="logo" />
          <div className="brand-text">CampWild</div>
        </Navbar.Brand>
        <SearchBy />
        <Button
          className="whatsNewButton"
          variant="outline-success"
          onClick={() => {
            Store.update((s) => {
              s.showWhatsNewModal = true;
            });
          }}>
          What&apos;s New
        </Button>
        <LangSelector />
        <Nav>
          <NavDropdown className="navDropDown" title={userAvatar} align="end">
            {profile.email && (
              <div>
                <NavDropdown.Header>
                  <div className="profileEmail">{profile.email}</div>
                </NavDropdown.Header>
                <NavDropdown.Divider />
                <NavDropdown.Item
                  onClick={() =>
                    Store.update((s) => {
                      s.showProfileModal = true;
                    })
                  }>
                  <img src={ProfileIcon} /> {t('header.menu.profile')}
                </NavDropdown.Item>
                {profile.is_admin && (
                  <NavDropdown.Item
                    onClick={() => {
                      Store.update((s) => {
                        s.places = [];
                        s.showPlacesToVerify = true;
                      });
                      API.fetchPlaces({ not_verified: true }).then((res) => {
                        Store.update((s) => {
                          s.places = res;
                        });
                      });
                    }}>
                    <FaCheck />
                    {t('header.menu.placesToVerify')} ({placesToVerify})
                  </NavDropdown.Item>
                )}

                <NavDropdown.Item
                  className="whatsNewNav"
                  onClick={() => {
                    Store.update((s) => {
                      s.showWhatsNewModal = true;
                    });
                  }}>
                  <img src={News} />
                  {t('whatsNew.title')}
                </NavDropdown.Item>
                <NavDropdown.Item
                  className="classname"
                  onClick={() => {
                    Store.update((s) => {
                      s.showActivitiesModal = true;
                    });
                  }}>
                  <img src={Activities} />
                  {t('header.menu.activities')}
                </NavDropdown.Item>
                <NavDropdown.Item
                  className="classname"
                  onClick={() => {
                    Store.update((s) => {
                      s.showLeaderboardModal = true;
                    });
                  }}>
                  <img src={Leaderboard} />
                  {t('header.menu.leaderboard')}
                </NavDropdown.Item>
                <NavDropdown.Item
                  onClick={(e) => {
                    window.location = 'https://campwild.org';
                    e.preventDefault();
                  }}>
                  <FaHome />
                  {t('header.menu.goHome')}
                </NavDropdown.Item>

                <NavDropdown.Item
                  onClick={() => {
                    Store.update((s) => {
                      s.showFeedbackModal = true;
                    });
                  }}>
                  <FaEnvelope />
                  {t('header.menu.contactUs')}
                </NavDropdown.Item>
                <NavDropdown.Item
                  className="whatsNewNav"
                  onClick={() => {
                    Store.update((s) => {
                      s.showWhatsNewModal = true;
                    });
                  }}>
                  <img src={News} />
                  {t('whatsNew.title')}
                </NavDropdown.Item>

                <NavDropdown.Item
                  onClick={() =>
                    Store.update((s) => {
                      s.showTeamModal = true;
                    })
                  }>
                  <FaUsers /> {t('header.menu.team')}
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item
                  onClick={(e) => {
                    window.open('https://www.facebook.com/groups/shelterandbivouac', '_blank');
                    e.preventDefault();
                  }}>
                  <FaFacebook />
                  Camp Wild
                </NavDropdown.Item>
                <NavDropdown.Item
                  onClick={(e) => {
                    window.open('https://www.facebook.com/groups/vindskyddinorden', '_blank');
                    e.preventDefault();
                  }}>
                  <FaFacebook />
                  Vindskydd i Norden
                </NavDropdown.Item>
                <NavDropdown.Item
                  onClick={() =>
                    Store.update((s) => {
                      s.showOtherProjectsModal = true;
                    })
                  }>
                  <FaList />
                  Other Projects
                </NavDropdown.Item>

                <NavDropdown.Divider />
                <NavDropdown.Item onClick={logout}>
                  <img src={signoutIcon} /> {t('header.menu.signOut')}
                </NavDropdown.Item>
              </div>
            )}
            {!profile.email && (
              <>
                <NavDropdown.Item
                  onClick={(e) => {
                    window.location = 'mailto:support@campwild.org';
                    e.preventDefault();
                  }}>
                  <FaEnvelope />
                  {t('header.menu.contactUs')}
                </NavDropdown.Item>
                <NavDropdown.Item
                  onClick={(e) => {
                    window.location = 'https://paypal.me/pools/c/8zUa0PHxXw';
                    e.preventDefault();
                  }}>
                  <img src={PayPalIcon} />
                  {t('header.menu.donate')}
                </NavDropdown.Item>

                <NavDropdown.Item
                  onClick={(e) => {
                    window.location = 'https://campwild.org';
                    e.preventDefault();
                  }}>
                  <FaHome />
                  {t('header.menu.goHome')}
                </NavDropdown.Item>
                <NavDropdown.Item
                  className="classname"
                  onClick={() => {
                    Store.update((s) => {
                      s.showLeaderboardModal = true;
                    });
                  }}>
                  <img src={Leaderboard} />
                  {t('header.menu.leaderboard')}
                </NavDropdown.Item>
                <NavDropdown.Item
                  onClick={() =>
                    Store.update((s) => {
                      s.showTeamModal = true;
                    })
                  }>
                  <FaUsers /> {t('header.menu.team')}
                </NavDropdown.Item>

                <NavDropdown.Divider />
                <NavDropdown.Item
                  onClick={(e) => {
                    window.open('https://www.facebook.com/groups/shelterandbivouac', '_blank');
                    e.preventDefault();
                  }}>
                  <FaFacebook />
                  Camp Wild
                </NavDropdown.Item>
                <NavDropdown.Item
                  onClick={(e) => {
                    window.open('https://www.facebook.com/groups/vindskyddinorden', '_blank');
                    e.preventDefault();
                  }}>
                  <FaFacebook />
                  Vindskydd i Norden
                </NavDropdown.Item>

                <NavDropdown.Item
                  onClick={() =>
                    Store.update((s) => {
                      s.showOtherProjectsModal = true;
                    })
                  }>
                  <FaList />
                  Other Projects
                </NavDropdown.Item>

                <NavDropdown.Divider />
                <NavDropdown.Item
                  onClick={() => {
                    Store.update((s) => {
                      s.showSignInModal = true;
                    });
                  }}>
                  <BiExit size="sm" /> {t('header.menu.signIn')}
                </NavDropdown.Item>
              </>
            )}
          </NavDropdown>
        </Nav>
      </Navbar>
    </header>
  );
};

export default Header;
