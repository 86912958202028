import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import MyCloseButton from '../../components/button/closeButton';
import { API } from '../../utils/api';
import Store from '../../utils/store';

const ReportComment = () => {
  const { t } = useTranslation();
  const place = Store.useState((s) => s.selectedPlace);
  const commentId = Store.useState((s) => s.commentId);

  const showReportCommentModal = Store.useState((s) => s.showReportCommentModal);

  const [placeholder, setPlaceholder] = useState('');
  const [disabled, setDisabled] = useState(false);
  const [reason, setReason] = useState('');
  const [text, setText] = useState('');

  const hide = () => {
    Store.update((s) => {
      s.showReportCommentModal = false;
      s.commentId = null;
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDisabled(true);
    API.reportComment(place.id, commentId, { comment: commentId, reason: reason, text: text })
      .then(() => {
        setDisabled(false);
        toast.success(t('toast.commentReported'));
        hide();
      })
      .catch(() => {
        setDisabled(false);
        toast.error(t('toast.error'));
      });
  };

  const onRadioClicked = (e) => {
    if (e.target.id === 'defamation') {
      setPlaceholder(t('reportComment.defamationLink'));
    } else if (e.target.id === 'other') {
      setPlaceholder(t('reportPlace.otherText'));
    } else if (e.target.id !== 'other' && e.target.id !== 'defamation') {
      setPlaceholder('');
    }
    setReason(e.target.id);
  };

  return (
    <Modal show={showReportCommentModal} onHide={hide} size="lg" className="activitiesModal">
      <Modal.Header className="modalHeaderCloseButton">
        <Modal.Title>{t('reportComment.header')}</Modal.Title>
        <MyCloseButton hide={hide} />
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Check
            type="radio"
            label={t('reportComment.suspicious')}
            name="reasons"
            id="suspicious"
            disabled={disabled}
            onClick={onRadioClicked}
            className="formCheckReport"
          />
          <Form.Check
            type="radio"
            label={t('reportComment.hate')}
            name="reasons"
            id="hate"
            disabled={disabled}
            onClick={onRadioClicked}
            className="formCheckReport"
          />
          <Form.Check
            type="radio"
            label={t('reportComment.harm')}
            name="reasons"
            id="harm"
            onClick={onRadioClicked}
            disabled={disabled}
            className="formCheckReport"
          />
          <Form.Check
            type="radio"
            label={t('reportComment.adult')}
            name="reasons"
            id="adult"
            onClick={onRadioClicked}
            disabled={disabled}
            className="formCheckReport"
          />
          <Form.Check
            type="radio"
            label={t('reportComment.defamation')}
            name="reasons"
            id="defamation"
            onClick={onRadioClicked}
            disabled={disabled}
            className="formCheckReport"
          />
          <Form.Check
            type="radio"
            label={t('reportComment.other')}
            name="reasons"
            id="other"
            onClick={onRadioClicked}
            disabled={disabled}
            className="formCheckReport"
          />
          <Form.Control
            className="reportCommentFormControl"
            as="textarea"
            placeholder={placeholder}
            disabled={disabled}
            value={text}
            onChange={(e) => setText(e.target.value)}
          />
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={onSubmit} disabled={disabled | !reason}>
          {t('button.send')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ReportComment;
