import React from 'react';

import Store from '../utils/store';
import Icon from '../images/icons/filter.svg';

const FilterIcon = () => {
  const showFilterModal = () => {
    Store.update((s) => {
      s.showFilterModal = true;
    });
  };
  return (
    <div className="filterIcon" onClick={showFilterModal}>
      <img alt="filter" src={Icon} />
    </div>
  );
};

export default FilterIcon;
