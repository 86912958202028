import React, { Suspense } from 'react';
import { Route, Router, Switch } from 'react-router-dom';

import PrivacyText from './assets/components/privacyText';
import TermsText from './assets/components/termsText';
import Version from './assets/components/version';
import Main from './assets/pages/main';
import NotFound from './assets/pages/notFound';
import Signin from './assets/pages/signin';
import { initializeReactGA } from './assets/utils/analytics';
import history from './assets/utils/history';

// import i18n (needs to be bundled ;))
import './assets/localization/i18n';
import './wdyr';

initializeReactGA();

function App() {
  return (
    <Router history={history}>
      <div className="page">
        <main className="content">
          <Switch>
            <Route exact path="/terms" component={TermsText} />
            <Route exact path="/privacy" component={PrivacyText} />
            <Route exact path="/version" component={Version} />
            <Route exact path="/signin" component={Signin} />
            <Route exact path="/places/:placeId" component={Main} />
            <Route path="/" component={Main} />
            <Route component={NotFound} />
          </Switch>
        </main>
      </div>
    </Router>
  );
}

// here app catches the suspense from page in case translations are not yet loaded
export default function WrappedApp() {
  return (
    <Suspense fallback="loading...">
      <App />
    </Suspense>
  );
}
