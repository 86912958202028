import React, { createRef, useEffect, useRef } from 'react';
import Store from '../../utils/store';
import Marker from './marker';
import MarkerClusterGroup from '@changey/react-leaflet-markercluster'; // add import MarkerClusterGroup

const Places = () => {
  const profile = Store.useState((s) => s.profile);
  const places = Store.useState((s) => s.places);
  const selectedPlaceId = Store.useState((s) => s.selectedPlaceId);
  const selectedMarker = useRef(null);
  const showPlacesToVerify = Store.useState((s) => s.showPlacesToVerify);
  const markerRefs = useRef({});

  useEffect(() => {
    const refMarkers = {};
    places.forEach((place) => (refMarkers[place.id] = createRef()));
    markerRefs.current = refMarkers; // refresh marker's link
  }, [places]);

  useEffect(() => {
    if (selectedPlaceId === null && selectedMarker.current) {
      markerRefs.current[selectedMarker.current].current.reset();
    }
  }, [selectedPlaceId]);

  const resetPrevious = (currentSelected) => {
    if (selectedMarker.current && markerRefs.current[selectedMarker.current].current && currentSelected !== selectedMarker.current) {
      // reset previous marker color
      markerRefs.current[selectedMarker.current].current.reset();
    }
    selectedMarker.current = currentSelected;
  };

  return (
    <MarkerClusterGroup showCoverageOnHover={false} maxClusterRadius={showPlacesToVerify ? 0 : 40}>
      {places.map((place) => {
        return (
          <Marker
            key={place.id}
            place={place}
            draggable={profile.is_admin}
            selected={place.id === selectedPlaceId}
            resetPrevious={resetPrevious}
            ref={markerRefs.current[place.id]}
          />
        );
      })}
    </MarkerClusterGroup>
  );
};

export default Places;
