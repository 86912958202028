import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const TermsText = () => {
  return (
    <Container>
      <Row>
        <Col>
          <h3>Terms of Service</h3>
          <p>
            These terms and conditions are effective immediately for those registering accounts after that date and will
            become effective March 1, 2021 for those with pre-existing accounts. These terms and conditions
            (the &quot;Terms&quot;) govern your access to and use of CampWild&apos;s website that link to or reference
            these Terms (&quot;Site&quot;). By accessing or using the Site, you are agreeing to these Terms and
            concluding a legally binding contract with CampWild. Do not access or use the Site if you are unwilling or
            unable to be bound by the Terms.
          </p>
          <ol>
            <li>
              <h5>DEFINITIONS</h5>
            </li>
            <ol>
              <li>
                <h6>Parties</h6>
                <p>
                  &quot;You&quot; and &quot;your&quot; refer to you, as a user of the Site. A &quot;user&quot; is
                  someone who accesses, browses, crawls, scrapes, or in any way uses the Site. 
                  &quot;We,&quot; &quot;us,&quot; and &quot;our&quot; refer to CampWild.
                </p>
              </li>

              <li>
                <h6>Content</h6>
                <p>
                  &quot;Content&quot; means text, images, photos, location data, and all other forms of data or
                  communication. &quot;Your Content&quot; means Content that you submit or transmit to, through, or in
                  connection with the Site, such as ratings, reviews, and information that you publicly display or
                  displayed in your account profile.  &quot;User Content&quot; means Content that users submit or
                  transmit to, through, or in connection with the Site.  &quot;CampWild Content&quot; means Content that
                  we create and make available in connection with the Site.  &quot;Third Party Content&quot; means
                  Content that originates from parties other than CampWild or its users, which is made available in
                  connection with the Site. &quot;Site Content&quot; means all of the Content that is made available in
                  connection with the Site, including Your Content, User Content, Third Party Content, and CampWild
                  Content.
                </p>
              </li>
            </ol>
            <li>
              <h5>CHANGES TO THE TERMS OF SERVICE</h5>
              <p>
                We may modify the Terms from time to time. The most current version of these Terms will be located here.
                You understand and agree that your access to or use of the Site is governed by the Terms effective at
                the time of your access to or use of the Site. If we make material changes to these Terms, we will
                notify you by email or by posting a notice on the Site prior to the effective date of the changes. We
                will also indicate at the top of this page the date that revisions were last made. You should revisit
                these Terms on a regular basis as revised versions will be binding on you. Any such modification will be
                effective upon our posting of new Terms. You understand and agree that your continued access to or use
                of the Site after the effective date of modifications to the Terms indicates your acceptance of the
                modifications.
              </p>
            </li>
            <li>
              <h5>TRANSLATION</h5>
              <p>
                We may translate these Terms into other languages for your convenience. Nevertheless, the English
                version governs your relationship with CampWild, and any inconsistencies among the different versions
                will be resolved in favor of the English version.
              </p>
            </li>
            <li>
              <h5>USING THE SITE</h5>
              <ol>
                <li>
                  <h6>Eligibility</h6>
                  <p>
                    To access or use the Site, you must be 18 years or older and have the requisite power and authority
                    to enter into these Terms. You may not access or use the Site if you are a competitor of ours or if
                    we have previously banned you from the Site or closed your account.
                  </p>
                </li>
                <li>
                  <h6>Permission to Use the Site</h6>
                  <p>
                    We grant you permission to use the Site subject to the restrictions in these Terms. Your use of the
                    Site is at your own risk, including the risk that you might be exposed to Content that is offensive,
                    indecent, inaccurate, objectionable, or otherwise inappropriate.
                  </p>
                </li>
                <li>
                  <h6>Site Availability</h6>
                  <p>
                    The Site may be modified, updated, interrupted, suspended or discontinued at any time without notice
                    or liability.
                  </p>
                </li>
                <li>
                  <h6>User Accounts</h6>
                  <p>
                    You must create an account and provide certain information about yourself in order to use some of
                    the features that are offered through the Site. You are responsible for maintaining the
                    confidentiality of your account password. You are also responsible for all activities that occur in
                    connection with your account. You agree to notify us immediately of any unauthorized use of your
                    account. We reserve the right to close your account at any time for any or no reason. Your account
                    is for your personal, non-commercial use only. In creating it, we ask that you provide complete and
                    accurate information about yourself to bolster your credibility as a contributor to the Site. You
                    may not impersonate someone else (e.g., adopt the identity of a celebrity or your next-door
                    neighbor), create or use an account for anyone other than yourself, provide an email address other
                    than your own, or create multiple accounts. If you use a pseudonym, take care to note that others
                    may still be able to identify you if, for example, you include identifying information in your
                    reviews, use the same account information on other sites, or allow other sites to share information
                    about you with CampWild. Please read our Privacy Policy for more information.
                  </p>
                </li>
                <li>
                  <h6>Communications from CampWild and other Users</h6>
                  <p>
                    By creating an account, you agree to receive certain communications in connection with the Site. For
                    example, you might receive our e-mail newsletter about new features or improvements. You can opt-out
                    of non-essential communications here.
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <h5>CONTENT</h5>
              <ol>
                <li>
                  <h6>Responsibility for Your Content</h6>
                  <p>
                    You alone are responsible for Your Content, and once published, it cannot always be withdrawn. You
                    assume all risks associated with Your Content, including anyone&apos;s reliance on its quality,
                    accuracy, or reliability, or any disclosure by you of information in Your Content that makes you
                    personally identifiable. You represent that you own, or have the necessary permissions to use and
                    authorize the use of Your Content as described herein. You may not imply that Your Content is in any
                    way sponsored or endorsed by CampWild. You may expose yourself to liability if, for example, Your
                    Content contains material that is false, intentionally misleading, or defamatory; violates any
                    third-party right, including any copyright, trademark, patent, trade secret, moral right, privacy
                    right, right of publicity, or any other intellectual property or proprietary right; contains
                    material that is unlawful, including illegal hate speech or pornography; exploits or otherwise harms
                    minors; or violates or advocates the violation of any law or regulation.
                  </p>
                </li>
                <li>
                  <h6>Our Right to Use Your Content</h6>
                  <p>
                    We may use Your Content in a number of different ways, including publicly displaying it,
                    reformatting it, incorporating it into advertisements and other works, creating derivative works
                    from it, promoting it, distributing it, and allowing others to do the same in connection with their
                    own websites and media platforms (&quot;Other Media&quot;). As such, you hereby irrevocably grant us
                    world-wide, perpetual, non-exclusive, royalty-free, assignable, sublicensable, transferable rights
                    to use Your Content for any purpose . Please note that you also irrevocably grant the users of the
                    Site and any Other Media the right to access Your Content in connection with their use of the Site
                    and any Other Media. Finally, you irrevocably waive, and cause to be waived, against CampWild and
                    its users any claims and assertions of moral rights or attribution with respect to Your Content. By
                    &quot;use&quot; we mean use, copy, publicly perform and display, reproduce, distribute, modify,
                    translate, remove, analyze, commercialize, and prepare derivative works of Your Content.
                  </p>
                </li>
                <li>
                  <h6>Ownership</h6>
                  As between you and CampWild, you own Your Content. We own the CampWild Content, including but not
                  limited to visual interfaces, interactive features, graphics, design, compilation, including, but not
                  limited to, our compilation of User Content and other Site Content, computer code, products, software,
                  aggregate user review ratings, and all other elements and components of the Site excluding Your
                  Content, User Content and Third Party Content. We also own the copyrights, trademarks, service marks,
                  trade names, and other intellectual and proprietary rights throughout the world (&quot;IP
                  Rights&quot;) associated with the CampWild Content and the Site, which are protected by copyright,
                  trade dress, patent, trademark laws and all other applicable intellectual and proprietary rights and
                  laws. As such, you may not modify, reproduce, distribute, create derivative works or adaptations of,
                  publicly display or in any way exploit any of the CampWild Content in whole or in part except as
                  expressly authorized by us. Except as expressly and unambiguously provided herein, we do not grant you
                  any express or implied rights, and all rights in and to the Site and the CampWild Content are retained
                  by us.
                </li>
                <li>
                  <h6>Advertising</h6>
                  <p>
                    CampWild and its licensees may publicly display advertisements and other information adjacent to or
                    included with Your Content. You are not entitled to any compensation for such advertisements. The
                    manner, mode and extent of such advertising are subject to change without specific notice to you.
                  </p>
                </li>
                <li>
                  <h6>Other</h6>
                  <p>
                    User Content (including any that may have been created by users employed or contracted by CampWild)
                    does not necessarily reflect the opinion of CampWild. We reserve the right to remove, screen, edit,
                    or reinstate User Content from time to time at our sole discretion for any reason or no reason, and
                    without notice to you. For example, we may remove a review if we believe it violates our Content
                    Guidelines. We have no obligation to retain or provide you with copies of Your Content, nor do we
                    guarantee any confidentiality with respect to Your Content.
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <h5>RESTRICTIONS</h5>
              <p>
                We are under no obligation to enforce the Terms on your behalf against another user. While we encourage
                you to let us know if you believe another user has violated the Terms, we reserve the right to
                investigate and take appropriate action at our sole discretion.
              </p>
              <ol>
                <li>
                  <h6>You agree not to, and will not assist, encourage, or enable others to use the Site to:</h6>
                </li>
                <ol>
                  <p>
                    <li>
                      Violate our Content Guidelines, for example, by writing a fake or defamatory review, trading
                      reviews with other businesses, or compensating someone or being compensated to write or remove a
                      review;
                    </li>
                    <li>
                      Violate any third party&apos;s rights, including any breach of confidence, copyright, trademark,
                      patent, trade secret, moral right, privacy right, right of publicity, or any other intellectual
                      property or proprietary right;
                    </li>
                    <li>
                      Violate any third party&apos;s rights, including any breach of confidence, copyright, trademark,
                      patent, trade secret, moral right, privacy right, right of publicity, or any other intellectual
                      property or proprietary right;
                    </li>
                    <li>Threaten, stalk, harm, or harass others, or promote bigotry or discrimination;</li>
                    <li>
                      Promote a business or other commercial venture or event, or otherwise use the Site for commercial
                      purposes;
                    </li>
                    <li>
                      Send bulk emails, surveys, or other mass messaging, whether commercial in nature or not; engage in
                      keyword spamming;
                    </li>
                    <li>Solicit personal information from minors, or submit or transmit pornography;</li>
                    <li>Violate any applicable law.</li>
                  </p>
                </ol>
                <li>
                  <h6>You also agree not to, and will not assist, encourage, or enable others to:</h6>
                  <ol>
                    <p>
                      <li>Violate the Terms;</li>
                      <li>
                        Modify, adapt, appropriate, reproduce, distribute, translate, create derivative works or
                        adaptations of, publicly display, sell, trade, or in any way exploit the Site or Site Content
                        (other than Your Content), except as expressly authorized by CampWild;
                      </li>
                      <li>
                        Use any robot, spider, site search/retrieval application, or other automated device, process or
                        means to access, retrieve, scrape, or index any portion of the Site or any Site Content;
                      </li>
                      <li>Reverse engineer any portion of the Site;</li>
                      <li>
                        Remove or modify any copyright, trademark or other proprietary rights notice that appears on any
                        portion of the Site or on any materials printed or copied from the Site;
                      </li>
                      <li>Record, process, or mine information about other users;</li>
                      <li>
                        Access, retrieve or index any portion of the Site for purposes of constructing or populating a
                        searchable database of business reviews;
                      </li>
                      <li>Reformat or frame any portion of the Site;</li>
                      <li>
                        Take any action that imposes, or may impose, in our sole discretion, an unreasonable or
                        disproportionately large load on CampWild&apos;s technology infrastructure or otherwise make
                        excessive traffic demands of the Site;
                      </li>
                      <li>
                        Attempt to gain unauthorized access to the Site, user accounts, computer systems or networks
                        connected to the Site through hacking, password mining or any other means;
                      </li>
                      <li>
                        Use the Site or any Site Content to transmit any computer viruses, worms, defects, Trojan horses
                        or other items of a destructive nature (collectively, &quot;Viruses&quot;);
                      </li>
                      <li>
                        Use any device, software or routine that interferes with the proper working of the Site, or
                        otherwise attempt to interfere with the proper working of the Site;
                      </li>
                      <li>
                        Use the Site to violate the security of any computer network, crack passwords or security
                        encryption codes; disrupt or interfere with the security of, or otherwise cause harm to, the
                        Site or Site Content; or
                      </li>
                      <li>
                        Remove, circumvent, disable, damage or otherwise interfere with any security-related features of
                        the Site, features that prevent or restrict the use or copying of Site Content, or features that
                        enforce limitations on the use of the Site The restrictions above only apply to the extent
                        permissible under applicable law. Nevertheless, you agree not to act contrary to them (even if
                        permissible under applicable law) without providing 30 days&apos; prior written notice to
                        us here, together with any information that we may reasonably require to give us an opportunity
                        to provide alternative remedies or otherwise accommodate you at our sole discretion.
                      </li>
                    </p>
                  </ol>
                </li>
              </ol>
            </li>
            <li>
              <h5>GUIDELINES AND POLICIES</h5>
              <ol>
                <li>
                  <h6>Content Guidelines</h6>
                  <p>You represent that you have read and understood our Content Guidelines.</p>
                </li>
                <li>
                  <h6>Privacy</h6>
                  <p>
                    You represent that you have read and understood our Privacy Policy. Note that we may disclose
                    information about you to third parties if we have a good faith belief that such a disclosure is
                    reasonably necessary to (i) take action regarding suspected illegal activities; (ii) enforce or
                    apply our Terms and Privacy Policy; (iii) comply with legal process or other government inquiry,
                    such as a search warrant, subpoena, statute, judicial proceeding, or other legal process served on
                    us; or (iv) protect our rights, reputation, and property, or that of our users, affiliates, or the
                    public. If you use the Site outside of the Sweden, you consent to having your personal data
                    transferred to and processed in Sweden.
                  </p>
                </li>
              </ol>
              <li>
                <h5>SUGGESTIONS AND IMPROVEMENTS</h5>
                <p>
                  By sending us any ideas, suggestions, documents or proposals (&quot;Feedback&quot;), you agree that
                  (i) your Feedback does not contain the confidential or proprietary information of third parties, (ii)
                  we are under no obligation of confidentiality, express or implied, with respect to the Feedback, (iii)
                  we may have something similar to the Feedback already under consideration or in development, and (iv)
                  you grant us an irrevocable, non-exclusive, royalty-free, perpetual, worldwide license to use, modify,
                  prepare derivative works, publish, distribute and sublicense the Feedback, and you irrevocably waive,
                  and cause to be waived, against CampWild and its users any claims and assertions of any moral rights
                  contained in such Feedback.
                </p>
                <li>
                  <h5>THIRD PARTIES</h5>
                  <p>
                    The Site may include links to other websites or applications (each, a  &quot;Third Party
                    Site&quot;). We do not control or endorse any Third Party Site. You agree that we are not
                    responsible for the availability or contents of such Third Party Sites. Your use of Third Party
                    Sites is at your own risk. Some of the services made available through the Site may be subject to
                    additional third party or open source licensing terms and disclosures, including the ones
                    posted here and incorporated herein by reference.
                  </p>
                </li>
                <li>
                  <h5>INDEMNITY</h5>
                  <p>
                    You agree to indemnify, defend, and hold CampWild, its parents, subsidiaries, affiliates, any
                    related companies, suppliers, licensors and partners, and the officers, directors, employees, agents
                    and representatives of each of them (collectively, the &quot;CampWild Entities&quot;) harmless,
                    including costs, liabilities and legal fees, from any claim or demand made by any third party
                    arising out of or relating to (i) your access to or use of the Site, (ii) your violation of the
                    Terms, (iii) any products or services purchased or obtained by you in connection with the Site, or
                    (iv) the infringement by you, or any third party using your account, of any intellectual property or
                    other right of any person or entity. CampWild reserves the right, at your expense, to assume the
                    exclusive defense and control of any matter for which you are required to indemnify us and you agree
                    to cooperate with our defense of these claims. You agree not to settle any such matter without the
                    prior written consent of CampWild. CampWild will use reasonable efforts to notify you of any such
                    claim, action or proceeding upon becoming aware of it.
                  </p>
                </li>
                <li>
                  <h5>DISCLAIMERS AND LIMITATIONS OF LIABILITY</h5>
                  <p>
                    PLEASE READ THIS SECTION CAREFULLY SINCE IT LIMITS THE LIABILITY OF THE CampWild ENTITIES TO YOU.
                    EACH OF THE SUBSECTIONS BELOW ONLY APPLIES UP TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW.
                    NOTHING HEREIN IS INTENDED TO LIMIT ANY RIGHTS YOU MAY HAVE WHICH MAY NOT BE LAWFULLY LIMITED. IF
                    YOU ARE UNSURE ABOUT THIS OR ANY OTHER SECTION OF THESE TERMS, PLEASE CONSULT WITH A LEGAL
                    PROFESSIONAL PRIOR TO ACCESSING OR USING THE SITE. BY ACCESSING OR USING THE SITE, YOU REPRESENT
                    THAT YOU HAVE READ, UNDERSTOOD, AND AGREE TO THESE TERMS, INCLUDING THIS SECTION. YOU ARE GIVING UP
                    SUBSTANTIAL LEGAL RIGHTS BY AGREEING TO THESE TERMS.
                  </p>
                  <ol>
                    <p>
                      <li>
                        THE SITE IS MADE AVAILABLE TO YOU ON AN &quot;AS IS&quot;, &quot;WITH ALL FAULTS&quot; AND
                        &quot;AS AVAILABLE&quot; BASIS, WITH THE EXPRESS UNDERSTANDING THAT THE CampWild ENTITIES MAY
                        NOT MONITOR, CONTROL, OR VET USER CONTENT. AS SUCH, YOUR USE OF THE SITE IS AT YOUR OWN
                        DISCRETION AND RISK. THE CampWild ENTITIES MAKE NO CLAIMS OR PROMISES ABOUT THE QUALITY,
                        ACCURACY, OR RELIABILITY OF THE SITE, ITS SAFETY OR SECURITY, OR THE SITE CONTENT. ACCORDINGLY,
                        THE CampWild ENTITIES ARE NOT LIABLE TO YOU FOR ANY LOSS OR DAMAGE THAT MIGHT ARISE, FOR
                        EXAMPLE, FROM THE SITE&apos;S INOPERABILITY, UNAVAILABILITY OR SECURITY VULNERABILITIES OR FROM
                        YOUR RELIANCE ON THE QUALITY, ACCURACY, OR RELIABILITY OF THE BUSINESS LISTINGS, RATINGS,
                        REVIEWS (INCLUDING THEIR CONTENT, ORDER, AND DISPLAY), OR METRICS FOUND ON, USED ON, OR MADE
                        AVAILABLE THROUGH THE SITE.
                      </li>
                      <li>
                        THE CampWild ENTITIES MAKE NO CLAIMS OR PROMISES WITH RESPECT TO ANY THIRD PARTY, SUCH AS THE
                        BUSINESSES OR ADVERTISERS LISTED ON THE SITE OR THE SITE&apos;S USERS. ACCORDINGLY, THE CampWild
                        ENTITIES ARE NOT LIABLE TO YOU FOR ANY LOSS OR DAMAGE THAT MIGHT ARISE FROM THEIR ACTIONS OR
                        OMISSIONS, INCLUDING, FOR EXAMPLE, IF ANOTHER USER OR BUSINESS MISUSES YOUR CONTENT, IDENTITY OR
                        PERSONAL INFORMATION, OR IF YOU HAVE A NEGATIVE EXPERIENCE WITH ONE OF THE BUSINESSES OR
                        ADVERTISERS LISTED OR FEATURED ON THE SITE. YOUR PURCHASE AND USE OF PRODUCTS OR SERVICES
                        OFFERED BY THIRD PARTIES THROUGH THE SITE IS AT YOUR OWN DISCRETION AND RISK.
                      </li>
                      <li>
                        THE CampWild ENTITIES EXPRESSLY DISCLAIM ALL WARRANTIES, WHETHER EXPRESS OR IMPLIED, INCLUDING
                        WARRANTIES AS TO THE PRODUCTS OR SERVICES OFFERED BY BUSINESSES LISTED ON THE SITE, AND IMPLIED
                        WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. NO ORAL
                        OR WRITTEN INFORMATION OR ADVICE PROVIDED TO YOU BY A REPRESENTATIVE OF ONE OF THE CampWild
                        ENTITIES SHALL CREATE A REPRESENTATION OR WARRANTY.
                      </li>
                      <li>
                        YOUR SOLE AND EXCLUSIVE RIGHT AND REMEDY IN CASE OF DISSATISFACTION WITH THE SITE, RELATED
                        SERVICES, OR ANY OTHER GRIEVANCE SHALL BE YOUR TERMINATION AND DISCONTINUATION OF ACCESS TO, OR
                        USE OF THE SITE.
                      </li>
                      <li>
                        THE CampWild ENTITIES&apos; MAXIMUM AGGREGATE LIABILITY TO YOU FOR LOSSES OR DAMAGES THAT YOU
                        SUFFER IN CONNECTION WITH THE SITE OR THESE TERMS IS LIMITED TO THE GREATER OF (i) THE AMOUNT
                        PAID, IF ANY, BY YOU TO THE CampWild ENTITIES IN CONNECTION WITH THE SITE IN THE 12 MONTHS PRIOR
                        TO THE ACTION GIVING RISE TO LIABILITY, OR (ii) $100.
                      </li>
                      <li>
                        THE CampWild ENTITIES DISCLAIM LIABILITY FOR ANY (i) INDIRECT, SPECIAL, INCIDENTAL, PUNITIVE,
                        EXEMPLARY, RELIANCE, OR CONSEQUENTIAL DAMAGES, (ii) LOSS OF PROFITS, (iii) BUSINESS
                        INTERRUPTION, (iv) REPUTATIONAL HARM, OR (v) LOSS OF INFORMATION OR DATA.
                      </li>
                    </p>
                  </ol>
                </li>
                <li>
                  <h5>CHOICE OF LAW AND VENUE</h5>
                  <p>
                    Swedish law will govern these Terms, as well as any claim, cause of action or dispute that might
                    arise between you and CampWild (a &quot;Claim&quot;), without regard to conflict of law provisions.
                  </p>
                </li>
                <li>
                  <h5> TERMINATION </h5>
                  <ol>
                    <p>
                      <li>
                        You may terminate the Terms at any time by closing your account, discontinuing your use of the
                        Site, and providing CampWild with a notice of termination here. Please review our Privacy
                        Policy for information about what we do with your account when terminated.
                      </li>
                      <li>
                        We may close your account, suspend your ability to use certain portions of the Site, and/or ban
                        you altogether from the Site for any or no reason, and without notice or liability of any kind.
                        Any such action could prevent you from accessing your account, the Site, Your Content, Site
                        Content, or any other related information.
                      </li>
                      <li>
                        In the event of any termination of these Terms, whether by you or us, Sections 1, 5, 6, 10 - 14
                        will continue in full force and effect, including our right to use Your Content as detailed in
                        Section 5.
                      </li>
                    </p>
                  </ol>
                </li>
                <li>
                  <h5>GENERAL TERMS</h5>
                  <ol>
                    <li>
                      We reserve the right to modify, update, or discontinue the Site at our sole discretion, at any
                      time, for any or no reason, and without notice or liability.
                    </li>
                    <li>
                      We may provide you with notices, including those regarding changes to the Terms by email, regular
                      mail or communications through the Site.
                    </li>
                    <li>
                      Except as otherwise stated in Section 10 above, nothing herein is intended, nor will be deemed, to
                      confer rights or remedies upon any third party.
                    </li>
                    <li>
                      The Terms contain the entire agreement between you and us regarding the use of the Site, and
                      supersede any prior agreement between you and us on such subject matter. The parties acknowledge
                      that no reliance is placed on any representation made but not expressly contained in these Terms.
                    </li>
                    <li>
                      Any failure on CampWild&apos;s part to exercise or enforce any right or provision of the Terms
                      does not constitute a waiver of such right or provision. The failure of either party to exercise
                      in any respect any right provided for herein shall not be deemed a waiver of any further rights
                      hereunder.
                    </li>
                    <li>
                      If any provision of the Terms is found to be unenforceable or invalid, then only that provision
                      shall be modified to reflect the parties&apos; intention or eliminated to the minimum extent
                      necessary so that the Terms shall otherwise remain in full force and effect and enforceable.
                    </li>
                    <li>
                      The Terms, and any rights or obligations hereunder, are not assignable, transferable or
                      sublicensable by you except with CampWild&apos;s prior written consent, but may be assigned or
                      transferred by us without restriction. Any attempted assignment by you shall violate these Terms
                      and be void.
                    </li>
                    <li>
                      The section titles in the Terms are for convenience only and have no legal or contractual effect.
                    </li>
                  </ol>
                </li>
              </li>
            </li>
          </ol>
        </Col>
      </Row>
    </Container>
  );
};

export default TermsText;
