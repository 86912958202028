import React, { useState } from 'react';

import Store from '../utils/store';
import newPlace from '../images/icons/newPlace.svg';
import blueMarker from '../images/icons/add-location.png';
import currentLocation from '../images/icons/geo.png';
import edit from '../images/icons/edit.png';
import closeIcon from '../images/icons/close-window.png';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { Marker as GMarker } from '@react-google-maps/api';
import { Marker as OSMMarker } from 'react-leaflet';
import L from 'leaflet';
import { Spinner } from 'react-bootstrap';

const AddPlaceIcon = () => {
  const profile = Store.useState((s) => s.profile);
  const showNewPlaceModal = Store.useState((s) => s.showNewPlaceModal);
  const placeIconActive = Store.useState((s) => s.placeIconActive);

  const [position, setPosition] = useState(null);
  const [locating, setLocating] = useState(false);

  const getMarker = () => {
    if (position && !window.google) {
      return (
        <OSMMarker
          eventHandlers={{
            click: onMarkerClick,
          }}
          position={[position.lat, position.lng]}
          icon={L.icon({
            iconUrl: newPlace,
            iconRetinaUrl: newPlace,
            iconSize: [40, 40],
          })}
        />
      );
    }
    return <GMarker position={position} icon={newPlace} draggable={false} onClick={onMarkerClick} />;
  };

  const showCoordinatesModal = () => {
    Store.update((s) => {
      if (profile.username) {
        s.showAddByCoordinatesModal = true;
      } else {
        s.showSignInModal = true;
      }
    });
  };

  const showPointOnMap = () => {
    Store.update((s) => {
      if (profile.username) {
        s.placeIconActive = true;
        s.pointOnMap = true;
        s.showMarkerAlert = true;
      } else {
        s.showSignInModal = true;
      }
    });
  };

  const handleClose = () => {
    Store.update((s) => {
      s.placeIconActive = false;
      s.pointOnMap = false;
      s.showNewMarker = false;
    });
  };

  const onMarkerClick = () => {
    Store.update((s) => {
      s.markerCoords = position;
      if (profile.username) {
        s.showNewPlaceModal = true;
      } else {
        s.showSignInModal = true;
      }
    });
  };

  const onLocationClick = () => {
    if (navigator.geolocation) {
      setLocating(true);
      navigator.geolocation.getCurrentPosition((position) => {
        const pos = {
          lat: parseFloat(position.coords.latitude.toFixed(7)),
          lng: parseFloat(position.coords.longitude.toFixed(7)),
        };
        setPosition(pos);

        Store.update((s) => {
          s.currentPosition = pos;
          s.centerCoords = pos;
          s.zoomLevel = 14;
          s.markerCoords = pos;
          s.showNewMarker = true;
          s.placeIconActive = true;
          s.showCurrentLocationMarker = false;
        });
        setLocating(false);
      });
    }
  };

  if (showNewPlaceModal) {
    Store.update((s) => {
      s.showAddByCoordinatesModal = false;
    });
  }

  return (
    <>
      {placeIconActive ? (
        <img onClick={handleClose} src={closeIcon} alt="" className="imageCloseBtn" />
      ) : (
        <div className="mb-2 addLocationIcon">
          {locating ? (
            <Spinner className="spinner" as="span" animation="border" size="sm" role="status" aria-hidden="true" />
          ) : (
            <DropdownButton
              key="start"
              id="dropdown-button-drop-start"
              drop="down"
              className="dropDownButton"
              variant="link"
              title={<img className="image" alt="marker" src={newPlace} />}>
              <Dropdown.Header className="name" eventkey="4">
                Add a new place by
              </Dropdown.Header>
              <Dropdown.Divider />
              <Dropdown.Item eventkey="1" onClick={showPointOnMap}>
                <img className=" item" alt="marker" src={blueMarker} />
                Pointing on map
              </Dropdown.Item>
              <Dropdown.Item eventkey="2" onClick={showCoordinatesModal}>
                <img className=" item" alt="edit" src={edit} /> Entering coordinates
              </Dropdown.Item>
              <Dropdown.Item eventkey="3" onClick={onLocationClick}>
                <img className=" item" alt="currentLocation" src={currentLocation} />
                Detecting current location
                {position && getMarker()}
              </Dropdown.Item>
            </DropdownButton>
          )}
        </div>
      )}
    </>
  );
};

export default AddPlaceIcon;
