import React, { useState, useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { MapContainer, TileLayer, useMapEvents } from 'react-leaflet';

import AddPlaceIcon from '../components/addPlaceIcon';
import DownloadFiles from '../components/downloadFiles';
import FilterIcon from '../components/filterIcon';
import LocatePositionButton from '../components/locatePositionButton';
import NewMarker from '../components/newMarker';
import LocationMarker from '../components/osm/locationMarker';
import Places from '../components/osm/places';
import { saveCoordCenter, saveZoomLevel } from '../utils/cookies';
import history from '../utils/history';
import Store from '../utils/store';
import { useTranslation } from 'react-i18next';
import _ from 'underscore';

const moduleName = 'OSMMap';

const MapEvents = React.memo(function MapEvents({ pointOnMap, showNewMarker }) {
  const map = useRef();

  useMapEvents({
    click: useCallback(
      (e) => {
        console.log(`${moduleName}: onMapClick -> ${JSON.stringify(e.latlng)}`);
        if (pointOnMap) {
          Store.update((s) => {
            // if marker already shown we reset its coordinates to 0
            if (showNewMarker) {
              s.markerCoords = { lat: 0, lng: 0 };
            } else {
              // otherwise use coordinates of a place where user clicked
              s.markerCoords = e.latlng;
            }
            s.selectedPlace = null;
            s.selectedPlaceId = null;
            s.showSelectedPlaceModal = false;
            s.showNewMarker = !s.showNewMarker;
            // close place detail modal window
            history.push('/');
          });
        }
      },
      [pointOnMap, showNewMarker],
    ),
    locationfound: useCallback((location) => {
      console.log('location found:', location);
    }, []),
    zoomend: useCallback(() => {
      if (map.current) {
        saveZoomLevel(map.current.getZoom());
      }
    }, [map]),
    dragend: useCallback(() => {
      if (map.current) {
        const center = map.current.getCenter();
        saveCoordCenter(center);
        Store.update((s) => {
          s.centerCoords = {
            lat: center.lat,
            lng: center.lng,
          };
        });
      }
    }, [map]),
  });

  return null;
});

MapEvents.displayName = 'MapEvents';

MapEvents.propTypes = {
  pointOnMap: PropTypes.bool.isRequired,
  showNewMarker: PropTypes.bool.isRequired,
};

function OSMMap() {
  const places = Store.useState((s) => s.places);
  const centerCoords = Store.useState((s) => s.centerCoords);
  const zoomLevel = Store.useState((s) => s.zoomLevel);
  const pointOnMap = Store.useState((s) => s.pointOnMap);
  const showNewMarker = Store.useState((s) => s.showNewMarker);
  const selectedPlaceId = Store.useState((s) => s.selectedPlaceId);
  const { t } = useTranslation();

  const [initialZoom] = useState(zoomLevel);
  const mapRef = useRef();

  // Effect to update map view when centerCoords change
  useEffect(() => {
    console.log(`${moduleName}: updating map view to ${!_.isEmpty(centerCoords)}`);
    console.log(`${moduleName} mapRef.current`, mapRef.current);
    if (mapRef.current && centerCoords.lat && centerCoords.lng) {
      console.log(`${moduleName}: updating map view to ${JSON.stringify(centerCoords)}`);
      mapRef.current.setView([centerCoords.lat, centerCoords.lng], mapRef.current.getZoom());
    }
  }, [centerCoords]);

  // Effect to update centerCoords based on selectedPlaceId change
  useEffect(() => {
    if (selectedPlaceId && places.length > 0) {
      const selectedPlace = places.find((place) => place.id === selectedPlaceId);
      if (selectedPlace) {
        Store.update((s) => {
          s.centerCoords = {
            lat: selectedPlace.latitude,
            lng: selectedPlace.longitude,
          };
        });
      }
    }
  }, [selectedPlaceId, places]);

  return (
    <>
      {places.length ? (
        <div className={pointOnMap ? 'leaflet-map-container' : ''}>
          <AddPlaceIcon />
          <FilterIcon />
          <DownloadFiles />
          <LocatePositionButton />
          <MapContainer
            className="leaflet-map"
            center={[centerCoords.lat, centerCoords.lng]}
            zoom={initialZoom}
            maxZoom={18}
            zoomControl={false}
            ref={mapRef}
          >
            <MapEvents pointOnMap={pointOnMap} showNewMarker={showNewMarker} />
            <LocationMarker />
            <NewMarker />
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="http://osm.org/copyright" target="_blank">OpenStreetMap</a> contributors'
            />
            <Places />
          </MapContainer>
          <footer className="footer">
            <div>&copy; {new Date().getFullYear()} CampWild</div>
            <div
              className="terms"
              onClick={() =>
                Store.update((s) => {
                  s.showTermsModal = true;
                })
              }>
              {t('signIn.terms')}
            </div>
            <div
              className="terms"
              onClick={() =>
                Store.update((s) => {
                  s.showPrivacyModal = true;
                })
              }>
              {t('signIn.privacy')}
            </div>
          </footer>
        </div>
      ) : (
        <div>Loading...</div>
      )}
    </>
  );
}

export default React.memo(OSMMap);
