import React from 'react';
import { Modal } from 'react-bootstrap';
import MyCloseButton from '../../components/button/closeButton';
import Store from '../../utils/store';
import SearchBy from '../../components/searchBy';

const AddByCoordinates = () => {
  const showAddByCoordinatesModal = Store.useState((s) => s.showAddByCoordinatesModal);

  const hide = () => {
    Store.update((s) => {
      s.showAddByCoordinatesModal = false;
    });
  };

  return (
    <Modal show={showAddByCoordinatesModal} onHide={hide} className="modalSearchByCoordinates" centered>
      <Modal.Header className="modalHeader">
        <Modal.Title>Add or search place by coordinates</Modal.Title>
        <MyCloseButton  hide={hide}/>
      </Modal.Header>
      <Modal.Body className='body'>
        <SearchBy />
      </Modal.Body>
    </Modal>
  );
};

export default AddByCoordinates;
