import PropTypes from 'prop-types';
import React from 'react';
import Container from 'react-bootstrap/Container';
import _ from 'underscore';
import Store from '../../utils/store';
import DragAndDrop from './dragAndDrop';
import DropAreaImages from './dropAreaImages';
import DropAreaMsg from './dropAreaMsg';

const DropArea = ({ className }) => {
  const imagesToUpload = Store.useState((s) => s.imagesToUpload);
  const disabled = Store.useState((s) => s.savingNewPlace);

  const handleUploadImage = (files) => {
    console.log(files);
    Store.update((s) => {
      s.imagesToUpload = _.union(s.imagesToUpload, Array.from(files));
    });
  };

  const handleOnClick = () => {
    if (disabled) {
      return;
    }
    document.getElementById('fileInput').click();
  };

  return (
    <DragAndDrop>
      <Container className={className} onClick={handleOnClick}>
        <input
          className="inputDropArea"
          id="fileInput"
          type="file"
          accept="image/*"
          multiple
          onChange={(e) => handleUploadImage(e.target.files)}
        />
        {!imagesToUpload.length && <DropAreaMsg />}
        <DropAreaImages />
      </Container>
    </DragAndDrop>
  );
};

DropArea.propTypes = {
  className: PropTypes.string,
};

export default DropArea;
