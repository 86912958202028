import { Marker as GMarker } from '@react-google-maps/api';
import { Marker as OSMMarker } from 'react-leaflet';
import L from 'leaflet';

import React from 'react';

import NewPlaceIcon from '../images/icons/newPlace.svg';
import Store from '../utils/store';

const moduleName = 'newPlaceMarker';

const NewMarker = () => {
  const markerCoords = Store.useState((s) => s.markerCoords);
  const showNewMarker = Store.useState((s) => s.showNewMarker);
  const profile = Store.useState((s) => s.profile);

  const onMarkerClick = () => {
    console.log(`${moduleName}: onMarkerClick:`, markerCoords);
    Store.update((s) => {
      if (profile.username) {
        s.showNewPlaceModal = true;
      } else {
        s.showSignInModal = true;
      }
    });
  };

  const onDragEnd = (e) => {
    console.log(`${moduleName}: onDragEnd: e -> ${JSON.stringify(e.latLng.toJSON())}`);
    Store.update((s) => {
      s.markerCoords = e.latLng.toJSON();
    });
  };

  if (showNewMarker) {
    if (!window.google) {
      return (
        <OSMMarker
          eventHandlers={{
            click: onMarkerClick,
          }}
          position={[markerCoords.lat, markerCoords.lng]}
          icon={L.icon({
            iconUrl: NewPlaceIcon,
            iconRetinaUrl: NewPlaceIcon,
            iconSize: [40, 40],
          })}
        />
      );
    }
    return (
      <GMarker
        position={markerCoords}
        onClick={onMarkerClick}
        icon={NewPlaceIcon}
        draggable={true}
        onDragEnd={onDragEnd}
      />
    );
  }

  return <></>;
};

export default NewMarker;
