import Image from '../image';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Carousel } from 'react-responsive-carousel';

import NoImages from '../../images/icons/noImages.jpeg';
import Store from '../../utils/store';

const Gallery = () => {
  const { t } = useTranslation();

  const images = Store.useState((s) => s.selectedPlaceImages);
  const profile = Store.useState((s) => s.profile);

  const showModalGallery = () => {
    Store.update((s) => {
      s.showGalleryModal = true;
    });
  };

  const showModalAddPhotos = () => {
    Store.update((s) => {
      if (profile.username) {
        s.showAddPhotosModal = true;
      } else {
        s.showSignInModal = true;
      }
    });
  };

  return (
    <div>
      {images?.length > 0 ? (
        <Carousel
          showThumbs={false}
          infiniteLoop={true}
          useKeyboardArrows={true}
          emulateTouch={true}
          showIndicators={false}
          showStatus={false}>
          {images &&
            images.map((image, i) => (
              <Image alt="image" key={i} onClick={showModalGallery} src={image.image} width={150} height={200} />
            ))}
        </Carousel>
      ) : (
        <img alt="image" className="noImagesPlaceholder" src={NoImages} />
      )}
      <button className="addPhotoButton" onClick={showModalAddPhotos}>
        <span>{t('place.addPhoto')}</span>
      </button>
      <div className="sectionDividerBottomLine" />
    </div>
  );
};

export default Gallery;
